export const colors = {
  red: "#E2001A",
  cyan: "#31B7BC",
  black: "#393A42",
  grey: "#8D93AB",
  lightGrey: "#DFE6EB",
  border: "rgba(223, 230, 235, 0.5)",
  darkGrey: "#535F6A",
  blue: "rgba(1, 84, 145)",
  lightBlue: "rgba(163, 207, 228)",
  white: "#FFFFFF",
};

export const shadows = {
  input: "0px 1px 2px rgba(0, 0, 0, 0.1)",
  default: "0px 0px 4px rgba(0, 0, 0, 0.1)",
};

export const fonts = {
  primary: "'Heebo'",
};
