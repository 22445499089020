import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useStore } from "react-redux";
import { useAPI } from "../../apiProvider";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import InfoCard from "../../components/InfoCard";
import PrivatePlantDataViewer, {
  usePrivatePlantDataViewerState,
} from "../../module/PrivatePlantDataViewer";
import PrivatePlantDataEditor, {
  showPrivatePlantDataEditorModal,
  setPlantMarking,
} from "../../module/PrivatePlantDataEditor";
import { useSelector } from "../../redux/utils";
import { colors } from "../../styles/variables";

interface Props {
  plantId: string;

  /**
   * load can be set to false to prevent loading the data.
   * This can be used, if it is known that there is no private data.
   */
  load?: boolean;
}

const PrivatePlantDataViewerContainer = ({ plantId, load = true }: Props) => {
  const { t } = useTranslation("privatePlantDataViewer");
  const privatePlantDataViewerState = usePrivatePlantDataViewerState(
    plantId,
    load
  );
  const store = useStore();
  const api = useAPI();

  /**
   * null - private data doesn't exist
   * boolean - private data exists
   */
  const isPlantMarked: boolean | undefined | null = useSelector(
    (state) => state.modules.privatePlantData.plantMarked,
    shallowEqual
  );

  useEffect(() => {
    // Set marking to true in case if create plant data
    if (
      privatePlantDataViewerState.privatePlantData ||
      privatePlantDataViewerState.privatePlantData === null
    ) {
      setPlantMarking(
        store,
        privatePlantDataViewerState.privatePlantData
          ? privatePlantDataViewerState.privatePlantData.marked
          : null
      );
    }
  }, [privatePlantDataViewerState.privatePlantData, store]);

  const handleOpenPlantPrivateDataEditor = useCallback(() => {
    // Private data doesn't exist so open modal to create
    if (isPlantMarked === null) {
      showPrivatePlantDataEditorModal(store, api);
      return;
    }
    // Open modal to update private data
    showPrivatePlantDataEditorModal(store, api, plantId);
  }, [plantId, api, store, isPlantMarked]);

  const handlePrivateDataRefresh = useCallback(() => {
    privatePlantDataViewerState.refreshPrivatePlantData();
  }, [privatePlantDataViewerState]);

  return (
    <InfoCard
      title={t("title")}
      subTitle={t("tip")}
      subTitleColor={colors.red}
      fullWidth
      onAction={handleOpenPlantPrivateDataEditor}
      actionIcon={faPen}
    >
      <PrivatePlantDataViewer
        privatePlantDataViewerState={privatePlantDataViewerState}
      />
      <PrivatePlantDataEditor
        plantId={plantId}
        onAction={handlePrivateDataRefresh}
      />
    </InfoCard>
  );
};

export default PrivatePlantDataViewerContainer;
