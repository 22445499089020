import React, { memo } from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { useFormikContext } from "formik";

interface Props {
  label: string;
  name: string;
}

const SingleCheckbox: React.FC<CheckboxProps & Props> = memo(
  ({ label, name, ...rest }) => {
    const formik = useFormikContext<Record<string, boolean>>();

    const handleChange = () => {
      formik.setFieldValue(name, !formik.values[name]);
    };

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values[name]}
            onChange={handleChange}
            name={name}
            {...rest}
          />
        }
        label={label}
      />
    );
  }
);

SingleCheckbox.displayName = "SingleCheckbox";

export default SingleCheckbox;
