import React, { memo } from "react";
import { Card, Typography, Grid, makeStyles, fade } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { SimplePlantDTO } from "../../api";
import AddressViewerContainer from "../../containers/AddressViewerContainer";
import { useTranslation } from "react-i18next";
import {
  categories,
  categoryDetails,
  getMainCategory,
  getCategoryDetailsIndex,
} from "../../enum/plant";

interface Props {
  plant: SimplePlantDTO;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.light, 0.05),
      borderLeft: `5px solid ${theme.palette.primary.light}`,
      paddingLeft: "15px",
    },
  },
  icon: {
    fontSize: 22,
    color: theme.palette.secondary.main,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const PlantListItem = memo(({ plant }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation("plantList");
  return (
    <Card className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={11} container spacing={2}>
          <Grid item xs={1}>
            {!plant.isExternalPlant && (
              <img src="/images/logo.svg" alt="logo" width="70px" />
            )}
          </Grid>
          <Grid item xs={11} container alignItems="center">
            <Typography variant="h2">{plant.name}</Typography>
          </Grid>
          {plant.category !== 0 && (
            <Grid item xs={4}>
              <Typography variant="h5">{t("type")}</Typography>
              <Typography variant="body2">
                {categories[getMainCategory(plant.category)]}
                {plant.category % 100 !== 0 &&
                  categoryDetails[getCategoryDetailsIndex(plant.category)] && (
                    <>
                      <br />
                      {
                        categoryDetails[
                          getCategoryDetailsIndex(plant.category) - 1
                        ][plant.category]
                      }
                    </>
                  )}
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}>
            <AddressViewerContainer addressId={plant.addressId} />
          </Grid>
        </Grid>
        <Grid item xs={1} className={classes.iconContainer}>
          <FontAwesomeIcon icon={faAngleRight} className={classes.icon} />
        </Grid>
      </Grid>
    </Card>
  );
});

PlantListItem.displayName = "PlantListItem";

export default PlantListItem;
