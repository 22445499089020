import { ISelectOption } from "../../components/Form/Select/utils";

export enum PostalCodeRange {
  Range10 = 10,
  Range20 = 20,
  Range50 = 50,
  Range100 = 100,
  Range200 = 200,
}

export const PostalCodeRangeOptions: ISelectOption[] = Object.values(
  PostalCodeRange
)
  .filter((v) => typeof v === "number")
  .map((v) => ({
    name: `${v.toString()} km`,
    value: v.toString(),
  }));
