import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Container,
  makeStyles,
  Box,
} from "@material-ui/core";
import Menu from "../../module/Menu";
import { useTranslation } from "react-i18next";

// A style sheet
const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  img: {
    marginRight: 16,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Box component="header" className={classes.container} boxShadow={3}>
      <Container>
        <Grid container spacing={1}>
          <Grid item container xs={4} alignItems="center">
            <Link to="/" className={classes.iconContainer}>
              <img src="/images/logo.svg" alt="logo" className={classes.img} />
              <Typography variant="h1" component="h1">
                {t("title")}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={8} container alignItems="center" justify="flex-end">
            <Menu />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
