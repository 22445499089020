import React from "react";
import { shallowEqual, useStore } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useSelector } from "../../redux/utils";
import { closeNotification } from "./utils";

const Notification = (props: AlertProps) => {
  const store = useStore();

  const open: boolean = useSelector(
    (state) => state.modules.notification.open,
    shallowEqual
  );

  const message: string | undefined = useSelector(
    (state) => state.modules.notification.message,
    shallowEqual
  );

  const handleClose = () => {
    closeNotification(store);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert onClose={handleClose} {...props}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Notification;
