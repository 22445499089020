import React from "react";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { WasteCategoryGroupEntity } from "../../jsonEntity/wasteCategoryGroup";
import CategoryGroupForm from "./CategoryGroupForm";
import CategoryGroupItem from "./CategoryGroupItem";
import ImporterButtons from "./ImporterButtons";
import { useTranslation } from "react-i18next";

interface Props {
  onCategoryGroupListChange: (
    categoryGroupList: WasteCategoryGroupEntity[]
  ) => void;
  categoryGroups: WasteCategoryGroupEntity[];
  onSetActiveStep: (step: number) => void;
  onModalClose: () => void;
}

const useStyles = makeStyles({
  header: {
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 20,
  },
});

const CategoryGroupList = ({
  onCategoryGroupListChange,
  categoryGroups,
  onSetActiveStep,
  onModalClose,
}: Props) => {
  const { t } = useTranslation("wasteImporter");

  const handleAddWasteCategoryGroup = (
    categoryGroup: WasteCategoryGroupEntity
  ) => {
    onCategoryGroupListChange([...categoryGroups, categoryGroup]);
  };

  const handleDeleteCategoryGroupItem = (index: number) => {
    onCategoryGroupListChange(categoryGroups.filter((c, i) => i !== index));
  };

  const handleNextStep = () => onSetActiveStep(2);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.header}>
        <Grid item xs={3}>
          <Box pb={1}>
            <Typography variant="subtitle1">{t("fromPage")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pb={1}>
            <Typography variant="subtitle1">{t("categories")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3} />
        {categoryGroups.map((categoryGroup, index) => (
          <Grid item xs={12} key={categoryGroup.fromPage}>
            <CategoryGroupItem
              categoryGroup={categoryGroup}
              index={index}
              onDelete={handleDeleteCategoryGroupItem}
            />
          </Grid>
        ))}
      </Grid>
      <CategoryGroupForm
        key={categoryGroups.length}
        minPage={categoryGroups[categoryGroups.length - 1]?.fromPage}
        onAddWasteCategoryGroup={handleAddWasteCategoryGroup}
      />
      <ImporterButtons
        onModalClose={onModalClose}
        onOk={handleNextStep}
        okText={categoryGroups.length === 0 ? t("skipThisStep") : t("continue")}
      />
    </>
  );
};

export default CategoryGroupList;
