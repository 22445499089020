import React from "react";
import { makeStyles, Container } from "@material-ui/core";
import { useStore } from "react-redux";
import PrimaryAddButton from "../../components/PrimaryAddButton";
import PlantEditor, { showPlantEditorModal } from "../../module/PlantEditor";
import PlantList from "../../module/PlantList";
import PlantSearch, { usePlantSearchState } from "../../module/PlantSearch";
import { usePlantListState } from "../../module/PlantList/hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  listContainer: {
    paddingTop: 50,
  },
});

const PlantListContainer = () => {
  const { t } = useTranslation("plantListContainer");

  const classes = useStyles();
  const store = useStore();
  const { search, setSearch } = usePlantSearchState();
  const plantListState = usePlantListState({
    filter: search,
    pageSize: 20,
  });

  const onShowModal = () => showPlantEditorModal(store);
  return (
    <>
      <PlantSearch search={search} onSetSearch={setSearch} />
      <Container>
        <div className={classes.listContainer}>
          <PlantList
            plantListState={plantListState}
            search={search}
            onSetSearch={setSearch}
          />
        </div>
        <PlantEditor />
        <PrimaryAddButton onClick={onShowModal} text={t("addPlantButton")} />
      </Container>
    </>
  );
};

export default PlantListContainer;
