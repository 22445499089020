import { ISelectOption } from "../Select/utils";

export const mapEnumToOptions = (
  values: Record<string, string>,
  translateValue?: (values: string) => string
): ISelectOption[] => {
  const options = [] as ISelectOption[];
  Object.keys(values).map((key) => {
    options.push({
      name: values[key],
      value: key,
    });
  });

  return options.sort((a, b) => {
    if (translateValue) {
      return translateValue(a.value).localeCompare(translateValue(b.value));
    }
    return a.value.localeCompare(b.value);
  });
};
