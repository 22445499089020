export default {
  title: "Suche nach Verwertungsanlagen durch die Suchfelder:",
  category: "Anlagenart",
  reset: "zurücksetzen",
  search: "Suche",
  pleaseSelect: "Bitte auswählen",
  postCode: "PLZ",
  wasteKey: "Abfallschlüssel",
  postalCodeRange: "Umkreis",
  name: "Stichwortsuche",
  openDetailSearch: "Weitere Filter",
  closeDetailSearch: "Weniger Filter",
  specifyPlant: "Anlagenart spezifizieren",
  formsOfDelivery: "Anlieferungsformen",
  deliveryConditions: "Anlieferbedingungen",
};
