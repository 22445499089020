import React from "react";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";

export interface IMenuOptions {
  name: string;
  link: string;
}

interface Props {
  menuHeading: string;
  menuOptions: IMenuOptions[];
}

const DropdownMenu = ({ menuHeading, menuOptions }: Props) => {
  /**
   * TODO
   * Add links when routing will be needed.
   */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (menuOptions.length === 0) {
    return (
      <Button color="inherit">
        <Typography variant="subtitle1">{menuHeading}</Typography>
      </Button>
    );
  }

  return (
    <>
      <Button
        aria-label="dropdown menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Typography variant="subtitle1">{menuHeading}</Typography>
      </Button>
      <Menu
        id={menuHeading}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.name}>{option.name}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
