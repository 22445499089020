import { createAction } from "@reduxjs/toolkit";
import { AddressDTO, ContactDTO, PlantDTO, PersonDTO } from "../../../api";

const PlantEditorActions = {
  setModalOpen: createAction(
    "MODULE_PLANT_EDITOR_SET_MODAL_OPEN",
    (
      open: boolean,
      selectedPlant?: PlantDTO,
      selectedAddress?: AddressDTO,
      selectedContact?: ContactDTO,
      selectedInternalPerson?: PersonDTO,
      selectedExternalPerson?: PersonDTO,
      selectedInternalPersonContact?: ContactDTO,
      selectedExternalPersonContact?: ContactDTO
    ) => {
      return {
        payload: {
          modalOpen: open,
          selectedPlant,
          selectedAddress,
          selectedContact,
          selectedInternalPerson,
          selectedExternalPerson,
          selectedInternalPersonContact,
          selectedExternalPersonContact,
        },
      };
    }
  ),
};

export default PlantEditorActions;
