import React, { useMemo } from "react";
import { Button, Menu, MenuItem, Typography, styled } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useLogout, useProfile } from "../Auth/hooks";
import { useTranslation } from "react-i18next";

const UserName = styled(Typography)({
  textTransform: "initial",
  marginLeft: 14,
  marginRight: 7,
});

const DropdownIcon = styled(FontAwesomeIcon)({
  fontSize: 16,
  marginBottom: 8,
});

const UserMenu = () => {
  const { t } = useTranslation("menu");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const profile = useProfile();
  const logout = useLogout();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const username = useMemo(() => {
    return profile.firstName && profile.lastName
      ? `${profile.firstName} ${profile.lastName}`
      : profile.username || profile.email || "Unknown User";
  }, [profile.email, profile.firstName, profile.lastName, profile.username]);

  return (
    <>
      <Button
        data-testid="user-menu-button"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <UserName variant="subtitle1">{username}</UserName>
        <DropdownIcon icon={faSortDown} />
      </Button>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-testid="user-menu-item-logout" onClick={handleLogout}>
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
