import React, { useEffect, useCallback, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Form, FormikHelpers, FormikProvider, useFormik } from "formik";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CreateWasteDTO, UpdateWasteDTO, WasteDTO } from "../../api";
import { useAPI } from "../../apiProvider";
import {
  Input,
  FormButtons,
  SelectWasteKey,
  getWasteListOptions,
} from "../../components/Form";
import CardTitle from "../../components/CardTitle";
import Modal from "../../components/TransitionsModal";
import { store } from "../../redux/configureStore";
import { useSelector } from "../../redux/utils";
import { closeWasteEditorModal, transformWasteKey } from "./utils";
import { IWaste } from "../../components/Form/SelectWasteKey/utils";

interface Props {
  onAction?: () => void;
  plantId: string;
}

const WasteEditor = ({ onAction, plantId }: Props) => {
  const { t } = useTranslation("wasteEditor");
  const [wasteDesignation, setWasteDesignation] = useState("");
  const api = useAPI();

  const waste: WasteDTO | null = useSelector(
    (state) => state.modules.wasteEditor.selectedWaste,
    shallowEqual
  );

  const usedWasteKeys: string[] = useSelector(
    (state) => state.modules.wasteEditor.usedWasteKeys,
    shallowEqual
  );

  const validationSchema = yup.object({
    wasteKey: yup.string().required(t("wasteKeyError")),
    notes: yup.string(),
  });

  const handleUpdateWaste = async (
    values: UpdateWasteDTO,
    initialValues: WasteDTO
  ) => {
    // Need update only notes for now
    if (values.notes !== initialValues.notes) {
      await api.waste.wasteControllerUpdateWaste({
        wasteId: initialValues.id,
        updateWasteDTO: { notes: values.notes },
      });
    }
  };

  const handleCreateWaste = async (values: CreateWasteDTO) => {
    await api.plantWaste.plantWasteControllerAddWaste({
      plantId,
      createWasteDTO: values,
    });
  };

  const handleSubmit = async (
    values: CreateWasteDTO,
    formikHelpers: FormikHelpers<CreateWasteDTO>
  ) => {
    formikHelpers.setSubmitting(true);
    if (waste) {
      await handleUpdateWaste(values, waste);
    } else {
      await handleCreateWaste(values);
    }
    formikHelpers.setSubmitting(false);
    handleCloseModal();
    if (onAction) onAction();
  };

  const formik = useFormik({
    initialValues: { wasteKey: "", notes: "" },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCloseModal = () => closeWasteEditorModal(store);

  const isModalOpen = useSelector(
    (state) => state.modules.wasteEditor.modalOpen,
    shallowEqual
  );

  const handleSetEditorValues = useCallback(async () => {
    if (!waste || formik.dirty) return;
    formik.setValues({
      wasteKey: transformWasteKey(waste.wasteKey),
      notes: waste.notes,
    });
  }, [waste, formik]);

  useEffect(() => {
    if (isModalOpen === false && formik.dirty) {
      formik.resetForm();
    }
  }, [isModalOpen, formik]);

  useEffect(() => {
    handleSetEditorValues();
  }, [handleSetEditorValues]);

  useEffect(() => {
    const options = getWasteListOptions();
    const option = options.find(
      (o: IWaste) => o.wasteKey.replaceAll(" ", "") === formik.values.wasteKey
    );
    setWasteDesignation(option?.designation || "");
  }, [formik.values.wasteKey]);

  return (
    <Modal title={t("title")} open={isModalOpen} onClose={handleCloseModal}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pb={2} pt={2}>
                <CardTitle>{t("lawData")}</CardTitle>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SelectWasteKey
                usedKeys={usedWasteKeys}
                dropdownFullWidth
                disabled={!!waste}
                name="wasteKey"
                label={t("wasteKey")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="designation"
                disabled
                value={wasteDesignation}
                label={t("wasteDesignation")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="notes"
                label={t("additionalInformation")}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <FormButtons onCancel={handleCloseModal} />
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default WasteEditor;
