import { AppStore } from "../../redux/configureStore";
import NotificationActions from "./store/notificationActions";

export const showNotification = (store: AppStore, message: string) => {
  store.dispatch(NotificationActions.setOpen(true, message));
};

export const closeNotification = (store: AppStore) => {
  store.dispatch(NotificationActions.setOpen(false));
};
