import React, { memo, useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { ISelectOption, mapEnumToOptions } from "./utils";

interface Props {
  className?: string;
  name: string;
  label: string;
  options: Record<number, string> | ISelectOption[];
  sort?: (a: ISelectOption, b: ISelectOption) => number;
  onChangeHook?: () => void;
}

const Select: React.FC<Props> = memo(
  ({ name, label, options, sort, className = "", onChangeHook }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formik = useFormikContext<any>();

    const handleChange = (
      evt: React.ChangeEvent<Record<string, number>>,
      newValue: ISelectOption | null
    ) => {
      if (onChangeHook) {
        onChangeHook();
      }
      formik.setFieldValue(name, newValue?.value);
    };
    const mappedOptions = useMemo(() => {
      let res: ISelectOption[];

      if (Array.isArray(options)) {
        res = options;
      } else {
        res = mapEnumToOptions(options);
      }

      if (sort) {
        res.sort(sort);
      }

      return res;
    }, [options, sort]);

    const getValue = useCallback(() => {
      if (!formik.values[name]) return null;
      const option = mappedOptions.find(
        (o) => o.value === formik.values[name].toString()
      );
      if (!option) return null;
      return option;
    }, [formik, name, mappedOptions]);

    return (
      <Autocomplete
        id={name}
        className={className}
        value={getValue()}
        onChange={handleChange}
        options={mappedOptions}
        getOptionLabel={(option: ISelectOption) => option.name}
        getOptionSelected={(option: ISelectOption, value: ISelectOption) =>
          option?.value === value?.value
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            size="small"
            variant="outlined"
          />
        )}
      />
    );
  }
);

Select.displayName = "Select";

export default Select;
