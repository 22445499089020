/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePrivatePlantDTO } from '../dto';
// @ts-ignore
import { PrivatePlantDTO } from '../dto';
// @ts-ignore
import { UpdatePrivatePlantDTO } from '../dto';
/**
 * PrivatePlantDataApi - axios parameter creator
 * @export
 */
export const PrivatePlantDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a new private Plant
         * @param {string} plantId 
         * @param {CreatePrivatePlantDTO} createPrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerCreatePrivatePlantData: async (plantId: string, createPrivatePlantDTO: CreatePrivatePlantDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('privatePlantControllerCreatePrivatePlantData', 'plantId', plantId)
            // verify required parameter 'createPrivatePlantDTO' is not null or undefined
            assertParamExists('privatePlantControllerCreatePrivatePlantData', 'createPrivatePlantDTO', createPrivatePlantDTO)
            const localVarPath = `/plant/{plantId}/private`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPrivatePlantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the private data of the current user for a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerGetPrivatePlantData: async (plantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('privatePlantControllerGetPrivatePlantData', 'plantId', plantId)
            const localVarPath = `/plant/{plantId}/private`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update private plant data
         * @param {string} plantId 
         * @param {UpdatePrivatePlantDTO} updatePrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerUpdatePrivatePlantData: async (plantId: string, updatePrivatePlantDTO: UpdatePrivatePlantDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('privatePlantControllerUpdatePrivatePlantData', 'plantId', plantId)
            // verify required parameter 'updatePrivatePlantDTO' is not null or undefined
            assertParamExists('privatePlantControllerUpdatePrivatePlantData', 'updatePrivatePlantDTO', updatePrivatePlantDTO)
            const localVarPath = `/plant/{plantId}/private`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrivatePlantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivatePlantDataApi - functional programming interface
 * @export
 */
export const PrivatePlantDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivatePlantDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a new private Plant
         * @param {string} plantId 
         * @param {CreatePrivatePlantDTO} createPrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privatePlantControllerCreatePrivatePlantData(plantId: string, createPrivatePlantDTO: CreatePrivatePlantDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privatePlantControllerCreatePrivatePlantData(plantId, createPrivatePlantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the private data of the current user for a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privatePlantControllerGetPrivatePlantData(plantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivatePlantDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privatePlantControllerGetPrivatePlantData(plantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update private plant data
         * @param {string} plantId 
         * @param {UpdatePrivatePlantDTO} updatePrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privatePlantControllerUpdatePrivatePlantData(plantId: string, updatePrivatePlantDTO: UpdatePrivatePlantDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privatePlantControllerUpdatePrivatePlantData(plantId, updatePrivatePlantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivatePlantDataApi - factory interface
 * @export
 */
export const PrivatePlantDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivatePlantDataApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a new private Plant
         * @param {string} plantId 
         * @param {CreatePrivatePlantDTO} createPrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerCreatePrivatePlantData(plantId: string, createPrivatePlantDTO: CreatePrivatePlantDTO, options?: any): AxiosPromise<string> {
            return localVarFp.privatePlantControllerCreatePrivatePlantData(plantId, createPrivatePlantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the private data of the current user for a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerGetPrivatePlantData(plantId: string, options?: any): AxiosPromise<PrivatePlantDTO> {
            return localVarFp.privatePlantControllerGetPrivatePlantData(plantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update private plant data
         * @param {string} plantId 
         * @param {UpdatePrivatePlantDTO} updatePrivatePlantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantControllerUpdatePrivatePlantData(plantId: string, updatePrivatePlantDTO: UpdatePrivatePlantDTO, options?: any): AxiosPromise<void> {
            return localVarFp.privatePlantControllerUpdatePrivatePlantData(plantId, updatePrivatePlantDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privatePlantControllerCreatePrivatePlantData operation in PrivatePlantDataApi.
 * @export
 * @interface PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantDataRequest
 */
export interface PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantDataRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantData
     */
    readonly plantId: string

    /**
     * 
     * @type {CreatePrivatePlantDTO}
     * @memberof PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantData
     */
    readonly createPrivatePlantDTO: CreatePrivatePlantDTO
}

/**
 * Request parameters for privatePlantControllerGetPrivatePlantData operation in PrivatePlantDataApi.
 * @export
 * @interface PrivatePlantDataApiPrivatePlantControllerGetPrivatePlantDataRequest
 */
export interface PrivatePlantDataApiPrivatePlantControllerGetPrivatePlantDataRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivatePlantDataApiPrivatePlantControllerGetPrivatePlantData
     */
    readonly plantId: string
}

/**
 * Request parameters for privatePlantControllerUpdatePrivatePlantData operation in PrivatePlantDataApi.
 * @export
 * @interface PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantDataRequest
 */
export interface PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantDataRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantData
     */
    readonly plantId: string

    /**
     * 
     * @type {UpdatePrivatePlantDTO}
     * @memberof PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantData
     */
    readonly updatePrivatePlantDTO: UpdatePrivatePlantDTO
}

/**
 * PrivatePlantDataApi - object-oriented interface
 * @export
 * @class PrivatePlantDataApi
 * @extends {BaseAPI}
 */
export class PrivatePlantDataApi extends BaseAPI {
    /**
     * 
     * @summary creates a new private Plant
     * @param {PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivatePlantDataApi
     */
    public privatePlantControllerCreatePrivatePlantData(requestParameters: PrivatePlantDataApiPrivatePlantControllerCreatePrivatePlantDataRequest, options?: any) {
        return PrivatePlantDataApiFp(this.configuration).privatePlantControllerCreatePrivatePlantData(requestParameters.plantId, requestParameters.createPrivatePlantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the private data of the current user for a plant
     * @param {PrivatePlantDataApiPrivatePlantControllerGetPrivatePlantDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivatePlantDataApi
     */
    public privatePlantControllerGetPrivatePlantData(requestParameters: PrivatePlantDataApiPrivatePlantControllerGetPrivatePlantDataRequest, options?: any) {
        return PrivatePlantDataApiFp(this.configuration).privatePlantControllerGetPrivatePlantData(requestParameters.plantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update private plant data
     * @param {PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivatePlantDataApi
     */
    public privatePlantControllerUpdatePrivatePlantData(requestParameters: PrivatePlantDataApiPrivatePlantControllerUpdatePrivatePlantDataRequest, options?: any) {
        return PrivatePlantDataApiFp(this.configuration).privatePlantControllerUpdatePrivatePlantData(requestParameters.plantId, requestParameters.updatePrivatePlantDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
