import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Fab,
  isWidthUp,
  makeStyles,
  WithWidth,
  withWidth,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  button: {
    position: "fixed",
    right: 40,
    bottom: 40,
    fontSize: 24,
  },
  icon: {
    marginRight: "10px",
  },
  text: {
    whiteSpace: "pre-wrap",
    fontSize: "16px",
  },
}));

interface Props extends WithWidth {
  onClick: () => void;
  text?: string;
}

const PrimaryAddButton = memo(({ text, ...props }: Props) => {
  const classes = useStyles();

  if (text && isWidthUp("md", props.width)) {
    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        {...props}
      >
        <FontAwesomeIcon
          className={classes.icon}
          size="2x"
          icon={faPlusCircle}
        />
        <div className={classes.text}>{text}</div>
      </Button>
    );
  }

  return (
    <Fab className={classes.button} color="primary" {...props}>
      <FontAwesomeIcon icon={faPlus} />
    </Fab>
  );
});

PrimaryAddButton.displayName = "PrimaryAddButton";

export default withWidth()(PrimaryAddButton);
