import { useCallback, useEffect, useState } from "react";
import { ContactDTO, PersonDTO } from "../../api";
import { useAPI } from "../../apiProvider";

export interface IPersonAndContactViewerState {
  person?: PersonDTO | null;
  contact?: ContactDTO | null;
  refreshPersonAndContact: () => void;
}

export function usePersonAndContactViewerState(
  personId?: string | null
): IPersonAndContactViewerState {
  const [person, setPerson] = useState<PersonDTO | null | undefined>();
  const [contact, setContact] = useState<ContactDTO | null | undefined>();
  const api = useAPI();
  const loadPersonAndContact = useCallback(async () => {
    setPerson(personId ? null : undefined);
    setContact(personId ? null : undefined);
    if (!personId) {
      return;
    }
    const personResponse = await api.person.personControllerGetPerson({
      personId,
    });
    const personContact = await api.contact.contactControllerGetContact({
      contactId: personResponse.data.contactId,
    });
    setPerson(personResponse.data);
    setContact(personContact.data);
  }, [api.person, api.contact, personId]);

  useEffect(() => {
    loadPersonAndContact();
  }, [loadPersonAndContact]);

  return {
    person,
    contact,
    refreshPersonAndContact: loadPersonAndContact,
  };
}
