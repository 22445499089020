import { useCallback, useEffect, useState } from "react";
import { AddressDTO } from "../../api";
import { useAPI } from "../../apiProvider";

interface IAddressViewerState {
  address?: AddressDTO;
  refreshAddress: () => void;
}

export function useAddressViewerState(addressId?: string): IAddressViewerState {
  const [address, setAddress] = useState<AddressDTO>();

  const api = useAPI();
  const loadAddress = useCallback(async () => {
    if (!addressId) return;
    const response = await api.address.addressControllerGetAddress({
      addressId,
    });
    setAddress(response.data);
  }, [api.address, addressId]);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  return {
    address,
    refreshAddress: loadAddress,
  };
}
