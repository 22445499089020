import { createAction } from "@reduxjs/toolkit";

const WasteImporterActions = {
  setModalOpen: createAction(
    "MODULE_WASTE_IMPORTER_SET_MODAL_OPEN",
    (open: boolean) => {
      return {
        payload: {
          modalOpen: open,
        },
      };
    }
  ),
};

export default WasteImporterActions;
