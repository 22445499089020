export default {
  title: "Abfallschlüssel importieren",
  subtitle:
    "Für eine schnelle Mehrfachanlage der Abfallschlüssel können diese automatisch aus dem Zertifikate der Anlagen ausgelesen werden",
  uploadTip: "Zertifikat als PDF ablegen oder hier klicken",
  stepLabel1: "Datei hochladen",
  stepLabel2: "Seiten zu Kategorien zuordnen",
  stepLabel3: "Import bestätigen",
  dropzoneText:
    "Ziehen Sie eine PDF-Datei hierher und legen Sie sie dort ab oder klicken Sie auf",
  abort: "abbrechen",
  finishMapping: "Mapping beenden",
  skipThisStep: "Überspringen",
  fromPage: "Von Seite",
  categories: "Kategorien",
  add: "Hinzufügen",
  continue: "Fortsetzen",
  confirm: "bestätigen",
  confirmationText:
    "Nach der Importbestätigung werden alle vorhandenen Abfälle gelöscht. Bist du sicher?",
  notification: "{{amount}} Abfallartikel wurden importiert",
  fromPageValidationError: "nur Zahlen ab 1 sind erlaubt",
  fileLimitExceedMessage: "Maximal {{amount}} Datei ist erlaubt.",
  dropRejectMessage:
    "Es sind nur {{acceptedFiles}} Dateien erlaubt mit einer maximalgröße von {{maxFileSize}} MB",
};
