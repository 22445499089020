import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import PlantListItem from "./PlantListItem";
import { useTranslation } from "react-i18next";
import { IPlantListState } from "./hooks";
import PlantListEmptyState from "./PlantListEmptyState";
import InfiniteScroll from "react-infinite-scroll-component";
import { ISearchValues } from "../PlantSearch/hooks";

export interface Props {
  plantListState: IPlantListState;
  customEmptyMessage?: string;
  search: ISearchValues;
  onSetSearch: (data: ISearchValues) => void;
}

interface IExternalPlantSwitch {
  handleChange: () => void;
  checked: boolean;
  label: string;
  name: string;
}

const ExternalPlantSwitch = ({
  handleChange,
  checked,
  label,
  name,
}: IExternalPlantSwitch) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          name={name}
          color="primary"
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};

const PlantList = ({
  plantListState: { loadedPlants, nextPage, count },
  customEmptyMessage,
  search,
  onSetSearch,
}: Props) => {
  const { t } = useTranslation("plantList");

  const [includeExternalPlants, setIncludeExternalPlants] = useState(false);

  const handleExternalPlantSwitch = () => {
    setIncludeExternalPlants(!includeExternalPlants);
    onSetSearch({ ...search, includeExternalPlants: !includeExternalPlants });
  };

  if (!loadedPlants) {
    // TODO add loader later
    return null;
  }
  if (loadedPlants.length === 0) {
    return <PlantListEmptyState customEmptyMessage={customEmptyMessage} />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box display="flex" whiteSpace="break-spaces">
            <Typography variant="subtitle1">{t("amount")}: </Typography>
            <Typography variant="subtitle1" color="primary">
              {count}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="right">
            <ExternalPlantSwitch
              handleChange={handleExternalPlantSwitch}
              checked={includeExternalPlants}
              name={"includeExternalPlantSwitch"}
              label={t("externalPlantSwitch")}
            />
          </Box>
        </Grid>
      </Grid>

      {/* ToDo: Develop own paging solution as the lib is quite big */}
      <InfiniteScroll
        hasMore={count != loadedPlants.length}
        dataLength={loadedPlants.length}
        next={nextPage}
        loader={
          <p style={{ textAlign: "center" }}>
            <b>{t("loading")}</b>
          </p>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>{t("allLoaded")}</b>
          </p>
        }
      >
        {loadedPlants.map((plant) => (
          <Link to={`/plant/${plant.id}`} key={plant.id}>
            <Box margin="12px 0px">
              <PlantListItem plant={plant} />
            </Box>
          </Link>
        ))}
      </InfiniteScroll>
    </>
  );
};

export default PlantList;
