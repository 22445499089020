import { useCallback, useEffect, useState } from "react";
import { WasteListDTO } from "../../api";
import { useAPI } from "../../apiProvider";

export interface IWasteViewerState {
  page: number;
  pageSize: number;
  wasteList?: WasteListDTO;
  refreshWasteList: () => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}

export function useWasteListViewerState(plantId: string): IWasteViewerState {
  const [wasteList, setWasteList] = useState<WasteListDTO>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const api = useAPI();
  const loadWasteList = useCallback(async () => {
    if (!plantId) return;
    const response = await api.plantWaste.plantWasteControllerGetPlantWasteList(
      {
        plantId,
        page,
        pageSize,
      }
    );
    setWasteList(response.data);
  }, [api.plantWaste, plantId, page, pageSize]);

  useEffect(() => {
    loadWasteList();
  }, [loadWasteList]);

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    wasteList,
    refreshWasteList: loadWasteList,
  };
}
