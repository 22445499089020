import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  onEdit: () => void;
  className?: string;
}

const PageBackButton = ({ onEdit, className }: Props) => {
  const { t } = useTranslation("common");

  return (
    <Button
      className={className}
      variant="contained"
      color="primary"
      onClick={onEdit}
      startIcon={<FontAwesomeIcon icon={faEdit} />}
    >
      {t("edit")}
    </Button>
  );
};

export default PageBackButton;
