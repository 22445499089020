/* eslint-disable @typescript-eslint/no-explicit-any */
// Disable error with any type in this file

/**
 * filter out values that were changed
 * @param values form values of the item
 * @param initialValues existing values of the item in database
 * @returns changed values of the item - difference between values and initial values
 */
export const getChangedValues = <
  Values extends Record<string, any>,
  InitialValues extends Record<string, any>,
  ReturnType extends Record<string, any>
>(
  values: Values,
  initialValues: InitialValues
): ReturnType | null => {
  const changedValues = {} as Record<string, any>;
  Object.keys(values).map((key) => {
    if (
      initialValues[key] !== undefined &&
      values[key] !== undefined &&
      values[key] !== initialValues[key]
    ) {
      changedValues[key] = values[key];
    }
  });
  if (Object.keys(changedValues).length === 0) {
    return null;
  }
  return changedValues as ReturnType;
};

/**
 * filter out values that contain data
 * @param values form values of the item
 * @returns values that contain some data, empty values are ignored
 */
export const getExistingValues = <Values extends Record<string, any>>(
  values: Values
): Values | null => {
  const changedValues = {} as Record<string, any>;
  Object.keys(values).map((key) => {
    if (values[key]) {
      changedValues[key] = values[key];
    }
  });
  if (Object.keys(changedValues).length === 0) {
    return null;
  }
  return changedValues as Values;
};
