import axios from "axios";
import { PlantWasteApiPlantWasteControllerImportRequest } from "../api";
import { BaseAPI, BASE_PATH } from "../api/base";

export class WasteImportApi extends BaseAPI {
  public plantWasteControllerImport(
    requestParameters: PlantWasteApiPlantWasteControllerImportRequest
  ) {
    const instance = axios.create({
      baseURL: this.configuration ? this.configuration.basePath : BASE_PATH,
      headers: {
        "content-type": "multipart/form-data",
        ...this.configuration?.baseOptions.headers,
      },
    });

    const formData = new FormData();
    formData.append("file", requestParameters.file as Blob);
    if (requestParameters.mapping) {
      formData.append("mapping", requestParameters.mapping);
    }
    return instance.post(
      `/plant/${requestParameters.plantId}/waste/import`,
      formData
    );
  }
}
