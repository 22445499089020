import WasteEditorActions from "./store/wasteEditorActions";
import { AppStore } from "../../redux/configureStore";
import { APIs } from "../../apiProvider";

export const showWasteEditorModal = async (
  store: AppStore,
  api: APIs,
  plantId: string,
  wasteId?: string
) => {
  const wasteKeys = await api.plantWaste.plantWasteControllerGetWasteKeys({
    plantId,
  });
  // Open editor only for creating plant
  if (!wasteId) {
    store.dispatch(WasteEditorActions.setModalOpen(true, wasteKeys.data));
    return;
  }

  // Open editor for editing plant
  const waste = await api.waste.wasteControllerGetWaste({ wasteId });

  store.dispatch(
    WasteEditorActions.setModalOpen(true, wasteKeys.data, waste.data)
  );
};

export const closeWasteEditorModal = (store: AppStore) => {
  store.dispatch(WasteEditorActions.setModalOpen(false));
};

/**
 * Adds spaces to the waste key
 * @param wasteKey key from the backend
 * @returns key with spaces
 */
export const transformWasteKey = (wasteKey: string) => {
  const result = [];

  for (let i = 0; i < wasteKey.trim().length; i += 2) {
    result.push(wasteKey.substr(i, 2));
  }

  return result.join(" ");
};
