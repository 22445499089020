import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IPrivatePlantDataViewerState } from "./hooks";
import { useTranslation } from "react-i18next";

interface Props {
  privatePlantDataViewerState: IPrivatePlantDataViewerState;
}

const PrivatePlantDataViewer = ({ privatePlantDataViewerState }: Props) => {
  const { t } = useTranslation("privatePlantDataViewer");

  const { privatePlantData, privatePlantDataAddress } =
    privatePlantDataViewerState;

  const addressData = [
    privatePlantData?.billingCompanyName,
    privatePlantDataAddress?.street,
    privatePlantDataAddress?.postalCode,
    privatePlantDataAddress?.city,
  ].filter((addressItem) => addressItem);
  if (privatePlantData === undefined || privatePlantDataAddress === undefined) {
    return (
      <>
        <Box>
          <Typography variant="h4">{t("generalPricingConditions")}</Typography>
          <Skeleton animation="wave" height={21} />
        </Box>
        <Box marginTop={1}>
          <Typography variant="h4">{t("billingAddress")}</Typography>
          <Skeleton animation="wave" height={21} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <Typography variant="h4">{t("generalPricingConditions")}</Typography>
        <Typography variant="body1">
          {privatePlantDataViewerState.privatePlantData?.conditions}
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography variant="h4">{t("billingAddress")}</Typography>
        <Typography variant="body1">{addressData.join(", ")}</Typography>
      </Box>
    </>
  );
};

export default PrivatePlantDataViewer;
