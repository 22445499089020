/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddressDTO } from '../dto';
/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get one address
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerGetAddress: async (addressId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerGetAddress', 'addressId', addressId)
            const localVarPath = `/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get one address
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerGetAddress(addressId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerGetAddress(addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @summary get one address
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerGetAddress(addressId: string, options?: any): AxiosPromise<AddressDTO> {
            return localVarFp.addressControllerGetAddress(addressId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressControllerGetAddress operation in AddressApi.
 * @export
 * @interface AddressApiAddressControllerGetAddressRequest
 */
export interface AddressApiAddressControllerGetAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddressApiAddressControllerGetAddress
     */
    readonly addressId: string
}

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @summary get one address
     * @param {AddressApiAddressControllerGetAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerGetAddress(requestParameters: AddressApiAddressControllerGetAddressRequest, options?: any) {
        return AddressApiFp(this.configuration).addressControllerGetAddress(requestParameters.addressId, options).then((request) => request(this.axios, this.basePath));
    }
}
