import React, { memo, useCallback } from "react";
import { SelectProps } from "@material-ui/core";
import { FormikProps } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { mapEnumToOptions } from "./utils";
import { ISelectOption } from "../Select/utils";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  label?: string;
  options: Record<number, string>;
  translateValue: (value: string) => string;
}

const SelectMultiple: React.FC<SelectProps & Props> = memo(
  ({ formik, name, label, options, translateValue }) => {
    const handleChange = (
      evt: React.ChangeEvent<Record<string, number>>,
      newValue: ISelectOption[]
    ) => {
      formik.setFieldValue(
        name,
        newValue.map((v) => v.name)
      );
    };
    const mappedOptions = mapEnumToOptions(options, translateValue);
    const getValue = useCallback(() => {
      if (!formik.values[name]) return undefined;
      const option = mappedOptions.filter((o) =>
        formik.values[name].includes(o.name)
      );
      if (!option) return undefined;
      return option;
    }, [formik, name, mappedOptions]);

    return (
      <Autocomplete
        id={name}
        size="small"
        multiple
        value={getValue()}
        onChange={handleChange}
        options={mappedOptions}
        getOptionLabel={(option: ISelectOption) => {
          if (translateValue) {
            return translateValue(option.value);
          }
          return option.value;
        }}
        getOptionSelected={(option: ISelectOption, value: ISelectOption) =>
          option?.name === value?.name
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            size="small"
            variant="outlined"
          />
        )}
      />
    );
  }
);

SelectMultiple.displayName = "SelectMultiple";

export default SelectMultiple;
