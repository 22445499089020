export default {
  companyData: "Firmendaten",
  name: "Entsorgungsfirma",
  nameError: "Entsorgungsfirma Feld ist erforderlich",
  other: "Sonstiges",
  excluded: "Ausgeschlossen sind",
  remarks: "Sonstiges/ Bemerkungen",
  editRecord: "Datensatz bearbeiten",
  createRecord: "Datensatz erstellen",
  phone: "Telefon",
  email: "E-Mail",
  emailError: "Ungültige E-Mail Adresse",
  position: "Position",
  internalPersonTitle: "Anlagen Kontaktperson",
  externalPersonTitle: "REMONDIS Kontaktperson",
  firstName: "Vorname",
  lastName: "Nachname",
  cellPhone: "Festnetz",
  mobile: "Handy",
  category: "Anlagenart",
  pleaseSelect: "Bitte auswählen",
  externalPlant: "Es handelt sich um eine externe Anlage",
  formsOfDelivery: "Anlieferungsformen",
  deliveryConditions: "Anlieferbedingungen",
  plantData: "Analgendaten",
};
