import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  AddressDTO,
  ContactDTO,
  PersonDTO,
  PlantDTO,
  UpdateAddressDTO,
  UpdateContactDTO,
  UpdatePlantDTO,
} from "../../api";
import { useAPI } from "../../apiProvider";
import {
  Input,
  FormButtons,
  getChangedValues,
  Select,
  SingleCheckbox,
  CheckboxGroup,
  CategoryDetailSelect,
} from "../../components/Form";
import CardTitle from "../../components/CardTitle";
import Modal from "../../components/TransitionsModal";
import {
  closePlantEditorModal,
  mapPlantFromValues,
  mapAddressFromValues,
  mapContactFromValues,
  mapEditorValues,
  createInternalPersonAndItsContact,
  createExternalPersonAndItsContact,
  updateInternalPersonAnItsContact,
  updateExternalPersonAnItsContact,
} from "./utils";
import { store } from "../../redux/configureStore";
import { useSelector } from "../../redux/utils";
import {
  PlantEditorValues,
  mapDeliveryFormsValuesToBoolean,
  mapCategory,
} from "./utils";
import { getExistingValues } from "../../components/Form/utils";
import ContactFormFields from "./ContactFormFields";
import PersonFormFields, { PersonTypes } from "./PersonFormFields";
import {
  categories,
  FormsOfDelivery,
  FormsOfDeliveryTransport,
} from "../../enum/plant";
import AddressEditor, { useAddressEditorForm } from "../AddressEditor";
import DeliveryConditionInputFields from "../DeliveryConditionInputFields";
import { useDeliveryConditionsInputFields } from "../DeliveryConditionInputFields/hooks";

const useStyles = makeStyles(() => ({
  detailSearchFieldHeader: {
    marginBottom: "10px",
  },
}));

interface Props {
  onAction?: () => void;
}

const PlantEditor = ({ onAction }: Props) => {
  const { t } = useTranslation("plantEditor");
  const api = useAPI();

  const classes = useStyles();

  const deliveryConditionsSearchValidationSchema =
    useDeliveryConditionsInputFields();

  const history = useHistory();

  const plant: PlantDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedPlant,
    shallowEqual
  );

  const address: AddressDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedAddress,
    shallowEqual
  );

  const contact: ContactDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedContact,
    shallowEqual
  );

  const internalPerson: PersonDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedInternalPerson,
    shallowEqual
  );

  const externalPerson: PersonDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedExternalPerson,
    shallowEqual
  );

  const internalPersonContact: ContactDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedInternalPersonContact,
    shallowEqual
  );

  const externalPersonContact: ContactDTO | null = useSelector(
    (state) => state.modules.plantEditor.selectedExternalPersonContact,
    shallowEqual
  );

  const addressEditorForm = useAddressEditorForm();

  const validationSchema = yup
    .object({
      name: yup.string().required(t("nameError")),
      email: yup.string().email(t("emailError")),
    })
    .concat(addressEditorForm.addressValidationSchema)
    .concat(deliveryConditionsSearchValidationSchema);

  const handleUpdatePlant = async (
    values: PlantEditorValues,
    initialPlantValues: PlantDTO,
    initialAddressValues: AddressDTO,
    initialContactValues: ContactDTO,
    initialInternalPersonValues: PersonDTO | null,
    initialExternalPersonValues: PersonDTO | null,
    initialInternalPersonContactValues: ContactDTO | null,
    initialExternalPersonContactValues: ContactDTO | null
  ) => {
    let internalPersonId = initialPlantValues.internalPersonId;
    let externalPersonId = initialPlantValues.externalPersonId;

    if (
      internalPersonId &&
      initialInternalPersonValues &&
      initialInternalPersonContactValues
    ) {
      await updateInternalPersonAnItsContact(
        values,
        initialInternalPersonValues,
        initialInternalPersonContactValues,
        api
      );
    } else {
      internalPersonId = await createInternalPersonAndItsContact(values, api);
    }

    if (
      externalPersonId &&
      initialExternalPersonValues &&
      initialExternalPersonContactValues
    ) {
      await updateExternalPersonAnItsContact(
        values,
        initialExternalPersonValues,
        initialExternalPersonContactValues,
        api
      );
    } else {
      externalPersonId = await createExternalPersonAndItsContact(values, api);
    }

    const plantData = getChangedValues<
      PlantEditorValues,
      PlantDTO,
      UpdatePlantDTO
    >(values, initialPlantValues);

    if (
      plantData ||
      (!initialPlantValues.internalPersonId && internalPersonId) ||
      (!initialPlantValues.externalPersonId && externalPersonId)
    ) {
      await api.plant.plantControllerUpdatePlant({
        plantId: initialPlantValues.id,
        updatePlantDTO: {
          ...plantData,
          ...mapDeliveryFormsValuesToBoolean(values.formsOfDelivery),
          ...mapDeliveryFormsValuesToBoolean(values.formsOfDeliveryTransport),
          category: plantData?.category
            ? mapCategory(
                Number(plantData?.category),
                Number(values.categoryDetail)
              )
            : undefined,
          internalPersonId:
            !initialPlantValues.internalPersonId && internalPersonId
              ? internalPersonId
              : undefined,
          externalPersonId:
            !initialPlantValues.externalPersonId && externalPersonId
              ? externalPersonId
              : undefined,
          deliveryConditionViscosity: plantData?.deliveryConditionViscosity
            ? Number(plantData?.deliveryConditionViscosity)
            : null,
          deliveryConditionSludgeSubstance:
            plantData?.deliveryConditionSludgeSubstance
              ? Number(plantData?.deliveryConditionSludgeSubstance)
              : null,
          deliveryConditionDissolvedSolid:
            plantData?.deliveryConditionDissolvedSolid
              ? Number(plantData?.deliveryConditionDissolvedSolid)
              : null,
          deliveryConditionSuspendedSolids:
            plantData?.deliveryConditionSuspendedSolids
              ? Number(plantData?.deliveryConditionSuspendedSolids)
              : null,
          deliveryConditionParticleSize:
            plantData?.deliveryConditionParticleSize
              ? Number(plantData?.deliveryConditionParticleSize)
              : null,
          deliveryConditionPhValueMin: plantData?.deliveryConditionPhValueMin
            ? Number(plantData?.deliveryConditionPhValueMin)
            : null,
          deliveryConditionPhValueMax: plantData?.deliveryConditionPhValueMax
            ? Number(plantData?.deliveryConditionPhValueMax)
            : null,
          deliveryConditionPcbSubstancePrefix:
            plantData?.deliveryConditionPcbSubstancePrefix
              ? Number(plantData?.deliveryConditionPcbSubstancePrefix)
              : null,
          deliveryConditionPcbSubstance:
            plantData?.deliveryConditionPcbSubstance
              ? Number(plantData?.deliveryConditionPcbSubstance)
              : null,
          deliveryConditionHeatingValuePrefix:
            plantData?.deliveryConditionHeatingValuePrefix
              ? Number(plantData?.deliveryConditionHeatingValuePrefix)
              : null,
          deliveryConditionHeatingValue:
            plantData?.deliveryConditionHeatingValue
              ? Number(plantData?.deliveryConditionHeatingValue)
              : null,
        },
      });
    }

    const addressData = getChangedValues<
      PlantEditorValues,
      AddressDTO,
      UpdateAddressDTO
    >(values, initialAddressValues);
    if (addressData) {
      await api.plantAddress.plantAddressControllerUpdatePlantAddress({
        plantId: initialPlantValues.id,
        updateAddressDTO: addressData,
      });
    }

    const contactData = getChangedValues<
      PlantEditorValues,
      ContactDTO,
      UpdateContactDTO
    >(values, initialContactValues);
    if (contactData) {
      await api.plantContact.plantContactControllerUpdatePlantContact({
        plantId: initialPlantValues.id,
        updateContactDTO: contactData,
      });
    }
  };

  const handleCreatePlant = async (values: PlantEditorValues) => {
    const internalPersonId = await createInternalPersonAndItsContact(
      values,
      api
    );
    const externalPersonId = await createExternalPersonAndItsContact(
      values,
      api
    );

    const plantData = mapPlantFromValues(
      values,
      internalPersonId,
      externalPersonId
    );
    const response = await api.plant.plantControllerCreatePlant({
      createPlantDTO: plantData,
    });
    const plantId = response.data;

    const addressData = mapAddressFromValues(values);
    const changedAddressData = getExistingValues<UpdateAddressDTO>(addressData);
    if (changedAddressData) {
      await api.plantAddress.plantAddressControllerUpdatePlantAddress({
        plantId,
        updateAddressDTO: changedAddressData,
      });
    }

    const contactData = mapContactFromValues(values);
    const changedContactData = getExistingValues<UpdateContactDTO>(contactData);
    if (changedContactData) {
      await api.plantContact.plantContactControllerUpdatePlantContact({
        plantId,
        updateContactDTO: changedContactData,
      });
    }

    history.push(`/plant/${plantId}`);
  };

  const handleSubmit = async (
    values: PlantEditorValues,
    formikHelpers: FormikHelpers<PlantEditorValues>
  ) => {
    formikHelpers.setSubmitting(true);
    if (plant && address && contact) {
      await handleUpdatePlant(
        values,
        plant,
        address,
        contact,
        internalPerson,
        externalPerson,
        internalPersonContact,
        externalPersonContact
      );
    } else {
      await handleCreatePlant(values);
    }
    formikHelpers.setSubmitting(false);
    handleCloseModal();
    if (onAction) onAction();
  };

  const handleCloseModal = () => closePlantEditorModal(store);

  const isModalOpen = useSelector(
    (state) => state.modules.plantEditor.modalOpen,
    shallowEqual
  );

  return (
    <Modal
      title={t("createRecord")}
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <Formik
        initialValues={mapEditorValues(
          plant,
          address,
          contact,
          internalPerson,
          externalPerson,
          internalPersonContact,
          externalPersonContact
        )}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box pb={2} pt={2}>
                  <CardTitle>{t("companyData")}</CardTitle>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Input name="name" label={t("name")} />
              </Grid>
              <AddressEditor />
              <ContactFormFields />
              <PersonFormFields personType={PersonTypes.external} />
              <PersonFormFields personType={PersonTypes.internal} />
              <Grid item xs={12}>
                <Box pb={2} pt={2}>
                  <CardTitle>{t("plantData")}</CardTitle>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="category"
                  label={t("category")}
                  options={categories}
                  onChangeHook={() => {
                    setFieldValue("categoryDetail", 0);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CategoryDetailSelect categoryNumber={values.category || 1} />
              </Grid>
              <Grid item xs={12}>
                <SingleCheckbox
                  name="isExternalPlant"
                  label={t("externalPlant")}
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box m={1} className={classes.detailSearchFieldHeader}>
                    {t("formsOfDelivery")}
                  </Box>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <CheckboxGroup
                        checkboxes={FormsOfDelivery}
                        groupKey="formsOfDelivery"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CheckboxGroup
                        checkboxes={FormsOfDeliveryTransport}
                        groupKey="formsOfDeliveryTransport"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box m={1} className={classes.detailSearchFieldHeader}>
                    {t("deliveryConditions")}
                  </Box>
                  <DeliveryConditionInputFields />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box pb={2} pt={2}>
                  <CardTitle>{t("other")}</CardTitle>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="excluded"
                  label={t("excluded")}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Input name="notes" label={t("remarks")} />
              </Grid>
            </Grid>
            <FormButtons onCancel={handleCloseModal} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PlantEditor;
