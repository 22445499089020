import React from "react";
import { makeStyles, Container, Box, Typography } from "@material-ui/core";
import PlantList, { useMarkedPlantListState } from "../../module/PlantList";
import CardTitle from "../../components/CardTitle";
import { useTranslation } from "react-i18next";
import PlantSearch, { usePlantSearchState } from "../../module/PlantSearch";
import { PageBackButton } from "../../components/PageButtons";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    paddingTop: 50,
  },
  headContainer: {
    backgroundColor: theme.palette.lightBg,
  },
  subtitle: {
    marginTop: 20,
  },
}));

const MarkedPlantsContainer = () => {
  const { t } = useTranslation("plantList");
  const classes = useStyles();
  const { search, setSearch } = usePlantSearchState();
  const markedPlantListState = useMarkedPlantListState({
    pageSize: 20,
    filter: search,
  });

  return (
    <>
      <Box className={classes.headContainer} pt={3} pb={3}>
        <Container>
          <Grid container spacing={3} className={classes.headContainer}>
            <Grid item xs={4}>
              <PageBackButton />
            </Grid>
            <Grid item xs={12}>
              <CardTitle>{t("markedPlants")}</CardTitle>
              <Typography className={classes.subtitle}>
                {t("storedData")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <PlantSearch search={search} onSetSearch={setSearch} hideTitle />
      </Box>
      <Container>
        <div className={classes.listContainer}>
          <PlantList
            customEmptyMessage={t("noEntries")}
            plantListState={markedPlantListState}
            search={search}
            onSetSearch={setSearch}
          />
        </div>
      </Container>
    </>
  );
};

export default MarkedPlantsContainer;
