export default {
  deliveryConditionViscosity: "max. Viskosität",
  deliveryConditionSludgeSubstance: "max. Schlammgehalt",
  deliveryConditionDissolvedSolid: "max. Anteil gelöster Feststoff",
  deliveryConditionSuspendedSolids: "max. Anteil an Schwebstoffen",
  deliveryConditionParticleSize: "max. Partiekelgröße",
  deliveryConditionPhValue: "pH-Wert",
  deliveryConditionPcbSubstance: "PCB-Gehalt",
  deliveryConditionHeatingValue: "Heizwert",
};
