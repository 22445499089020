import React from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  customEmptyMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    textAlign: "center",
  },
  icon: {
    fontSize: 60,
    color: theme.palette.secondary.main,
  },
  text: {
    color: theme.palette.secondary.main,
    paddingTop: 32,
  },
}));

const PlantListEmptyState = ({ customEmptyMessage }: Props) => {
  const { t } = useTranslation("plantList");
  const classes = useStyles();

  if (customEmptyMessage) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.text} variant="h2">
          {customEmptyMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <FontAwesomeIcon icon={faSearch} className={classes.icon} />
      <Typography className={classes.text} variant="h2">
        {t("emptyState")}
      </Typography>
    </Box>
  );
};

export default PlantListEmptyState;
