import React, { useContext, useEffect, useState } from "react";
import { ConfigApi } from "./api/api/config-api";
import { PlantApi } from "./api/api/plant-api";
import { PlantWasteApi } from "./api/api/plant-waste-api";
import { AddressApi } from "./api/api/address-api";
import { PlantAddressApi } from "./api/api/plant-address-api";
import { Configuration } from "./api/configuration";
import { ContactApi } from "./api/api/contact-api";
import { PersonApi } from "./api/api/person-api";
import { PlantContactApi } from "./api/api/plant-contact-api";
import { PersonContactApi } from "./api/api/person-contact-api";
import { WasteApi } from "./api/api/waste-api";
import { PrivatePlantDataApi } from "./api/api/private-plant-data-api";
import { PrivatePlantAddressDataApi } from "./api/api/private-plant-address-data-api";
import { MarkedPlantApi } from "./api/api/marked-plant-api";
import { WasteImportApi } from "./customApi/waste-import-api";

interface Props {
  config: Configuration;
  children: React.ReactNode;
}

export interface APIs {
  plant: PlantApi;
  config: ConfigApi;
  plantWaste: PlantWasteApi;
  address: AddressApi;
  plantAddress: PlantAddressApi;
  contact: ContactApi;
  plantContact: PlantContactApi;
  person: PersonApi;
  personContact: PersonContactApi;
  waste: WasteApi;
  privatePlantData: PrivatePlantDataApi;
  privatePlantAddressData: PrivatePlantAddressDataApi;
  markedPlant: MarkedPlantApi;
  wasteImport: WasteImportApi;

  /**
   * Injects a new config for the apis.
   */
  setAPIConfig: (conf: Configuration) => void;
}

export const APIContext = React.createContext<APIs | undefined>(undefined);

// The apis are outside, because otherwise it would trigger all loading-useEffects on the
// current page to re-trigger because of state change. (e.g. when refreshing the token)
const apis: APIs = {
  plant: new PlantApi(),
  config: new ConfigApi(),
  plantWaste: new PlantWasteApi(),
  address: new AddressApi(),
  plantAddress: new PlantAddressApi(),
  contact: new ContactApi(),
  plantContact: new PlantContactApi(),
  person: new PersonApi(),
  personContact: new PersonContactApi(),
  waste: new WasteApi(),
  privatePlantData: new PrivatePlantDataApi(),
  privatePlantAddressData: new PrivatePlantAddressDataApi(),
  markedPlant: new MarkedPlantApi(),
  wasteImport: new WasteImportApi(),
  setAPIConfig: (config) => {
    apis.plant = new PlantApi(config);
    apis.config = new ConfigApi(config);
    apis.plantWaste = new PlantWasteApi(config);
    apis.address = new AddressApi(config);
    apis.plantAddress = new PlantAddressApi(config);
    apis.contact = new ContactApi(config);
    apis.plantContact = new PlantContactApi(config);
    apis.person = new PersonApi(config);
    apis.personContact = new PersonContactApi(config);
    apis.waste = new WasteApi(config);
    apis.privatePlantData = new PrivatePlantDataApi(config);
    apis.privatePlantAddressData = new PrivatePlantAddressDataApi(config);
    apis.markedPlant = new MarkedPlantApi(config);
    apis.wasteImport = new WasteImportApi(config);
  },
};

export const APIProvider = ({ children, config }: Props) => {
  // This is a config update counter.
  // The sole purpose is
  //  1. to prevent rendering children when the api is not ready yet
  //  2. to trigger a re-render as the actual config is outside of the react-state
  const [apiCounter, setApiCounter] = useState(0);

  useEffect(() => {
    if (!config) {
      return;
    }

    apis.setAPIConfig(config);
    // Update the value to trigger a re-render
    setApiCounter((v) => v + 1);
  }, [config]);

  return (
    <APIContext.Provider value={apis}>
      {config && apiCounter ? children : null}
    </APIContext.Provider>
  );
};

export const useAPI = () => {
  const apis = useContext(APIContext);
  if (apis === undefined) {
    throw new Error("No api injected.");
  }
  return apis;
};
