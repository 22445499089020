import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as dotenv from "dotenv";
import { store } from "./redux/configureStore";
import "./utils/i18n";
import { Login } from "./module/Auth";

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Login>
        <App />
      </Login>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
