import { createReducer } from "@reduxjs/toolkit";
import WasteEditorActions from "./wasteEditorActions";
import { IWasteEditorState } from "./wasteEditorTypes";

const initialState: IWasteEditorState = {
  modalOpen: false,
  usedWasteKeys: [],
  selectedWaste: null,
};

export const WasteEditorReducer = createReducer(initialState, (builder) => {
  builder.addCase(WasteEditorActions.setModalOpen, (state, action) => {
    // if close modal reset selected plant
    if (!action.payload.modalOpen) {
      state.modalOpen = false;
      state.selectedWaste = null;
      state.usedWasteKeys = [];
      return;
    }
    // open modal
    state.modalOpen = true;
    // set waste keys that already in use by this plant
    state.usedWasteKeys = action.payload.usedWasteKeys;
    // open modal to edit plant
    state.selectedWaste = action.payload.selectedWaste || null;
  });
});
