/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateWasteDTO } from '../dto';
// @ts-ignore
import { ImportWasteResponseDTO } from '../dto';
// @ts-ignore
import { WasteListDTO } from '../dto';
/**
 * PlantWasteApi - axios parameter creator
 * @export
 */
export const PlantWasteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary adds new waste to a plant
         * @param {string} plantId 
         * @param {CreateWasteDTO} createWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerAddWaste: async (plantId: string, createWasteDTO: CreateWasteDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('plantWasteControllerAddWaste', 'plantId', plantId)
            // verify required parameter 'createWasteDTO' is not null or undefined
            assertParamExists('plantWasteControllerAddWaste', 'createWasteDTO', createWasteDTO)
            const localVarPath = `/plant/{plantId}/waste`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWasteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists the waste for a plant
         * @param {string} plantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerGetPlantWasteList: async (plantId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('plantWasteControllerGetPlantWasteList', 'plantId', plantId)
            const localVarPath = `/plant/{plantId}/waste`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists the waste keys used by a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerGetWasteKeys: async (plantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('plantWasteControllerGetWasteKeys', 'plantId', plantId)
            const localVarPath = `/plant/{plantId}/waste/key`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary parse a file for waste-keys and import them for the given plant
         * @param {string} plantId 
         * @param {object} file 
         * @param {string} [mapping] The mapping is a JSON string of a type specific to the parse implementation. For example pdf-files need WasteCategoryGroupEntity[].
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerImport: async (plantId: string, file: object, mapping?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('plantWasteControllerImport', 'plantId', plantId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('plantWasteControllerImport', 'file', file)
            const localVarPath = `/plant/{plantId}/waste/import`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (mapping !== undefined) { 
                localVarFormParams.append('mapping', mapping as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlantWasteApi - functional programming interface
 * @export
 */
export const PlantWasteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlantWasteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary adds new waste to a plant
         * @param {string} plantId 
         * @param {CreateWasteDTO} createWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantWasteControllerAddWaste(plantId: string, createWasteDTO: CreateWasteDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantWasteControllerAddWaste(plantId, createWasteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists the waste for a plant
         * @param {string} plantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantWasteControllerGetPlantWasteList(plantId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WasteListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantWasteControllerGetPlantWasteList(plantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists the waste keys used by a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantWasteControllerGetWasteKeys(plantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantWasteControllerGetWasteKeys(plantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary parse a file for waste-keys and import them for the given plant
         * @param {string} plantId 
         * @param {object} file 
         * @param {string} [mapping] The mapping is a JSON string of a type specific to the parse implementation. For example pdf-files need WasteCategoryGroupEntity[].
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantWasteControllerImport(plantId: string, file: object, mapping?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportWasteResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantWasteControllerImport(plantId, file, mapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlantWasteApi - factory interface
 * @export
 */
export const PlantWasteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlantWasteApiFp(configuration)
    return {
        /**
         * 
         * @summary adds new waste to a plant
         * @param {string} plantId 
         * @param {CreateWasteDTO} createWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerAddWaste(plantId: string, createWasteDTO: CreateWasteDTO, options?: any): AxiosPromise<string> {
            return localVarFp.plantWasteControllerAddWaste(plantId, createWasteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists the waste for a plant
         * @param {string} plantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerGetPlantWasteList(plantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<WasteListDTO> {
            return localVarFp.plantWasteControllerGetPlantWasteList(plantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists the waste keys used by a plant
         * @param {string} plantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerGetWasteKeys(plantId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.plantWasteControllerGetWasteKeys(plantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary parse a file for waste-keys and import them for the given plant
         * @param {string} plantId 
         * @param {object} file 
         * @param {string} [mapping] The mapping is a JSON string of a type specific to the parse implementation. For example pdf-files need WasteCategoryGroupEntity[].
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantWasteControllerImport(plantId: string, file: object, mapping?: string, options?: any): AxiosPromise<ImportWasteResponseDTO> {
            return localVarFp.plantWasteControllerImport(plantId, file, mapping, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for plantWasteControllerAddWaste operation in PlantWasteApi.
 * @export
 * @interface PlantWasteApiPlantWasteControllerAddWasteRequest
 */
export interface PlantWasteApiPlantWasteControllerAddWasteRequest {
    /**
     * 
     * @type {string}
     * @memberof PlantWasteApiPlantWasteControllerAddWaste
     */
    readonly plantId: string

    /**
     * 
     * @type {CreateWasteDTO}
     * @memberof PlantWasteApiPlantWasteControllerAddWaste
     */
    readonly createWasteDTO: CreateWasteDTO
}

/**
 * Request parameters for plantWasteControllerGetPlantWasteList operation in PlantWasteApi.
 * @export
 * @interface PlantWasteApiPlantWasteControllerGetPlantWasteListRequest
 */
export interface PlantWasteApiPlantWasteControllerGetPlantWasteListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlantWasteApiPlantWasteControllerGetPlantWasteList
     */
    readonly plantId: string

    /**
     * 
     * @type {number}
     * @memberof PlantWasteApiPlantWasteControllerGetPlantWasteList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PlantWasteApiPlantWasteControllerGetPlantWasteList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for plantWasteControllerGetWasteKeys operation in PlantWasteApi.
 * @export
 * @interface PlantWasteApiPlantWasteControllerGetWasteKeysRequest
 */
export interface PlantWasteApiPlantWasteControllerGetWasteKeysRequest {
    /**
     * 
     * @type {string}
     * @memberof PlantWasteApiPlantWasteControllerGetWasteKeys
     */
    readonly plantId: string
}

/**
 * Request parameters for plantWasteControllerImport operation in PlantWasteApi.
 * @export
 * @interface PlantWasteApiPlantWasteControllerImportRequest
 */
export interface PlantWasteApiPlantWasteControllerImportRequest {
    /**
     * 
     * @type {string}
     * @memberof PlantWasteApiPlantWasteControllerImport
     */
    readonly plantId: string

    /**
     * 
     * @type {object}
     * @memberof PlantWasteApiPlantWasteControllerImport
     */
    readonly file: object

    /**
     * The mapping is a JSON string of a type specific to the parse implementation. For example pdf-files need WasteCategoryGroupEntity[].
     * @type {string}
     * @memberof PlantWasteApiPlantWasteControllerImport
     */
    readonly mapping?: string
}

/**
 * PlantWasteApi - object-oriented interface
 * @export
 * @class PlantWasteApi
 * @extends {BaseAPI}
 */
export class PlantWasteApi extends BaseAPI {
    /**
     * 
     * @summary adds new waste to a plant
     * @param {PlantWasteApiPlantWasteControllerAddWasteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantWasteApi
     */
    public plantWasteControllerAddWaste(requestParameters: PlantWasteApiPlantWasteControllerAddWasteRequest, options?: any) {
        return PlantWasteApiFp(this.configuration).plantWasteControllerAddWaste(requestParameters.plantId, requestParameters.createWasteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists the waste for a plant
     * @param {PlantWasteApiPlantWasteControllerGetPlantWasteListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantWasteApi
     */
    public plantWasteControllerGetPlantWasteList(requestParameters: PlantWasteApiPlantWasteControllerGetPlantWasteListRequest, options?: any) {
        return PlantWasteApiFp(this.configuration).plantWasteControllerGetPlantWasteList(requestParameters.plantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists the waste keys used by a plant
     * @param {PlantWasteApiPlantWasteControllerGetWasteKeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantWasteApi
     */
    public plantWasteControllerGetWasteKeys(requestParameters: PlantWasteApiPlantWasteControllerGetWasteKeysRequest, options?: any) {
        return PlantWasteApiFp(this.configuration).plantWasteControllerGetWasteKeys(requestParameters.plantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary parse a file for waste-keys and import them for the given plant
     * @param {PlantWasteApiPlantWasteControllerImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantWasteApi
     */
    public plantWasteControllerImport(requestParameters: PlantWasteApiPlantWasteControllerImportRequest, options?: any) {
        return PlantWasteApiFp(this.configuration).plantWasteControllerImport(requestParameters.plantId, requestParameters.file, requestParameters.mapping, options).then((request) => request(this.axios, this.basePath));
    }
}
