/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateAddressDTO } from '../dto';
/**
 * PrivatePlantAddressDataApi - axios parameter creator
 * @export
 */
export const PrivatePlantAddressDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary update address of a private plant
         * @param {string} plantId 
         * @param {UpdateAddressDTO} updateAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantAddressControllerUpdatePrivatePlantBillingAddress: async (plantId: string, updateAddressDTO: UpdateAddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plantId' is not null or undefined
            assertParamExists('privatePlantAddressControllerUpdatePrivatePlantBillingAddress', 'plantId', plantId)
            // verify required parameter 'updateAddressDTO' is not null or undefined
            assertParamExists('privatePlantAddressControllerUpdatePrivatePlantBillingAddress', 'updateAddressDTO', updateAddressDTO)
            const localVarPath = `/plant/{plantId}/private/billing-address`
                .replace(`{${"plantId"}}`, encodeURIComponent(String(plantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivatePlantAddressDataApi - functional programming interface
 * @export
 */
export const PrivatePlantAddressDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivatePlantAddressDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary update address of a private plant
         * @param {string} plantId 
         * @param {UpdateAddressDTO} updateAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privatePlantAddressControllerUpdatePrivatePlantBillingAddress(plantId: string, updateAddressDTO: UpdateAddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privatePlantAddressControllerUpdatePrivatePlantBillingAddress(plantId, updateAddressDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivatePlantAddressDataApi - factory interface
 * @export
 */
export const PrivatePlantAddressDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivatePlantAddressDataApiFp(configuration)
    return {
        /**
         * 
         * @summary update address of a private plant
         * @param {string} plantId 
         * @param {UpdateAddressDTO} updateAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privatePlantAddressControllerUpdatePrivatePlantBillingAddress(plantId: string, updateAddressDTO: UpdateAddressDTO, options?: any): AxiosPromise<void> {
            return localVarFp.privatePlantAddressControllerUpdatePrivatePlantBillingAddress(plantId, updateAddressDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privatePlantAddressControllerUpdatePrivatePlantBillingAddress operation in PrivatePlantAddressDataApi.
 * @export
 * @interface PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddressRequest
 */
export interface PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddress
     */
    readonly plantId: string

    /**
     * 
     * @type {UpdateAddressDTO}
     * @memberof PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddress
     */
    readonly updateAddressDTO: UpdateAddressDTO
}

/**
 * PrivatePlantAddressDataApi - object-oriented interface
 * @export
 * @class PrivatePlantAddressDataApi
 * @extends {BaseAPI}
 */
export class PrivatePlantAddressDataApi extends BaseAPI {
    /**
     * 
     * @summary update address of a private plant
     * @param {PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivatePlantAddressDataApi
     */
    public privatePlantAddressControllerUpdatePrivatePlantBillingAddress(requestParameters: PrivatePlantAddressDataApiPrivatePlantAddressControllerUpdatePrivatePlantBillingAddressRequest, options?: any) {
        return PrivatePlantAddressDataApiFp(this.configuration).privatePlantAddressControllerUpdatePrivatePlantBillingAddress(requestParameters.plantId, requestParameters.updateAddressDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
