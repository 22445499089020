import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Variant } from "@material-ui/core/styles/createTypography";
import { AddressDTO } from "../../api";

interface Props {
  address?: AddressDTO;
  titleVariant?: Variant;
  valueVariant?: Variant;
}

const AddressViewer = ({
  address,
  titleVariant = "h4",
  valueVariant = "body1",
}: Props) => {
  const { t } = useTranslation("addressViewer");

  if (!address) {
    return (
      <>
        <Skeleton animation="wave" height={21} />
        <Skeleton animation="wave" height={22} />
      </>
    );
  }

  if (!address.street && !address.postalCode && !address.city) {
    // don't show if address is empty
    return null;
  }
  return (
    <Box>
      <Typography variant={titleVariant}>{t("address")}</Typography>
      <Typography variant={valueVariant}>
        {address.street.trim()}
        {address.street ? ", " : ""}
        {address.postalCode.trim()} {address.city.trim()}
      </Typography>
    </Box>
  );
};

export default AddressViewer;
