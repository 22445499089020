import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { PlantDTO } from "../../api";

const useSytels = makeStyles({
  defaultCursor: {
    cursor: "default",
  },
});
interface Props {
  plant: PlantDTO;
}

interface ILabelValueCheckbox {
  label: string;
  value: boolean;
}

const createFormsOfDeliveryTypeArray = (plant: PlantDTO) => {
  const formsOfDeliveryTypeArray: ILabelValueCheckbox[] = [
    {
      label: "deliveryFormLiquid",
      value: plant.deliveryFormLiquid,
    },
    {
      label: "deliveryFormSolid",
      value: plant.deliveryFormSolid,
    },
    {
      label: "deliveryFormPasty",
      value: plant.deliveryFormPasty,
    },
    {
      label: "deliveryFormSiloCompatible",
      value: plant.deliveryFormSiloCompatible,
    },
    {
      label: "deliveryFormContainer",
      value: plant.deliveryFormContainer,
    },
    {
      label: "deliveryFormBarrels",
      value: plant.deliveryFormBarrels,
    },
    {
      label: "deliveryFormLooseFilling",
      value: plant.deliveryFormLooseFilling,
    },
    {
      label: "deliveryFormBoxPallets",
      value: plant.deliveryFormBoxPallets,
    },
    {
      label: "deliveryFormBigBags",
      value: plant.deliveryFormBigBags,
    },
  ];
  return formsOfDeliveryTypeArray;
};

const createFormsOfDeliveryTransportArray = (plant: PlantDTO) => {
  const createFormsOfDeliveryTransportArray: ILabelValueCheckbox[] = [
    {
      label: "deliveryFormContainerTruck",
      value: plant.deliveryFormContainerTruck,
    },
    {
      label: "deliveryFormSiloTruck",
      value: plant.deliveryFormSiloTruck,
    },
    {
      label: "deliveryFormTiltSaddle",
      value: plant.deliveryFormTiltSaddle,
    },
    {
      label: "deliveryFormTanker",
      value: plant.deliveryFormTanker,
    },
    {
      label: "deliveryFormSuctionPressureTrolley",
      value: plant.deliveryFormSuctionPressureTrolley,
    },
    {
      label: "deliveryFormFuelDepot",
      value: plant.deliveryFormFuelDepot,
    },
    {
      label: "deliveryFormIBCS",
      value: plant.deliveryFormIBCS,
    },
    {
      label: "deliveryFormASP",
      value: plant.deliveryFormASP,
    },
    {
      label: "deliveryFormASF",
      value: plant.deliveryFormASF,
    },
  ];
  return createFormsOfDeliveryTransportArray;
};

interface IFormOfDeliveryCheckbox {
  labelValueItem: ILabelValueCheckbox;
}

const FormOfDeliveryCheckbox = ({
  labelValueItem,
}: IFormOfDeliveryCheckbox) => {
  const { t } = useTranslation("formsOfDeliveryViewer");
  const classes = useSytels();
  return (
    <FormControlLabel
      className={classes.defaultCursor}
      key={labelValueItem.label}
      control={
        <Checkbox
          className={classes.defaultCursor}
          checked={labelValueItem.value}
          name={labelValueItem.label}
          disableRipple
        />
      }
      label={t(labelValueItem.label)}
    />
  );
};

const FormsOfDeliveryViewer = ({ plant }: Props) => {
  const formsOfDeliveryTypeA: ILabelValueCheckbox[] =
    createFormsOfDeliveryTypeArray(plant);
  const formsOfDeliveryTransportArray: ILabelValueCheckbox[] =
    createFormsOfDeliveryTransportArray(plant);

  return (
    <Box>
      <Grid container>
        <Grid item xs={6} container>
          {formsOfDeliveryTypeA.map((labelValueCB) => (
            <Grid key={labelValueCB.label} item xs={12}>
              <FormOfDeliveryCheckbox labelValueItem={labelValueCB} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6} container>
          {formsOfDeliveryTransportArray.map((labelValueCB) => (
            <Grid key={labelValueCB.label} item xs={12}>
              <FormOfDeliveryCheckbox labelValueItem={labelValueCB} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormsOfDeliveryViewer;
