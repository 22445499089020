import { createMuiTheme } from "@material-ui/core";
import { colors, fonts } from "./variables";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    mainBg: React.CSSProperties["color"];
    lightBg: React.CSSProperties["color"];
  }
  interface PaletteOptions {
    mainBg: React.CSSProperties["color"];
    lightBg: React.CSSProperties["color"];
  }
}

export default createMuiTheme({
  typography: {
    fontFamily: [fonts.primary, "sans-serif"].join(","),
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.black,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
    },
    button: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.black,
    },
    h1: {
      fontSize: 28,
      fontWeight: 500,
      color: colors.black,
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
      color: colors.black,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
      color: colors.black,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.darkGrey,
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.darkGrey,
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: colors.blue,
      light: colors.lightBlue,
      dark: colors.black,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.grey,
      light: colors.lightGrey,
      dark: colors.darkGrey,
      contrastText: colors.white,
    },
    mainBg: "#f8f8fd",
    lightBg: "#ffffff",
  },
});
