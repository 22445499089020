import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { colors } from "../../styles/variables";
import { createWasteRow, getColumns, IWasteRow } from "./utils";
import { useTranslation } from "react-i18next";
import { IWasteViewerState } from "./hooks";
import WasteListItem from "./WasteListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
interface Props {
  wasteListViewerState: IWasteViewerState;
  onEditWaste: (wasteId: string) => void;
  wasteKeyExpireDate: string | null;
}

const useStyle = makeStyles({
  accordion: {
    boxShadow: "none",
    paddingBottom: "10px",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
    paddingLeft: "16px",
  },
  secondaryHeading: {
    color: colors.darkGrey,
  },
});

const WasteListViewer = ({
  wasteListViewerState,
  onEditWaste,
  wasteKeyExpireDate,
}: Props) => {
  const [rows, setRows] = useState<IWasteRow[]>([]);
  const { t } = useTranslation("wasteListViewer");
  const columns = getColumns(t);
  const [expanded, setExpanded] = useState<boolean>(false);

  const classes = useStyle();

  useEffect(() => {
    if (wasteListViewerState.wasteList) {
      const wasteRows = wasteListViewerState.wasteList.waste.map((wasteItem) =>
        createWasteRow(wasteItem, t)
      );
      const wasteRowsFilteredFromNull = wasteRows.filter((r) => r !== null);
      setRows(wasteRowsFilteredFromNull as IWasteRow[]);
    }
  }, [wasteListViewerState.wasteList, t]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    wasteListViewerState.setPage(page);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    wasteListViewerState.setPageSize(parseInt(event.target.value, 10));
    wasteListViewerState.setPage(0);
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography className={classes.heading}>
          {wasteListViewerState?.wasteList?.count} {t("wasteKey")}
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {wasteKeyExpireDate && (
            <>
              {t("expireDateWasteKeys")}{" "}
              {DateTime.fromISO(wasteKeyExpireDate)
                .setLocale("de")
                .toFormat("dd.MM.yyyy")}
            </>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                  <TableCell key="action"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: IWasteRow) => {
                  return (
                    <WasteListItem
                      key={row.id}
                      onEditWaste={onEditWaste}
                      item={row}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 20]}
            count={wasteListViewerState.wasteList?.count || 0}
            rowsPerPage={wasteListViewerState.pageSize}
            page={wasteListViewerState.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangePageSize}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default WasteListViewer;
