import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import menu from "../module/Menu/translations";
import plantEditor from "../module/PlantEditor/translations";
import plantViewer from "../module/PlantViewer/translations";
import formsOfDeliveryViewer from "../module/FormsOfDeliveryViewer/translations";
import conditionsOfDeliveryViewer from "../module/ConditionsOfDeliveryViewer/translations";
import plantList from "../module/PlantList/translations";
import addressViewer from "../module/AddressViewer/translations";
import addressEditor from "../module/AddressEditor/translations";
import contactViewer from "../module/ContactViewer/translations";
import privatePlantDataViewer from "../module/PrivatePlantDataViewer/translations";
import plantSearch from "../module/PlantSearch/translations";
import wasteListViewer from "../module/WasteListViewer/translations";
import wasteEditor from "../module/WasteEditor/translations";
import privatePlantDataEditor from "../module/PrivatePlantDataEditor/translations";
import wasteImporter from "../module/WasteImporter/translations";
import plantListContainer from "../containers/PlantListContainer/translations";
import common from "../containers/Layout/translations";
import form from "../components/Form/translations";
import deliveryConditionInputFields from "../module/DeliveryConditionInputFields/translations";
import categoryDetailSearch from "../components/Form/CategoryDetailSelect/translations";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: {
        plantListContainer,
        menu,
        plantSearch,
        plantEditor,
        plantViewer,
        addressViewer,
        addressEditor,
        contactViewer,
        plantList,
        wasteListViewer,
        wasteEditor,
        wasteImporter,
        privatePlantDataViewer,
        privatePlantDataEditor,
        deliveryConditionInputFields,
        categoryDetailSearch,
        form,
        common,
        formsOfDeliveryViewer,
        conditionsOfDeliveryViewer,
      },
    },
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });
