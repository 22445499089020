import { useCallback, useEffect, useState } from "react";
import { AddressDTO, PrivatePlantDTO } from "../../api";
import { useAPI } from "../../apiProvider";

export interface IPrivatePlantDataViewerState {
  privatePlantData?: PrivatePlantDTO | null | undefined;
  privatePlantDataAddress?: AddressDTO | null | undefined;
  refreshPrivatePlantData: () => void;
}

/**
 * Loads the private plant data for a plant.
 * @param plantId
 * @param doInitialLoad can be set to false to prevent loading the data in the beginning. This can be used, if it is known that there is no private data.
 * @returns
 */
export function usePrivatePlantDataViewerState(
  plantId: string,
  doInitialLoad = true
): IPrivatePlantDataViewerState {
  const [privatePlantData, setPrivatePlantData] =
    useState<PrivatePlantDTO | null | undefined>();
  const [privatePlantDataAddress, setPrivatePlantDataAddress] =
    useState<AddressDTO | null | undefined>();

  const api = useAPI();
  const loadPrivatePlantData = useCallback(() => {
    api.privatePlantData
      .privatePlantControllerGetPrivatePlantData({
        plantId,
      })
      .then((privatePlant) => {
        api.address
          .addressControllerGetAddress({
            addressId: privatePlant.data.billingAddressId,
          })
          .then((privatePlantAddress) => {
            setPrivatePlantDataAddress(privatePlantAddress.data);
            setPrivatePlantData(privatePlant.data);
          });
      })
      .catch(() => {
        setPrivatePlantDataAddress(null);
        setPrivatePlantData(null);
      });
  }, [api.privatePlantData, api.address, plantId]);

  useEffect(() => {
    if (!doInitialLoad) {
      setPrivatePlantData(null);
      setPrivatePlantDataAddress(null);
      return;
    }

    loadPrivatePlantData();
  }, [doInitialLoad, loadPrivatePlantData]);

  return {
    privatePlantData,
    privatePlantDataAddress,
    refreshPrivatePlantData: loadPrivatePlantData,
  };
}
