import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Input } from "../../components/Form";
import { useTranslation } from "react-i18next";

export enum PersonTypes {
  internal = "internal",
  external = "external",
}

interface Props {
  personType: PersonTypes;
}

const ContactFormFields = ({ personType }: Props) => {
  const { t } = useTranslation("plantEditor");
  return (
    <>
      <Grid item xs={12}>
        <Box mt={1} mb={-1}>
          <Typography variant="subtitle2">
            {t(`${personType}PersonTitle`)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Input name={`${personType}FirstName`} label={t("firstName")} />
      </Grid>
      <Grid item xs={6}>
        <Input name={`${personType}LastName`} label={t("lastName")} />
      </Grid>
      <Grid item xs={12}>
        <Input name={`${personType}Position`} label={t("position")} />
      </Grid>
      <Grid item xs={6}>
        <Input name={`${personType}CellPhone`} label={t("cellPhone")} />
      </Grid>
      <Grid item xs={6}>
        <Input name={`${personType}Phone`} label={t("mobile")} />
      </Grid>
      <Grid item xs={12}>
        <Input name={`${personType}Email`} label={t("email")} />
      </Grid>
    </>
  );
};

export default ContactFormFields;
