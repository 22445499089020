import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../index";
import {
  categories,
  categoryDetails,
  getCategoryDetailsIndex,
  getMainCategory,
} from "../../../enum/plant";
import { Box } from "@material-ui/core";

interface Props {
  categoryNumber: number;
}

const CategoryDetailSelect = ({ categoryNumber }: Props) => {
  const { t } = useTranslation("categoryDetailSearch");
  const categoryNumCalculated = getCategoryDetailsIndex(categoryNumber);
  if (
    categoryNumCalculated &&
    categoryNumCalculated < categoryDetails.length &&
    Object.entries(categoryDetails[categoryNumCalculated - 1]).length > 0
  ) {
    return (
      <Select
        name="categoryDetail"
        label={`${t("typeOf")} ${categories[getMainCategory(categoryNumber)]}`}
        options={categoryDetails[categoryNumCalculated - 1]}
      />
    );
  }
  return <Box m={1}>{t("noSpecifications")}</Box>;
};

export default CategoryDetailSelect;
