import * as yup from "yup";
import { useTranslation } from "react-i18next";

export function useDeliveryConditionsInputFields() {
  const { t } = useTranslation("deliveryConditionInputFields");
  const searchValidationSchema = yup.object({
    deliveryConditionViscosity: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive")),
    deliveryConditionSludgeSubstance: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive"))
      .max(100, t("validationMustBeLessThan100")),
    deliveryConditionDissolvedSolid: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive"))
      .max(100, t("validationMustBeLessThan100")),
    deliveryConditionSuspendedSolids: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive"))
      .max(100, t("validationMustBeLessThan100")),
    deliveryConditionParticleSize: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive")),
    deliveryConditionPhValue: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .min(0, t("validationBetween0and14"))
      .max(14, t("validationBetween0and14")),
    deliveryConditionPcbSubstance: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive")),
    deliveryConditionHeatingValue: yup
      .number()
      .typeError(t("validationNumberRequired"))
      .positive(t("validationMustBePositive")),
  });

  return searchValidationSchema;
}
