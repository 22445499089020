import { createReducer } from "@reduxjs/toolkit";
import PlantEditorActions from "./plantEditorActions";
import { IPlantEditorState } from "./plantEditorTypes";

const initialState: IPlantEditorState = {
  modalOpen: false,
  selectedPlant: null,
  selectedAddress: null,
  selectedContact: null,
  selectedInternalPerson: null,
  selectedExternalPerson: null,
  selectedInternalPersonContact: null,
  selectedExternalPersonContact: null,
};

export const PlantEditorReducer = createReducer(initialState, (builder) => {
  builder.addCase(PlantEditorActions.setModalOpen, (state, action) => {
    // if close modal reset selected plant
    if (!action.payload.modalOpen) {
      state.modalOpen = false;
      state.selectedPlant = null;
      state.selectedAddress = null;
      state.selectedContact = null;
      state.selectedInternalPerson = null;
      state.selectedExternalPerson = null;
      state.selectedInternalPersonContact = null;
      state.selectedExternalPersonContact = null;
      return;
    }
    // open modal
    state.modalOpen = true;
    // open modal to edit plant
    state.selectedPlant = action.payload.selectedPlant || null;
    state.selectedAddress = action.payload.selectedAddress || null;
    state.selectedContact = action.payload.selectedContact || null;
    state.selectedInternalPerson =
      action.payload.selectedInternalPerson || null;
    state.selectedExternalPerson =
      action.payload.selectedExternalPerson || null;
    state.selectedInternalPersonContact =
      action.payload.selectedInternalPersonContact || null;
    state.selectedExternalPersonContact =
      action.payload.selectedExternalPersonContact || null;
  });
});
