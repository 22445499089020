import { useCallback, useEffect, useState } from "react";
import { PlantDTO } from "../../api";
import { useAPI } from "../../apiProvider";

interface IPlantViewerState {
  plant?: PlantDTO;
  refreshPlant: () => void;
}

export function usePlantViewerState(plantId: string): IPlantViewerState {
  const [plant, setPlant] = useState<PlantDTO>();

  const api = useAPI();
  const loadPlant = useCallback(async () => {
    const response = await api.plant.plantControllerGetPlant({ plantId });
    setPlant(response.data);
  }, [api.plant, plantId]);

  useEffect(() => {
    loadPlant();
  }, [loadPlant]);

  return {
    plant,
    refreshPlant: loadPlant,
  };
}
