export default {
  wasteKey: "Abfallschlüssel",
  notFound: "Unbekannt",
  wasteDesignation: "Abfallbezeichnung",
  additionalInformation: "Weitere Informationen",
  wasteListTitle: "Abfallrechtliche Daten",
  categories: "Zertifizierte Tätigkeit",
  category: {
    collect: "Sammeln",
    transport: "Befördern",
    store: "Lagern",
    treat: "Behandeln",
    process: "Verwerten",
    dispose: "Beseitigen",
    trade: "Handeln",
    broke: "Makeln",
  },
  expireDateWasteKeys: "Schlüssel laufen ab am:",
};
