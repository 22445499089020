export default {
  type: "Anlagenart",
  amount: "Anzahl",
  emptyState:
    "Suche nach einer anderen Anlagenart oder Abfallschlüssel, um Suchergebnisse anzuzeigen",
  allLoaded: "Alle Daten geladen",
  loading: "Daten laden...",
  storedData: "Ihre gespeicherten Anlagen und Kontaktpersonen",
  markedPlants: "Markierte Anlagen",
  noEntries: "Keine Einträge vorhanden",
  externalPlantSwitch: "Externe Anlagen anzeigen",
};
