import { ICheckbox } from "../components/Form/CheckboxGroup/CheckboxGroup";

export interface IDeliveryCondition {
  name: string;
  label: string;
  unit: string;
}

export const categories: Record<number, string> = {
  100: "Bauschuttaufbereitungsanlage",
  200: "Metallschlackeaufbereitungsanlage",
  300: "Deponien",
  400: "Restmüllaufbereitungsanlage",
  500: "Wertstoffzentrum",
  600: "Kunststoffaufbereitungsanlage",
  700: "Lösemittelaufbereitungsanlage",
  // 800 is missing
  900: "Kläranlage",
  1000: "Trinkwasseraufbereitungsanlage",
  1100: "Bioabfallaufbereitungsanlage",
  1200: "Untertageversatz",
  1300: "Untertagedeponie",
  1400: "Metallschrottaufbereitungsanlage",
  1500: "Sonderabfallaufbereitungsanlage",
  1600: "Sortieranlage für LVP und Wertstoffe",
  1700: "Cryogenanlage",
  1800: "Thermische Abfallbehandlungsanlage",
  1900: "Weißmineralproduktionsanlage",
  2000: "Desinfektionsanlage",
  2100: "Textilrecycling",
  2200: "Papiersortieranlage",
  2300: "Rückbauzentren für Elektro- und Elektrokleingeräte",
  2400: "Zwischenlager",
  2500: "Tierkörperbeseitigungsanlage",
  2600: "Chemikalienaufbereitungsanlage",
  2700: "Deponiegasanlage",
  2800: "Ersatzbrennstoffproduktionsanlage",
  2900: "Ersatzbrennstoffverbrennungsanlage",
  3000: "Fotochemieaufbereitungsanlage",
  3100: "Gewerbeabfallaufbereitungsanlage",
  3200: "Gipsproduktionsanlage",
  3300: "Glasaufbereitungsanlage",
  3400: "Holzaufbereitungsanlage",
  3500: "Gewerbeabfälle",
  3600: "Hausmüllverbrennungsanlage",
};

const categoriesDetail1: Record<number, string> = {
  101: "Aufbereitung Müllverbrennungsschlacke/-asche",
  102: "Baustoffrecycling",
  103: "Bauschutt- und Bodenaufbereitung",
  104: "biologische Bodenbehandlungsanlage",
  105: "Bergversatz",
  106: "Deponie",
  107: "Ersatzbaustoffe",
  108: "Flächensanierung",
  109: "Mineralstoffentsorgung",
  110: "CP-Anlage für mineralische Abfälle",
};

const categoriesDetail2: Record<number, string> = {
  201: "Aufbereitung und Verwertung von Galvanikschlämme",
  202: "Schlämme aus der Oberflächentechnik",
};

const categoriesDetail3: Record<number, string> = {
  301: "Klasse I",
  302: "Klasse II",
  303: "Klasse III",
};

const categoriesDetail4: Record<number, string> = {
  401: "mechanisch",
  402: "biologisch",
};

const categoriesDetail5: Record<number, string> = {
  501: "Sperrmüll",
};

const categoriesDetail11: Record<number, string> = {
  1101: "Biodieselanlage",
  1102: "Biogasanlage",
  1103: "Biomassekraftwerk",
  1104: "Vergärungsanlage",
  1105: "Kompostwerk",
};

const categoriesDetail12: Record<number, string> = {
  1201: "hydraulischer Versatz",
  1202: "mechanischer Versatz",
  1203: "Schüttgutversatz",
  1204: "Big-Bag-Versatz",
};

const categoriesDetail14: Record<number, string> = {
  1401: "Pressen",
  1402: "Scheren",
  1403: "Shredder",
  1404: "Fallwerk",
  1405: "Sprenggrube",
  1406: "Kabelrecycling",
  1407: "PSD-Anlage",
};

const categoriesDetail15: Record<number, string> = {
  1501: "Zwischenlager",
  1502: "Chemisch-Physikalische-Anlage",
  1503: "Sonderabfalldeponie",
  1504: "IBC-Prüfung",
  1505: "Sonderabafllverbrennungsanlage",
  1506: "Tanklager",
  1507: "Konditionieranlage",
  1508: "Behandlung",
  1509: "Sortierung",
  1510: "Altölaufbereitungsanlage",
  1511: "Schlammbehnadlungsanlage/ Dekanter",
  1512: "Emulsionsspaltanlage",
  1513: "Flotationsanlage",
  1514: "Umschlagsanlage",
  1515: "Batteriesortieranlage",
  1516: "Verdampfanlage",
  1517: "Biologische Abwassernachbehandlung",
  1518: "Quecksilberaufbereitung",
  1519: "Bodenbahndlungsanlage",
  1520: "Destillationsanlage",
  1521: "Thermische Depolymerisation",
  1522: "Spraydosenaufbereitung",
  1523: "Fettabscheider",
  1524: "Behälterreinigung",
  1525: "Biologische Abluftbehandlungsanlage",
  1526: "Nox-Anlage",
  1527: "Wirbelschichtkraftwerk",
  1528: "Hochtemperaturverbrennungsanlage/ Drehrohrtechnik",
  1529: "Rückstandsverbrennungsanlage",
  1530: "Klärschlammverbrennungsanlage",
  1531: "Separationsanlage",
};

const categoriesDetail33: Record<number, string> = {
  3601: "Flachglas",
  3602: "Hohlglas",
};

export const categoryDetails = [
  categoriesDetail1,
  categoriesDetail2,
  categoriesDetail3,
  categoriesDetail4,
  categoriesDetail5,
  {},
  {},
  {},
  {},
  {},
  categoriesDetail11,
  categoriesDetail12,
  {},
  categoriesDetail14,
  categoriesDetail15,
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  categoriesDetail33,
  {},
  {},
  {},
];

export const getCategoryDetailsIndex = (categoryNumber: number) => {
  return Math.trunc(categoryNumber / 100);
};

export const getMainCategory = (categoryNumber: number) => {
  return Math.trunc(categoryNumber / 100) * 100;
};

export const FormsOfDelivery: ICheckbox[] = [
  { key: "deliveryFormLiquid", label: "flüssig" },
  { key: "deliveryFormSolid", label: "fest" },
  { key: "deliveryFormPasty", label: "pastös" },
  { key: "deliveryFormSiloCompatible", label: "silofähig" },
  { key: "deliveryFormContainer", label: "Gebinde" },
  { key: "deliveryFormBarrels", label: "Fassware" },
  { key: "deliveryFormLooseFilling", label: "lose Schüttung" },
  { key: "deliveryFormBoxPallets", label: "Gitterboxen" },
  { key: "deliveryFormBigBags", label: "Big-Bags" },
];

export const FormsOfDeliveryTransport: ICheckbox[] = [
  { key: "deliveryFormContainerTruck", label: "Containerfahrzeuge" },
  { key: "deliveryFormSiloTruck", label: "Silofahrzeuge" },
  { key: "deliveryFormTiltSaddle", label: "Kippsattel" },
  { key: "deliveryFormTanker", label: "Tankfahrzeug" },
  { key: "deliveryFormSuctionPressureTrolley", label: "Saug-/Druckwagen" },
  { key: "deliveryFormFuelDepot", label: "Tanklager" },
  { key: "deliveryFormIBCS", label: "IBCs" },
  { key: "deliveryFormASP", label: "ASP" },
  { key: "deliveryFormASF", label: "ASF" },
];

export const DeliveryConditions: IDeliveryCondition[] = [
  {
    name: "deliveryConditionViscosity",
    label: "max. Viskosität",
    unit: "cps (bzw. mPas)",
  },
  {
    name: "deliveryConditionSludgeSubstance",
    label: "max. Schlammgehalt ",
    unit: "%",
  },
  {
    name: "deliveryConditionDissolvedSolid",
    label: "max. Anteil gelöster Feststoff",
    unit: "%",
  },
  {
    name: "deliveryConditionSuspendedSolids",
    label: "max. Anteil an Schwebstoffen",
    unit: "%",
  },
  {
    name: "deliveryConditionParticleSize",
    label: "max. Partiekelgröße",
    unit: "mm",
  },
];

export const DeliveryConditionsPhValue: IDeliveryCondition[] = [
  { name: "deliveryConditionPhValueMin", label: "min pH-Wert", unit: "0-14" },
  { name: "deliveryConditionPhValueMax", label: "max pH-Wert", unit: "0-14" },
];

export const DeliveryConditionsWithPrefix: IDeliveryCondition[] = [
  {
    name: "deliveryConditionPcbSubstance",
    label: "PCB-Gehalt",
    unit: "<> ppm",
  },
  { name: "deliveryConditionHeatingValue", label: "Heizwert", unit: "MJ/kg" },
];

export const MinMax: Record<number, string> = {
  0: "max",
  1: "min",
};
