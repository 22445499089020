/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateWasteDTO } from '../dto';
// @ts-ignore
import { WasteDTO } from '../dto';
/**
 * WasteApi - axios parameter creator
 * @export
 */
export const WasteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get one waste
         * @param {string} wasteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteControllerGetWaste: async (wasteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wasteId' is not null or undefined
            assertParamExists('wasteControllerGetWaste', 'wasteId', wasteId)
            const localVarPath = `/waste/{wasteId}`
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update one waste
         * @param {string} wasteId 
         * @param {UpdateWasteDTO} updateWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteControllerUpdateWaste: async (wasteId: string, updateWasteDTO: UpdateWasteDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wasteId' is not null or undefined
            assertParamExists('wasteControllerUpdateWaste', 'wasteId', wasteId)
            // verify required parameter 'updateWasteDTO' is not null or undefined
            assertParamExists('wasteControllerUpdateWaste', 'updateWasteDTO', updateWasteDTO)
            const localVarPath = `/waste/{wasteId}`
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWasteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteApi - functional programming interface
 * @export
 */
export const WasteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WasteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get one waste
         * @param {string} wasteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteControllerGetWaste(wasteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WasteDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wasteControllerGetWaste(wasteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update one waste
         * @param {string} wasteId 
         * @param {UpdateWasteDTO} updateWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteControllerUpdateWaste(wasteId: string, updateWasteDTO: UpdateWasteDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wasteControllerUpdateWaste(wasteId, updateWasteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WasteApi - factory interface
 * @export
 */
export const WasteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WasteApiFp(configuration)
    return {
        /**
         * 
         * @summary get one waste
         * @param {string} wasteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteControllerGetWaste(wasteId: string, options?: any): AxiosPromise<WasteDTO> {
            return localVarFp.wasteControllerGetWaste(wasteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update one waste
         * @param {string} wasteId 
         * @param {UpdateWasteDTO} updateWasteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteControllerUpdateWaste(wasteId: string, updateWasteDTO: UpdateWasteDTO, options?: any): AxiosPromise<void> {
            return localVarFp.wasteControllerUpdateWaste(wasteId, updateWasteDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for wasteControllerGetWaste operation in WasteApi.
 * @export
 * @interface WasteApiWasteControllerGetWasteRequest
 */
export interface WasteApiWasteControllerGetWasteRequest {
    /**
     * 
     * @type {string}
     * @memberof WasteApiWasteControllerGetWaste
     */
    readonly wasteId: string
}

/**
 * Request parameters for wasteControllerUpdateWaste operation in WasteApi.
 * @export
 * @interface WasteApiWasteControllerUpdateWasteRequest
 */
export interface WasteApiWasteControllerUpdateWasteRequest {
    /**
     * 
     * @type {string}
     * @memberof WasteApiWasteControllerUpdateWaste
     */
    readonly wasteId: string

    /**
     * 
     * @type {UpdateWasteDTO}
     * @memberof WasteApiWasteControllerUpdateWaste
     */
    readonly updateWasteDTO: UpdateWasteDTO
}

/**
 * WasteApi - object-oriented interface
 * @export
 * @class WasteApi
 * @extends {BaseAPI}
 */
export class WasteApi extends BaseAPI {
    /**
     * 
     * @summary get one waste
     * @param {WasteApiWasteControllerGetWasteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteApi
     */
    public wasteControllerGetWaste(requestParameters: WasteApiWasteControllerGetWasteRequest, options?: any) {
        return WasteApiFp(this.configuration).wasteControllerGetWaste(requestParameters.wasteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update one waste
     * @param {WasteApiWasteControllerUpdateWasteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteApi
     */
    public wasteControllerUpdateWaste(requestParameters: WasteApiWasteControllerUpdateWasteRequest, options?: any) {
        return WasteApiFp(this.configuration).wasteControllerUpdateWaste(requestParameters.wasteId, requestParameters.updateWasteDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
