import PrivatePlantDataEditorActions from "./store/privatePlantDataEditorActions";
import { AppStore } from "../../redux/configureStore";
import { APIs } from "../../apiProvider";
import {
  AddressDTO,
  CreatePrivatePlantDTO,
  PrivatePlantDTO,
  UpdateAddressDTO,
} from "../../api";
import { addressFormInitialValues } from "../AddressEditor";

export interface IPrivatePlantDataEditorValues {
  conditions: string;
  billingCompanyName: string;
  street: string;
  postalCode: string;
  city: string;
}

export const initialValues: IPrivatePlantDataEditorValues = {
  conditions: "",
  billingCompanyName: "",
  ...addressFormInitialValues,
};

export const mapEditorValues = (
  privateData: PrivatePlantDTO,
  address: AddressDTO
): IPrivatePlantDataEditorValues => ({
  conditions: privateData.conditions || "",
  billingCompanyName: privateData.billingCompanyName || "",
  street: address.street || "",
  postalCode: address.postalCode || "",
  city: address.city || "",
});

export const mapPrivateDataFromValues = (
  values: IPrivatePlantDataEditorValues
): CreatePrivatePlantDTO => ({
  conditions: values.conditions || "",
  billingCompanyName: values.billingCompanyName || "",
  marked: true,
});

export const mapAddressFromValues = (
  values: IPrivatePlantDataEditorValues
): UpdateAddressDTO => ({
  street: values.street,
  postalCode: values.postalCode,
  city: values.city,
});

export const showPrivatePlantDataEditorModal = async (
  store: AppStore,
  api: APIs,
  plantId?: string
) => {
  // Open editor only for creating private plant data
  if (!plantId) {
    store.dispatch(PrivatePlantDataEditorActions.setModalOpen(true));
    return;
  }
  // Open editor for editing private plant data
  const privatePlantData =
    await api.privatePlantData.privatePlantControllerGetPrivatePlantData({
      plantId,
    });
  const privatePlantDataAddress = await api.address.addressControllerGetAddress(
    {
      addressId: privatePlantData.data.billingAddressId,
    }
  );
  store.dispatch(
    PrivatePlantDataEditorActions.setModalOpen(
      true,
      privatePlantData.data,
      privatePlantDataAddress.data
    )
  );
};

export const closePrivatePlantDataEditorModal = (store: AppStore) => {
  store.dispatch(PrivatePlantDataEditorActions.setModalOpen(false));
};

export const changePlantMarking = async (
  store: AppStore,
  api: APIs,
  plantId: string,
  marked: boolean
) => {
  await api.privatePlantData.privatePlantControllerUpdatePrivatePlantData({
    plantId,
    updatePrivatePlantDTO: { marked },
  });
  store.dispatch(PrivatePlantDataEditorActions.changePlantMarking(marked));
};

export const createEmptyPrivatePlantData = async (
  store: AppStore,
  api: APIs,
  plantId: string
) => {
  await api.privatePlantData.privatePlantControllerCreatePrivatePlantData({
    plantId,
    createPrivatePlantDTO: {
      marked: true,
      conditions: "",
      billingCompanyName: "",
    },
  });
  store.dispatch(PrivatePlantDataEditorActions.changePlantMarking(true));
};

export const setPlantMarking = async (
  store: AppStore,
  marked: boolean | null
) => {
  store.dispatch(PrivatePlantDataEditorActions.changePlantMarking(marked));
};
