import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserMenu from "./UserMenu";
import DropdownMenu from "./DropdownMenu";
import { getMenuData } from "./utils";

// A style sheet
const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
  },
  list: {
    listStyleType: "none",
    paddingLeft: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  menuItem: {
    marginLeft: "40px",
    textTransform: "uppercase",
  },
  userContainer: {
    marginLeft: "0px",
  },
});

const Menu = () => {
  const styles = useStyles();
  const { t } = useTranslation("menu");
  const menuData = getMenuData(t);
  return (
    <nav className={styles.root}>
      <ul className={styles.list}>
        {menuData.map((menuItem) => {
          if (!menuItem.link) {
            return (
              <DropdownMenu
                key={menuItem.menuTitle}
                menuHeading={menuItem.menuTitle}
                menuOptions={menuItem.dropdownOptions}
              />
            );
          }
          return (
            <Link to={menuItem.link} key={menuItem.menuTitle}>
              <Button color="inherit">
                <Typography variant="subtitle1">
                  {menuItem.menuTitle}
                </Typography>
              </Button>
            </Link>
          );
        })}
        <li className={styles.userContainer}>
          <UserMenu />
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
