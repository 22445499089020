import axios from "axios";
import { useEffect, useState } from "react";

export interface Config {
  backend: string;
}

let config: Config | undefined;

export async function getConfig(): Promise<Config> {
  if (config) {
    return config;
  }

  config = await axios.get("/config.json").then((res) => res.data);

  if (!config) {
    throw new Error("could not load config.json");
  }

  return config;
}

export function useConfig(): Config | undefined {
  const [cfg, setCfg] = useState<Config>();

  useEffect(() => {
    getConfig().then(setCfg);
  }, []);

  return cfg;
}
