import React from "react";
import { Card, makeStyles, Theme, IconButton, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardTitle, { CardSubTitle } from "../CardTitle";
import {
  faPlus,
  faFileImport,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  subTitleColor?: string;
  fullWidth?: boolean;
  onAction?: () => void;
  onImport?: () => void;
  actionIcon?: IconDefinition;
  importIcon?: IconDefinition;
  className?: string;
  contentClassName?: string;
}

interface StylesProps {
  fullWidth?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>({
  card: {
    padding: "15px 0px",
    maxWidth: (props) => (props.fullWidth ? "100%" : "610px"),
    width: "100%",
    position: "relative",
  },
  content: {
    padding: 20,
  },
  addIcon: {
    position: "absolute",
    right: 20,
  },
  importIcon: {
    position: "absolute",
    right: 50,
  },
});

const InfoCard = ({
  title,
  subTitle,
  subTitleColor,
  children,
  fullWidth = false,
  onAction,
  onImport,
  actionIcon = faPlus,
  importIcon = faFileImport,
  className,
  contentClassName,
}: Props) => {
  const classes = useStyles({ fullWidth });
  return (
    <Card className={`${className} ${classes.card}`}>
      {onAction && (
        <IconButton
          className={classes.addIcon}
          aria-label="edit"
          onClick={onAction}
          size="small"
        >
          <FontAwesomeIcon icon={actionIcon} />
        </IconButton>
      )}
      {onImport && (
        <IconButton
          className={classes.importIcon}
          aria-label="import"
          onClick={onImport}
          size="small"
        >
          <FontAwesomeIcon icon={importIcon} />
        </IconButton>
      )}
      <Grid container>
        <Grid container item xs={12}>
          <CardTitle>{title}</CardTitle>
        </Grid>
        {subTitle ? (
          <>
            <Grid container item xs={12}>
              <CardSubTitle color={subTitleColor}>{subTitle}</CardSubTitle>
            </Grid>
          </>
        ) : null}
      </Grid>

      <div className={`${contentClassName} ${classes.content}`}>{children}</div>
    </Card>
  );
};

export default InfoCard;
