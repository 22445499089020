import React from "react";
import { useStore } from "react-redux";
import { useAPI } from "../../apiProvider";
import { useAddressViewerState } from "../../module/AddressViewer";
import { useContactViewerState } from "../../module/ContactViewer";
import { usePersonAndContactViewerState } from "../../module/PersonAndContactViewer";
import PlantEditor, { showPlantEditorModal } from "../../module/PlantEditor";
import PlantViewer, { usePlantViewerState } from "../../module/PlantViewer";

interface Props {
  plantId: string;
}

const PlantViewerContainer = ({ plantId }: Props) => {
  const store = useStore();
  const api = useAPI();

  const plantViewerState = usePlantViewerState(plantId);
  const addressViewerState = useAddressViewerState(
    plantViewerState.plant?.addressId
  );
  const contactViewerState = useContactViewerState(
    plantViewerState.plant?.contactId
  );
  const refreshPlant = () => {
    plantViewerState.refreshPlant();
  };

  const internalPersonAndContactState = usePersonAndContactViewerState(
    plantViewerState.plant?.internalPersonId
  );

  const externalPersonAndContactState = usePersonAndContactViewerState(
    plantViewerState.plant?.externalPersonId
  );

  const handleShowEditorModal = () => {
    showPlantEditorModal(store, api, plantId);
  };
  const handleEditorAction = () => {
    plantViewerState.refreshPlant();
    addressViewerState.refreshAddress();
    contactViewerState.refreshContact();
    internalPersonAndContactState.refreshPersonAndContact();
    externalPersonAndContactState.refreshPersonAndContact();
  };
  return (
    <>
      {plantViewerState.plant && (
        <PlantViewer
          plant={plantViewerState.plant}
          address={addressViewerState.address}
          contact={contactViewerState.contact}
          internalPersonAndContactState={internalPersonAndContactState}
          externalPersonAndContactState={externalPersonAndContactState}
          onEdit={handleShowEditorModal}
          refreshPlant={refreshPlant}
        />
      )}
      <PlantEditor onAction={handleEditorAction} />
    </>
  );
};

export default PlantViewerContainer;
