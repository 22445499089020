export interface ISelectOption {
  name: string;
  value: string;
}

export const mapEnumToOptions = (
  values: Record<number, string>
): ISelectOption[] => {
  const options = [] as ISelectOption[];
  Object.keys(values).map((key) => {
    options.push({
      name: values[Number(key)] as string,
      value: Number(key).toString(),
    });
  });

  return options.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};
