import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Input } from "../../components/Form";

const AddressEditor = () => {
  const { t } = useTranslation("addressEditor");

  return (
    <>
      <Grid item xs={6}>
        <Input name="street" label={t("street")} />
      </Grid>
      <Grid item xs={2}>
        <Input name="postalCode" label={t("postalCode")} />
      </Grid>
      <Grid item xs={4}>
        <Input name="city" label={t("city")} />
      </Grid>
    </>
  );
};

export default AddressEditor;
