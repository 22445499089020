import React, { memo } from "react";
import { useFormikContext } from "formik";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField, makeStyles } from "@material-ui/core";
import { IWaste, getWasteListOptions, getOptionLabel } from "./utils";
import { useCallback } from "react";

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  dropdownFullWidth?: boolean;
  usedKeys?: string[];
  className?: string;
}

const useStyles = makeStyles({
  popper: {
    width: "400px !important",
  },
});

const SelectWasteKey: React.FC<Props> = memo(
  ({
    name,
    label,
    disabled,
    dropdownFullWidth = false,
    usedKeys = [],
    className = "",
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formik = useFormikContext<any>();

    const handleChange = (
      evt: React.ChangeEvent<Record<string, number>>,
      newValue: IWaste | null
    ) => {
      // remove spaces
      formik.setFieldValue(name, newValue?.wasteKey.replaceAll(" ", ""));
    };
    const classes = useStyles();
    const mappedOptions: IWaste[] = getWasteListOptions();
    const filteredOptions = mappedOptions.filter(
      (o) => !usedKeys.includes(o.wasteKey.replaceAll(" ", ""))
    );

    const filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option: IWaste) =>
        option.wasteKey +
        option.wasteKey.replaceAll(" ", "") +
        option.designation,
    });

    const getValue = useCallback(() => {
      if (!formik.values[name]) return null;
      const option = mappedOptions.find(
        (o) =>
          o.wasteKey.replaceAll(" ", "") ===
          formik.values[name].replaceAll(" ", "")
      );
      if (!option) return null;
      return option;
    }, [formik, name, mappedOptions]);

    return (
      <Autocomplete
        id={name}
        className={className}
        classes={!dropdownFullWidth ? classes : undefined}
        onChange={handleChange}
        value={getValue()}
        options={filteredOptions}
        filterOptions={filterOptions}
        disabled={disabled}
        getOptionSelected={(option: IWaste, value: IWaste) =>
          option?.wasteKey === value?.wasteKey
        }
        getOptionLabel={(option) => option.wasteKey}
        renderOption={(option: IWaste) => <>{getOptionLabel(option)}</>}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            size="small"
            variant="outlined"
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
          />
        )}
      />
    );
  }
);

SelectWasteKey.displayName = "SelectWasteKey";

export default SelectWasteKey;
