import React from "react";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  onModalClose: () => void;
  onOk?: () => void;
  okText?: string;
  isLoading?: boolean;
}

const useStyles = makeStyles({
  buttonWrapper: {
    position: "relative",
    display: "initial",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const ImporterButtons = ({
  onModalClose,
  onOk,
  okText,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation("wasteImporter");
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" pt={8}>
      <Button variant="contained" onClick={onModalClose}>
        {t("abort")}
      </Button>
      {onOk && okText && (
        <div className={classes.buttonWrapper}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onOk}
          >
            {okText}
          </Button>

          {isLoading && (
            <CircularProgress size={24} className={classes.loader} />
          )}
        </div>
      )}
    </Box>
  );
};

export default ImporterButtons;
