import React, { memo } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { useFormikContext } from "formik";

interface Props {
  name: string;
}

const Input: React.FC<TextFieldProps & Props> = memo(({ name, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik = useFormikContext<any>();
  const handleChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = evt.target as HTMLTextAreaElement | HTMLInputElement;
    if (rest.type === "number") {
      formik.setFieldValue(name, parseInt(target.value));
      return;
    }
    formik.setFieldValue(name, target.value);
  };

  return (
    <TextField
      size="small"
      name={name}
      fullWidth
      variant="outlined"
      value={formik.values[name]}
      onChange={handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...rest}
    />
  );
});

Input.displayName = "Input";

export default Input;
