import React, { useMemo } from "react";
import {
  StyledComponentProps,
  Typography,
  withStyles,
} from "@material-ui/core";

interface Props extends StyledComponentProps<"root"> {
  children: React.ReactNode;
  color?: string;
}

const CardSubTitle = ({ children, color, ...rest }: Props) => {
  const Title = useMemo(() => {
    return withStyles((theme) => ({
      root: {
        padding: "4px 20px",
        display: "initial",
        backgroundColor: color || theme.palette.primary.main,
        textTransform: "uppercase",
        fontSize: "12px",
        marginTop: "10px",
        color: theme.palette.primary.contrastText,
      },
    }))(Typography);
  }, [color]);

  return (
    <Title variant="h3" {...rest}>
      {children}
    </Title>
  );
};

export default CardSubTitle;
