import React, { useState } from "react";
import CategoryGroupList from "./CategoryGroupList";
import { Box, Stepper, Step, StepLabel } from "@material-ui/core";
import { shallowEqual, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "material-ui-dropzone";
import Modal from "../../components/TransitionsModal";
import { useSelector } from "../../redux/utils";
import { closeWasteImporterModal } from "./utils";
import { WasteCategoryGroupEntity } from "../../jsonEntity/wasteCategoryGroup";
import WasteImportConfirmation from "./WasteImportConfirmation";
import ImporterButtons from "./ImporterButtons";
import { getStepsLabels } from "./utils";
import { showNotification } from "../Notification";
import { useAPI } from "../../apiProvider";

interface Props {
  plantId: string;
  onAction: () => void;
}

const WasteImporter = ({ plantId, onAction }: Props) => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [categoryGroups, setCategoryGroups] = useState<
    WasteCategoryGroupEntity[]
  >([]);

  const { t } = useTranslation("wasteImporter");
  const store = useStore();
  const api = useAPI();

  const steps = getStepsLabels(t);

  const handleCloseModal = () => {
    closeWasteImporterModal(store);
    // Wait for animation end
    setTimeout(() => {
      setActiveStep(0);
      setFile(undefined);
    }, 1000);
  };

  const isModalOpen = useSelector(
    (state) => state.modules.wasteImporter.modalOpen,
    shallowEqual
  );

  const handleChange = (files: File[]) => {
    if (!files[0]) return;
    setFile(files[0]);
    setActiveStep(1);
    setCategoryGroups([]);
  };

  const handleCategoryGroupListChange = (
    updatedCategoryGroups: WasteCategoryGroupEntity[]
  ) => {
    setCategoryGroups(updatedCategoryGroups);
  };

  const handleSaveData = async () => {
    if (!file) return;
    setLoading(true);
    const response = await api.wasteImport.plantWasteControllerImport({
      plantId,
      file,
      mapping: JSON.stringify(categoryGroups),
    });
    setLoading(false);
    onAction();
    handleCloseModal();
    showNotification(
      store,
      t("notification", { amount: response.data.ids.length })
    );
  };

  return (
    <Modal title={t("title")} open={isModalOpen} onClose={handleCloseModal}>
      <Box pt={3}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <>
          <DropzoneArea
            filesLimit={1}
            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) =>
              t("dropRejectMessage", {
                acceptedFiles: acceptedFiles.join(", "),
                maxFileSize: maxFileSize / 1000000,
              })
            }
            getFileLimitExceedMessage={(amount) =>
              t("fileLimitExceedMessage", { amount })
            }
            onChange={handleChange}
            acceptedFiles={[".pdf"]}
            dropzoneText={t("dropzoneText")}
          />
          <ImporterButtons onModalClose={handleCloseModal} />
        </>
      )}
      {activeStep === 1 && (
        <CategoryGroupList
          onSetActiveStep={setActiveStep}
          onModalClose={handleCloseModal}
          onCategoryGroupListChange={handleCategoryGroupListChange}
          categoryGroups={categoryGroups}
        />
      )}
      {activeStep === 2 && (
        <WasteImportConfirmation
          isLoading={loading}
          onConfirm={handleSaveData}
          onModalClose={handleCloseModal}
        />
      )}
    </Modal>
  );
};

export default WasteImporter;
