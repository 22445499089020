import { createReducer } from "@reduxjs/toolkit";
import NotificationActions from "./notificationActions";
import { INotificationState } from "./notificationTypes";

const initialState: INotificationState = {
  open: false,
};

export const NotificationReducer = createReducer(initialState, (builder) => {
  builder.addCase(NotificationActions.setOpen, (state, action) => {
    state.open = action.payload.open;
    state.message = action.payload.message;
  });
});
