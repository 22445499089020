import React, { memo } from "react";
import { TableCell, TableRow, IconButton, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getColumns, IWasteColumn, IWasteRow } from "./utils";
import { useTranslation } from "react-i18next";

interface Props {
  item: IWasteRow;
  onEditWaste: (wasteId: string) => void;
}

const useStyles = makeStyles({
  row: {
    "&:hover $actionButton": {
      display: "block",
    },
    height: 70,
  },
  actionButton: {
    display: "none",
  },
  actionColumn: {
    minWidth: 40,
  },
  actionIcon: {
    fontSize: 12,
  },
});

const WasteListItem = ({ item, onEditWaste }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation("wasteListViewer");
  const columns = getColumns(t);

  return (
    <TableRow
      className={classes.row}
      hover
      role="checkbox"
      tabIndex={-1}
      key={item.id}
    >
      {columns.map((column: IWasteColumn) => {
        const value = item[column.id];
        return (
          <TableCell key={column.id}>
            {value}
            {column.id === "wasteKey" && item.special ? "*" : ""}
          </TableCell>
        );
      })}
      <TableCell key="action" className={classes.actionColumn}>
        <IconButton
          className={classes.actionButton}
          onClick={() => onEditWaste(item.id)}
        >
          <FontAwesomeIcon className={classes.actionIcon} icon={faPen} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default memo(WasteListItem);
