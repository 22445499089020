import { createAction } from "@reduxjs/toolkit";

const NotificationActions = {
  setOpen: createAction(
    "MODULE_NOTIFICATION_SET_OPEN",
    (open: boolean, message?: string) => {
      return {
        payload: {
          open,
          message,
        },
      };
    }
  ),
};

export default NotificationActions;
