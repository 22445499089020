export default {
  deliveryFormLiquid: "flüssig",
  deliveryFormSolid: "fest",
  deliveryFormPasty: "pastös",
  deliveryFormSiloCompatible: "silofähig",
  deliveryFormContainer: "Gebinde",
  deliveryFormBarrels: "Fassware",
  deliveryFormLooseFilling: "lose Schüttung",
  deliveryFormBoxPallets: "Gitterboxen",
  deliveryFormBigBags: "Big-Bags",
  deliveryFormContainerTruck: "Containerfahrzeuge",
  deliveryFormSiloTruck: "Silofahrzeuge",
  deliveryFormTiltSaddle: "Kippsattel",
  deliveryFormTanker: "Tankfahrzeug",
  deliveryFormSuctionPressureTrolley: "Saug-/Druckwagen",
  deliveryFormFuelDepot: "Tanklager",
  deliveryFormIBCS: "IBCs",
  deliveryFormASP: "ASP",
  deliveryFormASF: "ASF",
};
