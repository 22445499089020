import { TFunction } from "i18next";
import { WasteCategoryEnum, WasteDTO } from "../../api";
import { getWasteListOptions } from "../../components/Form/SelectWasteKey/utils";

export interface IWasteColumn {
  id: "wasteKey" | "designation" | "notes" | "categories";
  label: string;
}

export interface IWasteRow {
  id: string;
  wasteKey: string;
  designation: string;
  notes: string;
  special: boolean;
  categories: string;
}

function translateWasteCategory(t: TFunction) {
  return (c: WasteCategoryEnum) => {
    return t(`category.${c}`);
  };
}

/**
 * Merge waste from the db with waste from the local JSON
 * @param waste waste from the DB
 */
export const createWasteRow = (
  waste: WasteDTO,
  t: TFunction
): IWasteRow | null => {
  const localWasteList = getWasteListOptions();
  const localWaste = localWasteList.find(
    (w) => w.wasteKey.replaceAll(" ", "") === waste.wasteKey.trim()
  );

  return {
    id: waste.id,
    wasteKey: localWaste?.wasteKey || waste.wasteKey.trim(),
    designation: localWaste?.designation || t("notFound"),
    special: localWaste?.special || false,
    notes: waste.notes,
    categories: waste.categories
      ? waste.categories
          .map(translateWasteCategory(t))
          .sort((a, b) => a.localeCompare(b))
          .join(", ")
      : "",
  };
};

export const getColumns = (t: TFunction): IWasteColumn[] => {
  return [
    { id: "wasteKey", label: t("wasteKey") },
    { id: "designation", label: t("wasteDesignation") },
    { id: "notes", label: t("additionalInformation") },
    { id: "categories", label: t("categories") },
  ];
};
