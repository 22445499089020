import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ImporterButtons from "./ImporterButtons";

interface Props {
  onModalClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

const WasteImportConfirmation = ({
  onModalClose,
  onConfirm,
  isLoading,
}: Props) => {
  const { t } = useTranslation("wasteImporter");

  return (
    <>
      <Box pt={5} pb={5}>
        <Typography variant="h1" align="center">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="h3" align="center">
          {t("confirmationText")}
        </Typography>
      </Box>
      <ImporterButtons
        onModalClose={onModalClose}
        onOk={onConfirm}
        okText={t("confirm")}
        isLoading={isLoading}
      />
    </>
  );
};

export default WasteImportConfirmation;
