import { TFunction } from "i18next";
import { IMenuOptions } from "./DropdownMenu";

interface IMenuData {
  menuTitle: string;
  link?: string;
  dropdownOptions: IMenuOptions[];
}

export const getMenuData = (t: TFunction): IMenuData[] => {
  return [
    {
      menuTitle: t("Meine Liste"),
      link: "/plant/my/marked",
      dropdownOptions: [],
    },
  ];
};
