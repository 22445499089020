export default {
  title: "Datensatz bearbeiten",
  generalPriceConditions: "Allgemeine Preiskonditionen",
  conditions: "Konditionen",
  billingAddress: "Rechnungsadresse",
  companyName: "Firmenname",
  marked: "Markiert",
  unmarked: "Nicht markiert",
  privateDataCreatedNotification: "Die Anlage wurde erfolgreich hinzugefügt",
};
