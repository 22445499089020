import React from "react";
import Header from "./Header";
import Routers from "./Routers";
import Notification from "../../module/Notification";

const Layout = () => {
  return (
    <>
      <Header />
      <main>
        <Routers />
        <Notification />
      </main>
    </>
  );
};

export default Layout;
