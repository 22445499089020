import wasteList from "./wasteList.json";

export interface IWaste {
  wasteKey: string;
  designation: string;
  special: boolean;
}

export const getWasteListOptions = (): IWaste[] => {
  return Object.values(wasteList);
};

export const getOptionLabel = (option: IWaste): string => {
  return `${option.wasteKey}${option.special ? "*" : ""} - ${
    option.designation
  }`;
};
