/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum WasteCategoryEnum {
    Collect = 'collect',
    Transport = 'transport',
    Store = 'store',
    Treat = 'treat',
    Process = 'process',
    Dispose = 'dispose',
    Trade = 'trade',
    Broke = 'broke'
}



