import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PlantDTO } from "../../api";
import { MinMax } from "../../enum/plant";

interface Props {
  plant: PlantDTO;
}
interface IConditionsListItem {
  label: string;
  value: number | null | undefined;
  unit: string;
  prefix?: number;
}

const useStyles = makeStyles({
  alignRight: {
    textAlign: "right",
  },
});

const createConditionsOfDeliveryWithPrefixArray = (plant: PlantDTO) => {
  const conditionsOfDeliveryWithPrefixArray: IConditionsListItem[] = [
    {
      label: "deliveryConditionPcbSubstance",
      value: plant.deliveryConditionPcbSubstance,
      unit: "<> ppm",
      prefix: plant.deliveryConditionPcbSubstancePrefix || 0,
    },
    {
      label: "deliveryConditionHeatingValue",
      value: plant.deliveryConditionHeatingValue,
      unit: "MJ/kg",
      prefix: plant.deliveryConditionHeatingValuePrefix || 0,
    },
  ];
  return conditionsOfDeliveryWithPrefixArray;
};

const createConditionsOfDeliveryArray = (plant: PlantDTO) => {
  const conditionsOfDeliveryArray: IConditionsListItem[] = [
    {
      label: "deliveryConditionViscosity",
      value: plant.deliveryConditionViscosity,
      unit: "cps (bzw. mPas)",
    },
    {
      label: "deliveryConditionSludgeSubstance",
      value: plant.deliveryConditionSludgeSubstance,
      unit: "%",
    },
    {
      label: "deliveryConditionDissolvedSolid",
      value: plant.deliveryConditionDissolvedSolid,
      unit: "%",
    },
    {
      label: "deliveryConditionSuspendedSolids",
      value: plant.deliveryConditionSuspendedSolids,
      unit: "%",
    },
    {
      label: "deliveryConditionParticleSize",
      value: plant.deliveryConditionParticleSize,
      unit: "mm",
    },
  ];
  return conditionsOfDeliveryArray;
};

interface IConditionsOfDeliveryListItem {
  conditionsListItem: IConditionsListItem;
  prefix?: string;
}

interface IConditionOfDeliveryPhValue {
  phValueMin?: number;
  phValueMax?: number;
  unit: string;
  label: string;
}

const ConditionsOfDeliveryWithPrefixListItem = ({
  conditionsListItem,
}: IConditionsOfDeliveryListItem) => {
  const classes = useStyles();
  const { t } = useTranslation("conditionsOfDeliveryViewer");
  const prefix = conditionsListItem.prefix || 0;
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.alignRight}>
        {t(conditionsListItem.label)}
      </Grid>
      <Grid item xs={2} className={classes.alignRight}>
        {conditionsListItem.value
          ? `${MinMax[prefix]} ${conditionsListItem.value.toString()}`
          : "-"}
      </Grid>
      <Grid item xs={4}>
        {conditionsListItem.unit}
      </Grid>
    </Grid>
  );
};

const ConditionOfDeliveryPhValue = ({
  phValueMin,
  phValueMax,
  unit,
  label,
}: IConditionOfDeliveryPhValue) => {
  const classes = useStyles();
  const { t } = useTranslation("conditionsOfDeliveryViewer");
  let value = "-";
  if (phValueMin && phValueMax) {
    value = `${phValueMin} - ${phValueMax}`;
  } else if (phValueMin) {
    value = `${phValueMin}`;
  } else if (phValueMax) {
    value = `${phValueMax}`;
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.alignRight}>
        {t(label)}
      </Grid>
      <Grid item xs={2} className={classes.alignRight}>
        {value}
      </Grid>
      <Grid item xs={4}>
        {unit}
      </Grid>
    </Grid>
  );
};

const ConditionsOfDeliveryListItem = ({
  conditionsListItem,
}: IConditionsOfDeliveryListItem) => {
  const classes = useStyles();
  const { t } = useTranslation("conditionsOfDeliveryViewer");
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.alignRight}>
        {t(conditionsListItem.label)}
      </Grid>
      <Grid item xs={2} className={classes.alignRight}>{`${
        conditionsListItem.value ? conditionsListItem.value.toString() : "-"
      }`}</Grid>
      <Grid item xs={4}>
        {conditionsListItem.unit}
      </Grid>
    </Grid>
  );
};

const ConditionsOfDeliveryViewer = ({ plant }: Props) => {
  const conditionsOfDeliveryArray: IConditionsListItem[] =
    createConditionsOfDeliveryArray(plant);
  const conditionsOfDeliveryWithPrefixArray: IConditionsListItem[] =
    createConditionsOfDeliveryWithPrefixArray(plant);
  return (
    <Box>
      <Grid container spacing={4}>
        {conditionsOfDeliveryArray.map((coDI) => (
          <Grid key={coDI.label} item xs={12}>
            <ConditionsOfDeliveryListItem conditionsListItem={coDI} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ConditionOfDeliveryPhValue
            phValueMin={
              plant.deliveryConditionPhValueMin
                ? plant.deliveryConditionPhValueMin
                : undefined
            }
            phValueMax={
              plant.deliveryConditionPhValueMax
                ? plant.deliveryConditionPhValueMax
                : undefined
            }
            unit={"pH"}
            label={"deliveryConditionPhValue"}
          />
        </Grid>
        {conditionsOfDeliveryWithPrefixArray.map((coDI) => (
          <Grid key={coDI.label} item xs={12}>
            <ConditionsOfDeliveryWithPrefixListItem conditionsListItem={coDI} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ConditionsOfDeliveryViewer;
