import { createReducer } from "@reduxjs/toolkit";
import WasteImporterActions from "./wasteImporterActions";
import { IWasteImporterState } from "./wasteImporterTypes";

const initialState: IWasteImporterState = {
  modalOpen: false,
};

export const WasteImporterReducer = createReducer(initialState, (builder) => {
  builder.addCase(WasteImporterActions.setModalOpen, (state, action) => {
    // if close modal reset selected plant
    if (!action.payload.modalOpen) {
      state.modalOpen = false;
      return;
    }
    // open modal
    state.modalOpen = true;
  });
});
