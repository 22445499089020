import React from "react";
import AddressViewer, {
  useAddressViewerState,
} from "../../module/AddressViewer";

interface Props {
  addressId: string;
}

const AddressViewerContainer = ({ addressId }: Props) => {
  const { address } = useAddressViewerState(addressId);
  return (
    <AddressViewer address={address} titleVariant="h5" valueVariant="body2" />
  );
};

export default AddressViewerContainer;
