import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { WasteCategoryGroupEntity } from "../../jsonEntity/wasteCategoryGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconButton, makeStyles } from "@material-ui/core";
import { colors } from "../../styles/variables";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  deleteButton: {
    color: colors.red,
    "&:hover": {
      opacity: 0.5,
    },
  },
  container: {
    borderTop: `1px solid ${grey[300]}`,
    marginLeft: 0,
  },
});

interface Props {
  index: number;
  categoryGroup: WasteCategoryGroupEntity;
  onDelete: (index: number) => void;
}

const CategoryGroupItem = ({ index, categoryGroup, onDelete }: Props) => {
  const handleDelete = async () => {
    onDelete(index);
  };

  const { t } = useTranslation("wasteListViewer");

  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={3}>
        <Typography variant="body1">{categoryGroup.fromPage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {categoryGroup.categories
            .map((c) => t(`category.${c.toLocaleLowerCase()}`))
            .join(",")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <IconButton
          aria-label="delete"
          color="secondary"
          size="small"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrash} className={classes.deleteButton} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CategoryGroupItem;
