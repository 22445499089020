import { createReducer } from "@reduxjs/toolkit";
import WasteEditorActions from "./privatePlantDataEditorActions";
import { IPrivatePlantDataState } from "./privatePlantDataEditorTypes";

const initialState: IPrivatePlantDataState = {
  modalOpen: false,
  selectedPrivateData: null,
  selectedPrivateDataAddress: null,
};

export const PrivatePlantDataReducer = createReducer(
  initialState,
  (builder) => {
    builder.addCase(WasteEditorActions.setModalOpen, (state, action) => {
      // if close modal reset selected plant
      if (!action.payload.modalOpen) {
        state.modalOpen = false;
        state.selectedPrivateData = null;
        state.selectedPrivateDataAddress = null;
        return;
      }
      // open modal
      state.modalOpen = true;
      // open modal to edit private data
      state.selectedPrivateData =
        action.payload.selectedPrivatePlantData || null;
      state.selectedPrivateDataAddress =
        action.payload.selectedPrivatePlantDataAddress || null;
    });
    builder.addCase(WasteEditorActions.changePlantMarking, (state, action) => {
      state.plantMarked = action.payload.plantMarked;
    });
  }
);
