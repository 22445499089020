import React from "react";
import {
  IconButton,
  Paper,
  Typography,
  Backdrop,
  Modal,
  Fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
  children: React.ReactNode;
  title: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    paper: {
      width: 816,
      position: "relative",
      padding: "30px 30px 40px 30px",
      maxHeight: "80vh",
      overflow: "auto",
    },
    closeButton: {
      position: "absolute",
      color: theme.palette.primary.dark,
      top: 18,
      right: 10,
      width: 48,
    },
    title: {
      paddingBottom: 0,
    },
  })
);

const TransitionsModal = ({ children, title, open, onClose }: Props) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.title} variant="h1">
              {title}
            </Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            {children}
          </Paper>
        </div>
      </Fade>
    </Modal>
  );
};

export default TransitionsModal;
