/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SimplePlantsDTO } from '../dto';
/**
 * MarkedPlantApi - axios parameter creator
 * @export
 */
export const MarkedPlantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists the marked plants of the current user
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {number} [category] 
         * @param {string} [wasteKey] 
         * @param {string} [postalCode] 
         * @param {number} [postalCodeRange] 
         * @param {boolean} [includeExternalPlants] 
         * @param {boolean} [deliveryFormLiquid] 
         * @param {boolean} [deliveryFormSolid] 
         * @param {boolean} [deliveryFormPasty] 
         * @param {boolean} [deliveryFormSiloCompatible] 
         * @param {boolean} [deliveryFormContainer] 
         * @param {boolean} [deliveryFormBarrels] 
         * @param {boolean} [deliveryFormLooseFilling] 
         * @param {boolean} [deliveryFormBoxPallets] 
         * @param {boolean} [deliveryFormBigBags] 
         * @param {boolean} [deliveryFormContainerTruck] 
         * @param {boolean} [deliveryFormSiloTruck] 
         * @param {boolean} [deliveryFormTiltSaddle] 
         * @param {boolean} [deliveryFormTanker] 
         * @param {boolean} [deliveryFormSuctionPressureTrolley] 
         * @param {boolean} [deliveryFormFuelDepot] 
         * @param {boolean} [deliveryFormIBCS] 
         * @param {boolean} [deliveryFormASP] 
         * @param {boolean} [deliveryFormASF] 
         * @param {number} [deliveryConditionViscosity] 
         * @param {number} [deliveryConditionSludgeSubstance] 
         * @param {number} [deliveryConditionDissolvedSolid] 
         * @param {number} [deliveryConditionSuspendedSolids] 
         * @param {number} [deliveryConditionParticleSize] 
         * @param {number} [deliveryConditionPhValueMin] 
         * @param {number} [deliveryConditionPhValueMax] 
         * @param {number} [deliveryConditionPcbSubstancePrefix] 
         * @param {number} [deliveryConditionPcbSubstance] 
         * @param {number} [deliveryConditionHeatingValuePrefix] 
         * @param {number} [deliveryConditionHeatingValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedPlantControllerGetMarkedPlants: async (page?: number, pageSize?: number, name?: string, category?: number, wasteKey?: string, postalCode?: string, postalCodeRange?: number, includeExternalPlants?: boolean, deliveryFormLiquid?: boolean, deliveryFormSolid?: boolean, deliveryFormPasty?: boolean, deliveryFormSiloCompatible?: boolean, deliveryFormContainer?: boolean, deliveryFormBarrels?: boolean, deliveryFormLooseFilling?: boolean, deliveryFormBoxPallets?: boolean, deliveryFormBigBags?: boolean, deliveryFormContainerTruck?: boolean, deliveryFormSiloTruck?: boolean, deliveryFormTiltSaddle?: boolean, deliveryFormTanker?: boolean, deliveryFormSuctionPressureTrolley?: boolean, deliveryFormFuelDepot?: boolean, deliveryFormIBCS?: boolean, deliveryFormASP?: boolean, deliveryFormASF?: boolean, deliveryConditionViscosity?: number, deliveryConditionSludgeSubstance?: number, deliveryConditionDissolvedSolid?: number, deliveryConditionSuspendedSolids?: number, deliveryConditionParticleSize?: number, deliveryConditionPhValueMin?: number, deliveryConditionPhValueMax?: number, deliveryConditionPcbSubstancePrefix?: number, deliveryConditionPcbSubstance?: number, deliveryConditionHeatingValuePrefix?: number, deliveryConditionHeatingValue?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/plant/my/marked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (wasteKey !== undefined) {
                localVarQueryParameter['wasteKey'] = wasteKey;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (postalCodeRange !== undefined) {
                localVarQueryParameter['postalCodeRange'] = postalCodeRange;
            }

            if (includeExternalPlants !== undefined) {
                localVarQueryParameter['includeExternalPlants'] = includeExternalPlants;
            }

            if (deliveryFormLiquid !== undefined) {
                localVarQueryParameter['deliveryFormLiquid'] = deliveryFormLiquid;
            }

            if (deliveryFormSolid !== undefined) {
                localVarQueryParameter['deliveryFormSolid'] = deliveryFormSolid;
            }

            if (deliveryFormPasty !== undefined) {
                localVarQueryParameter['deliveryFormPasty'] = deliveryFormPasty;
            }

            if (deliveryFormSiloCompatible !== undefined) {
                localVarQueryParameter['deliveryFormSiloCompatible'] = deliveryFormSiloCompatible;
            }

            if (deliveryFormContainer !== undefined) {
                localVarQueryParameter['deliveryFormContainer'] = deliveryFormContainer;
            }

            if (deliveryFormBarrels !== undefined) {
                localVarQueryParameter['deliveryFormBarrels'] = deliveryFormBarrels;
            }

            if (deliveryFormLooseFilling !== undefined) {
                localVarQueryParameter['deliveryFormLooseFilling'] = deliveryFormLooseFilling;
            }

            if (deliveryFormBoxPallets !== undefined) {
                localVarQueryParameter['deliveryFormBoxPallets'] = deliveryFormBoxPallets;
            }

            if (deliveryFormBigBags !== undefined) {
                localVarQueryParameter['deliveryFormBigBags'] = deliveryFormBigBags;
            }

            if (deliveryFormContainerTruck !== undefined) {
                localVarQueryParameter['deliveryFormContainerTruck'] = deliveryFormContainerTruck;
            }

            if (deliveryFormSiloTruck !== undefined) {
                localVarQueryParameter['deliveryFormSiloTruck'] = deliveryFormSiloTruck;
            }

            if (deliveryFormTiltSaddle !== undefined) {
                localVarQueryParameter['deliveryFormTiltSaddle'] = deliveryFormTiltSaddle;
            }

            if (deliveryFormTanker !== undefined) {
                localVarQueryParameter['deliveryFormTanker'] = deliveryFormTanker;
            }

            if (deliveryFormSuctionPressureTrolley !== undefined) {
                localVarQueryParameter['deliveryFormSuctionPressureTrolley'] = deliveryFormSuctionPressureTrolley;
            }

            if (deliveryFormFuelDepot !== undefined) {
                localVarQueryParameter['deliveryFormFuelDepot'] = deliveryFormFuelDepot;
            }

            if (deliveryFormIBCS !== undefined) {
                localVarQueryParameter['deliveryFormIBCS'] = deliveryFormIBCS;
            }

            if (deliveryFormASP !== undefined) {
                localVarQueryParameter['deliveryFormASP'] = deliveryFormASP;
            }

            if (deliveryFormASF !== undefined) {
                localVarQueryParameter['deliveryFormASF'] = deliveryFormASF;
            }

            if (deliveryConditionViscosity !== undefined) {
                localVarQueryParameter['deliveryConditionViscosity'] = deliveryConditionViscosity;
            }

            if (deliveryConditionSludgeSubstance !== undefined) {
                localVarQueryParameter['deliveryConditionSludgeSubstance'] = deliveryConditionSludgeSubstance;
            }

            if (deliveryConditionDissolvedSolid !== undefined) {
                localVarQueryParameter['deliveryConditionDissolvedSolid'] = deliveryConditionDissolvedSolid;
            }

            if (deliveryConditionSuspendedSolids !== undefined) {
                localVarQueryParameter['deliveryConditionSuspendedSolids'] = deliveryConditionSuspendedSolids;
            }

            if (deliveryConditionParticleSize !== undefined) {
                localVarQueryParameter['deliveryConditionParticleSize'] = deliveryConditionParticleSize;
            }

            if (deliveryConditionPhValueMin !== undefined) {
                localVarQueryParameter['deliveryConditionPhValueMin'] = deliveryConditionPhValueMin;
            }

            if (deliveryConditionPhValueMax !== undefined) {
                localVarQueryParameter['deliveryConditionPhValueMax'] = deliveryConditionPhValueMax;
            }

            if (deliveryConditionPcbSubstancePrefix !== undefined) {
                localVarQueryParameter['deliveryConditionPcbSubstancePrefix'] = deliveryConditionPcbSubstancePrefix;
            }

            if (deliveryConditionPcbSubstance !== undefined) {
                localVarQueryParameter['deliveryConditionPcbSubstance'] = deliveryConditionPcbSubstance;
            }

            if (deliveryConditionHeatingValuePrefix !== undefined) {
                localVarQueryParameter['deliveryConditionHeatingValuePrefix'] = deliveryConditionHeatingValuePrefix;
            }

            if (deliveryConditionHeatingValue !== undefined) {
                localVarQueryParameter['deliveryConditionHeatingValue'] = deliveryConditionHeatingValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarkedPlantApi - functional programming interface
 * @export
 */
export const MarkedPlantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarkedPlantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists the marked plants of the current user
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {number} [category] 
         * @param {string} [wasteKey] 
         * @param {string} [postalCode] 
         * @param {number} [postalCodeRange] 
         * @param {boolean} [includeExternalPlants] 
         * @param {boolean} [deliveryFormLiquid] 
         * @param {boolean} [deliveryFormSolid] 
         * @param {boolean} [deliveryFormPasty] 
         * @param {boolean} [deliveryFormSiloCompatible] 
         * @param {boolean} [deliveryFormContainer] 
         * @param {boolean} [deliveryFormBarrels] 
         * @param {boolean} [deliveryFormLooseFilling] 
         * @param {boolean} [deliveryFormBoxPallets] 
         * @param {boolean} [deliveryFormBigBags] 
         * @param {boolean} [deliveryFormContainerTruck] 
         * @param {boolean} [deliveryFormSiloTruck] 
         * @param {boolean} [deliveryFormTiltSaddle] 
         * @param {boolean} [deliveryFormTanker] 
         * @param {boolean} [deliveryFormSuctionPressureTrolley] 
         * @param {boolean} [deliveryFormFuelDepot] 
         * @param {boolean} [deliveryFormIBCS] 
         * @param {boolean} [deliveryFormASP] 
         * @param {boolean} [deliveryFormASF] 
         * @param {number} [deliveryConditionViscosity] 
         * @param {number} [deliveryConditionSludgeSubstance] 
         * @param {number} [deliveryConditionDissolvedSolid] 
         * @param {number} [deliveryConditionSuspendedSolids] 
         * @param {number} [deliveryConditionParticleSize] 
         * @param {number} [deliveryConditionPhValueMin] 
         * @param {number} [deliveryConditionPhValueMax] 
         * @param {number} [deliveryConditionPcbSubstancePrefix] 
         * @param {number} [deliveryConditionPcbSubstance] 
         * @param {number} [deliveryConditionHeatingValuePrefix] 
         * @param {number} [deliveryConditionHeatingValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markedPlantControllerGetMarkedPlants(page?: number, pageSize?: number, name?: string, category?: number, wasteKey?: string, postalCode?: string, postalCodeRange?: number, includeExternalPlants?: boolean, deliveryFormLiquid?: boolean, deliveryFormSolid?: boolean, deliveryFormPasty?: boolean, deliveryFormSiloCompatible?: boolean, deliveryFormContainer?: boolean, deliveryFormBarrels?: boolean, deliveryFormLooseFilling?: boolean, deliveryFormBoxPallets?: boolean, deliveryFormBigBags?: boolean, deliveryFormContainerTruck?: boolean, deliveryFormSiloTruck?: boolean, deliveryFormTiltSaddle?: boolean, deliveryFormTanker?: boolean, deliveryFormSuctionPressureTrolley?: boolean, deliveryFormFuelDepot?: boolean, deliveryFormIBCS?: boolean, deliveryFormASP?: boolean, deliveryFormASF?: boolean, deliveryConditionViscosity?: number, deliveryConditionSludgeSubstance?: number, deliveryConditionDissolvedSolid?: number, deliveryConditionSuspendedSolids?: number, deliveryConditionParticleSize?: number, deliveryConditionPhValueMin?: number, deliveryConditionPhValueMax?: number, deliveryConditionPcbSubstancePrefix?: number, deliveryConditionPcbSubstance?: number, deliveryConditionHeatingValuePrefix?: number, deliveryConditionHeatingValue?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePlantsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markedPlantControllerGetMarkedPlants(page, pageSize, name, category, wasteKey, postalCode, postalCodeRange, includeExternalPlants, deliveryFormLiquid, deliveryFormSolid, deliveryFormPasty, deliveryFormSiloCompatible, deliveryFormContainer, deliveryFormBarrels, deliveryFormLooseFilling, deliveryFormBoxPallets, deliveryFormBigBags, deliveryFormContainerTruck, deliveryFormSiloTruck, deliveryFormTiltSaddle, deliveryFormTanker, deliveryFormSuctionPressureTrolley, deliveryFormFuelDepot, deliveryFormIBCS, deliveryFormASP, deliveryFormASF, deliveryConditionViscosity, deliveryConditionSludgeSubstance, deliveryConditionDissolvedSolid, deliveryConditionSuspendedSolids, deliveryConditionParticleSize, deliveryConditionPhValueMin, deliveryConditionPhValueMax, deliveryConditionPcbSubstancePrefix, deliveryConditionPcbSubstance, deliveryConditionHeatingValuePrefix, deliveryConditionHeatingValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarkedPlantApi - factory interface
 * @export
 */
export const MarkedPlantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarkedPlantApiFp(configuration)
    return {
        /**
         * 
         * @summary lists the marked plants of the current user
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {number} [category] 
         * @param {string} [wasteKey] 
         * @param {string} [postalCode] 
         * @param {number} [postalCodeRange] 
         * @param {boolean} [includeExternalPlants] 
         * @param {boolean} [deliveryFormLiquid] 
         * @param {boolean} [deliveryFormSolid] 
         * @param {boolean} [deliveryFormPasty] 
         * @param {boolean} [deliveryFormSiloCompatible] 
         * @param {boolean} [deliveryFormContainer] 
         * @param {boolean} [deliveryFormBarrels] 
         * @param {boolean} [deliveryFormLooseFilling] 
         * @param {boolean} [deliveryFormBoxPallets] 
         * @param {boolean} [deliveryFormBigBags] 
         * @param {boolean} [deliveryFormContainerTruck] 
         * @param {boolean} [deliveryFormSiloTruck] 
         * @param {boolean} [deliveryFormTiltSaddle] 
         * @param {boolean} [deliveryFormTanker] 
         * @param {boolean} [deliveryFormSuctionPressureTrolley] 
         * @param {boolean} [deliveryFormFuelDepot] 
         * @param {boolean} [deliveryFormIBCS] 
         * @param {boolean} [deliveryFormASP] 
         * @param {boolean} [deliveryFormASF] 
         * @param {number} [deliveryConditionViscosity] 
         * @param {number} [deliveryConditionSludgeSubstance] 
         * @param {number} [deliveryConditionDissolvedSolid] 
         * @param {number} [deliveryConditionSuspendedSolids] 
         * @param {number} [deliveryConditionParticleSize] 
         * @param {number} [deliveryConditionPhValueMin] 
         * @param {number} [deliveryConditionPhValueMax] 
         * @param {number} [deliveryConditionPcbSubstancePrefix] 
         * @param {number} [deliveryConditionPcbSubstance] 
         * @param {number} [deliveryConditionHeatingValuePrefix] 
         * @param {number} [deliveryConditionHeatingValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedPlantControllerGetMarkedPlants(page?: number, pageSize?: number, name?: string, category?: number, wasteKey?: string, postalCode?: string, postalCodeRange?: number, includeExternalPlants?: boolean, deliveryFormLiquid?: boolean, deliveryFormSolid?: boolean, deliveryFormPasty?: boolean, deliveryFormSiloCompatible?: boolean, deliveryFormContainer?: boolean, deliveryFormBarrels?: boolean, deliveryFormLooseFilling?: boolean, deliveryFormBoxPallets?: boolean, deliveryFormBigBags?: boolean, deliveryFormContainerTruck?: boolean, deliveryFormSiloTruck?: boolean, deliveryFormTiltSaddle?: boolean, deliveryFormTanker?: boolean, deliveryFormSuctionPressureTrolley?: boolean, deliveryFormFuelDepot?: boolean, deliveryFormIBCS?: boolean, deliveryFormASP?: boolean, deliveryFormASF?: boolean, deliveryConditionViscosity?: number, deliveryConditionSludgeSubstance?: number, deliveryConditionDissolvedSolid?: number, deliveryConditionSuspendedSolids?: number, deliveryConditionParticleSize?: number, deliveryConditionPhValueMin?: number, deliveryConditionPhValueMax?: number, deliveryConditionPcbSubstancePrefix?: number, deliveryConditionPcbSubstance?: number, deliveryConditionHeatingValuePrefix?: number, deliveryConditionHeatingValue?: number, options?: any): AxiosPromise<SimplePlantsDTO> {
            return localVarFp.markedPlantControllerGetMarkedPlants(page, pageSize, name, category, wasteKey, postalCode, postalCodeRange, includeExternalPlants, deliveryFormLiquid, deliveryFormSolid, deliveryFormPasty, deliveryFormSiloCompatible, deliveryFormContainer, deliveryFormBarrels, deliveryFormLooseFilling, deliveryFormBoxPallets, deliveryFormBigBags, deliveryFormContainerTruck, deliveryFormSiloTruck, deliveryFormTiltSaddle, deliveryFormTanker, deliveryFormSuctionPressureTrolley, deliveryFormFuelDepot, deliveryFormIBCS, deliveryFormASP, deliveryFormASF, deliveryConditionViscosity, deliveryConditionSludgeSubstance, deliveryConditionDissolvedSolid, deliveryConditionSuspendedSolids, deliveryConditionParticleSize, deliveryConditionPhValueMin, deliveryConditionPhValueMax, deliveryConditionPcbSubstancePrefix, deliveryConditionPcbSubstance, deliveryConditionHeatingValuePrefix, deliveryConditionHeatingValue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for markedPlantControllerGetMarkedPlants operation in MarkedPlantApi.
 * @export
 * @interface MarkedPlantApiMarkedPlantControllerGetMarkedPlantsRequest
 */
export interface MarkedPlantApiMarkedPlantControllerGetMarkedPlantsRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly category?: number

    /**
     * 
     * @type {string}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly wasteKey?: string

    /**
     * 
     * @type {string}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly postalCode?: string

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly postalCodeRange?: number

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly includeExternalPlants?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormLiquid?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormSolid?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormPasty?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormSiloCompatible?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormContainer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormBarrels?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormLooseFilling?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormBoxPallets?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormBigBags?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormContainerTruck?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormSiloTruck?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormTiltSaddle?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormTanker?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormSuctionPressureTrolley?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormFuelDepot?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormIBCS?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormASP?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryFormASF?: boolean

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionViscosity?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionSludgeSubstance?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionDissolvedSolid?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionSuspendedSolids?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionParticleSize?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionPhValueMin?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionPhValueMax?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionPcbSubstancePrefix?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionPcbSubstance?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionHeatingValuePrefix?: number

    /**
     * 
     * @type {number}
     * @memberof MarkedPlantApiMarkedPlantControllerGetMarkedPlants
     */
    readonly deliveryConditionHeatingValue?: number
}

/**
 * MarkedPlantApi - object-oriented interface
 * @export
 * @class MarkedPlantApi
 * @extends {BaseAPI}
 */
export class MarkedPlantApi extends BaseAPI {
    /**
     * 
     * @summary lists the marked plants of the current user
     * @param {MarkedPlantApiMarkedPlantControllerGetMarkedPlantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkedPlantApi
     */
    public markedPlantControllerGetMarkedPlants(requestParameters: MarkedPlantApiMarkedPlantControllerGetMarkedPlantsRequest = {}, options?: any) {
        return MarkedPlantApiFp(this.configuration).markedPlantControllerGetMarkedPlants(requestParameters.page, requestParameters.pageSize, requestParameters.name, requestParameters.category, requestParameters.wasteKey, requestParameters.postalCode, requestParameters.postalCodeRange, requestParameters.includeExternalPlants, requestParameters.deliveryFormLiquid, requestParameters.deliveryFormSolid, requestParameters.deliveryFormPasty, requestParameters.deliveryFormSiloCompatible, requestParameters.deliveryFormContainer, requestParameters.deliveryFormBarrels, requestParameters.deliveryFormLooseFilling, requestParameters.deliveryFormBoxPallets, requestParameters.deliveryFormBigBags, requestParameters.deliveryFormContainerTruck, requestParameters.deliveryFormSiloTruck, requestParameters.deliveryFormTiltSaddle, requestParameters.deliveryFormTanker, requestParameters.deliveryFormSuctionPressureTrolley, requestParameters.deliveryFormFuelDepot, requestParameters.deliveryFormIBCS, requestParameters.deliveryFormASP, requestParameters.deliveryFormASF, requestParameters.deliveryConditionViscosity, requestParameters.deliveryConditionSludgeSubstance, requestParameters.deliveryConditionDissolvedSolid, requestParameters.deliveryConditionSuspendedSolids, requestParameters.deliveryConditionParticleSize, requestParameters.deliveryConditionPhValueMin, requestParameters.deliveryConditionPhValueMax, requestParameters.deliveryConditionPcbSubstancePrefix, requestParameters.deliveryConditionPcbSubstance, requestParameters.deliveryConditionHeatingValuePrefix, requestParameters.deliveryConditionHeatingValue, options).then((request) => request(this.axios, this.basePath));
    }
}
