import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ContactDTO, PersonDTO } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faMobile,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  person: PersonDTO;
  contact: ContactDTO;
}

interface ContactInfoProps {
  text: string;
  icon: IconProp;
}

const ContactInfoWithIcon = ({ text, icon }: ContactInfoProps) => {
  if (text && text !== "") {
    return (
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <FontAwesomeIcon
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
              icon={icon}
            />
          </Grid>
          <Grid item>{text}</Grid>
        </Grid>
      </Grid>
    );
  }
  return <></>;
};

const PersonAndContactViewer = ({ person, contact }: Props) => {
  return (
    <Box m={1}>
      <Grid container spacing={1} direction="column">
        <ContactInfoWithIcon
          text={`${person.firstName} ${person.lastName}`.trim()}
          icon={faUser}
        />
        <ContactInfoWithIcon text={person.position} icon={faBriefcase} />
        <ContactInfoWithIcon text={contact.email} icon={faEnvelope} />
        <ContactInfoWithIcon text={contact.phone} icon={faPhone} />
        <ContactInfoWithIcon text={contact.cellPhone} icon={faMobile} />
      </Grid>
    </Box>
  );
};

export default PersonAndContactViewer;
