/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreatePlantDTO } from "../dto";
// @ts-ignore
import { PlantDTO } from "../dto";
// @ts-ignore
import { SimplePlantsDTO } from "../dto";
// @ts-ignore
import { UpdatePlantDTO } from "../dto";
/**
 * PlantApi - axios parameter creator
 * @export
 */
export const PlantApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary creates a new Plant
     * @param {CreatePlantDTO} createPlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerCreatePlant: async (
      createPlantDTO: CreatePlantDTO,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPlantDTO' is not null or undefined
      assertParamExists(
        "plantControllerCreatePlant",
        "createPlantDTO",
        createPlantDTO
      );
      const localVarPath = `/plant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "oauth2",
        [],
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPlantDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get one plant
     * @param {string} plantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerGetPlant: async (
      plantId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'plantId' is not null or undefined
      assertParamExists("plantControllerGetPlant", "plantId", plantId);
      const localVarPath = `/plant/{plantId}`.replace(
        `{${"plantId"}}`,
        encodeURIComponent(String(plantId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "oauth2",
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary lists the plants
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [name]
     * @param {number} [category]
     * @param {string} [wasteKey]
     * @param {string} [postalCode]
     * @param {number} [postalCodeRange]
     * @param {boolean} [includeExternalPlants]
     * @param {boolean} [deliveryFormLiquid]
     * @param {boolean} [deliveryFormSolid]
     * @param {boolean} [deliveryFormPasty]
     * @param {boolean} [deliveryFormSiloCompatible]
     * @param {boolean} [deliveryFormContainer]
     * @param {boolean} [deliveryFormBarrels]
     * @param {boolean} [deliveryFormLooseFilling]
     * @param {boolean} [deliveryFormBoxPallets]
     * @param {boolean} [deliveryFormBigBags]
     * @param {boolean} [deliveryFormContainerTruck]
     * @param {boolean} [deliveryFormSiloTruck]
     * @param {boolean} [deliveryFormTiltSaddle]
     * @param {boolean} [deliveryFormTanker]
     * @param {boolean} [deliveryFormSuctionPressureTrolley]
     * @param {boolean} [deliveryFormFuelDepot]
     * @param {boolean} [deliveryFormIBCS]
     * @param {boolean} [deliveryFormASP]
     * @param {boolean} [deliveryFormASF]
     * @param {number} [deliveryConditionViscosity]
     * @param {number} [deliveryConditionSludgeSubstance]
     * @param {number} [deliveryConditionDissolvedSolid]
     * @param {number} [deliveryConditionSuspendedSolids]
     * @param {number} [deliveryConditionParticleSize]
     * @param {number} [deliveryConditionPhValueMin]
     * @param {number} [deliveryConditionPhValueMax]
     * @param {number} [deliveryConditionPcbSubstancePrefix]
     * @param {number} [deliveryConditionPcbSubstance]
     * @param {number} [deliveryConditionHeatingValuePrefix]
     * @param {number} [deliveryConditionHeatingValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerGetPlants: async (
      page?: number,
      pageSize?: number,
      name?: string,
      category?: number,
      wasteKey?: string,
      postalCode?: string,
      postalCodeRange?: number,
      includeExternalPlants?: boolean,
      deliveryFormLiquid?: boolean,
      deliveryFormSolid?: boolean,
      deliveryFormPasty?: boolean,
      deliveryFormSiloCompatible?: boolean,
      deliveryFormContainer?: boolean,
      deliveryFormBarrels?: boolean,
      deliveryFormLooseFilling?: boolean,
      deliveryFormBoxPallets?: boolean,
      deliveryFormBigBags?: boolean,
      deliveryFormContainerTruck?: boolean,
      deliveryFormSiloTruck?: boolean,
      deliveryFormTiltSaddle?: boolean,
      deliveryFormTanker?: boolean,
      deliveryFormSuctionPressureTrolley?: boolean,
      deliveryFormFuelDepot?: boolean,
      deliveryFormIBCS?: boolean,
      deliveryFormASP?: boolean,
      deliveryFormASF?: boolean,
      deliveryConditionViscosity?: number,
      deliveryConditionSludgeSubstance?: number,
      deliveryConditionDissolvedSolid?: number,
      deliveryConditionSuspendedSolids?: number,
      deliveryConditionParticleSize?: number,
      deliveryConditionPhValueMin?: number,
      deliveryConditionPhValueMax?: number,
      deliveryConditionPcbSubstancePrefix?: number,
      deliveryConditionPcbSubstance?: number,
      deliveryConditionHeatingValuePrefix?: number,
      deliveryConditionHeatingValue?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/plant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "oauth2",
        [],
        configuration
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (category !== undefined) {
        localVarQueryParameter["category"] = category;
      }

      if (wasteKey !== undefined) {
        localVarQueryParameter["wasteKey"] = wasteKey;
      }

      if (postalCode !== undefined) {
        localVarQueryParameter["postalCode"] = postalCode;
      }

      if (postalCodeRange !== undefined) {
        localVarQueryParameter["postalCodeRange"] = postalCodeRange;
      }

      if (includeExternalPlants !== undefined) {
        localVarQueryParameter["includeExternalPlants"] = includeExternalPlants;
      }

      if (deliveryFormLiquid !== undefined) {
        localVarQueryParameter["deliveryFormLiquid"] = deliveryFormLiquid;
      }

      if (deliveryFormSolid !== undefined) {
        localVarQueryParameter["deliveryFormSolid"] = deliveryFormSolid;
      }

      if (deliveryFormPasty !== undefined) {
        localVarQueryParameter["deliveryFormPasty"] = deliveryFormPasty;
      }

      if (deliveryFormSiloCompatible !== undefined) {
        localVarQueryParameter["deliveryFormSiloCompatible"] =
          deliveryFormSiloCompatible;
      }

      if (deliveryFormContainer !== undefined) {
        localVarQueryParameter["deliveryFormContainer"] = deliveryFormContainer;
      }

      if (deliveryFormBarrels !== undefined) {
        localVarQueryParameter["deliveryFormBarrels"] = deliveryFormBarrels;
      }

      if (deliveryFormLooseFilling !== undefined) {
        localVarQueryParameter["deliveryFormLooseFilling"] =
          deliveryFormLooseFilling;
      }

      if (deliveryFormBoxPallets !== undefined) {
        localVarQueryParameter["deliveryFormBoxPallets"] =
          deliveryFormBoxPallets;
      }

      if (deliveryFormBigBags !== undefined) {
        localVarQueryParameter["deliveryFormBigBags"] = deliveryFormBigBags;
      }

      if (deliveryFormContainerTruck !== undefined) {
        localVarQueryParameter["deliveryFormContainerTruck"] =
          deliveryFormContainerTruck;
      }

      if (deliveryFormSiloTruck !== undefined) {
        localVarQueryParameter["deliveryFormSiloTruck"] = deliveryFormSiloTruck;
      }

      if (deliveryFormTiltSaddle !== undefined) {
        localVarQueryParameter["deliveryFormTiltSaddle"] =
          deliveryFormTiltSaddle;
      }

      if (deliveryFormTanker !== undefined) {
        localVarQueryParameter["deliveryFormTanker"] = deliveryFormTanker;
      }

      if (deliveryFormSuctionPressureTrolley !== undefined) {
        localVarQueryParameter["deliveryFormSuctionPressureTrolley"] =
          deliveryFormSuctionPressureTrolley;
      }

      if (deliveryFormFuelDepot !== undefined) {
        localVarQueryParameter["deliveryFormFuelDepot"] = deliveryFormFuelDepot;
      }

      if (deliveryFormIBCS !== undefined) {
        localVarQueryParameter["deliveryFormIBCS"] = deliveryFormIBCS;
      }

      if (deliveryFormASP !== undefined) {
        localVarQueryParameter["deliveryFormASP"] = deliveryFormASP;
      }

      if (deliveryFormASF !== undefined) {
        localVarQueryParameter["deliveryFormASF"] = deliveryFormASF;
      }

      if (deliveryConditionViscosity !== undefined) {
        localVarQueryParameter["deliveryConditionViscosity"] =
          deliveryConditionViscosity;
      }

      if (deliveryConditionSludgeSubstance !== undefined) {
        localVarQueryParameter["deliveryConditionSludgeSubstance"] =
          deliveryConditionSludgeSubstance;
      }

      if (deliveryConditionDissolvedSolid !== undefined) {
        localVarQueryParameter["deliveryConditionDissolvedSolid"] =
          deliveryConditionDissolvedSolid;
      }

      if (deliveryConditionSuspendedSolids !== undefined) {
        localVarQueryParameter["deliveryConditionSuspendedSolids"] =
          deliveryConditionSuspendedSolids;
      }

      if (deliveryConditionParticleSize !== undefined) {
        localVarQueryParameter["deliveryConditionParticleSize"] =
          deliveryConditionParticleSize;
      }

      if (deliveryConditionPhValueMin !== undefined) {
        localVarQueryParameter["deliveryConditionPhValueMin"] =
          deliveryConditionPhValueMin;
      }

      if (deliveryConditionPhValueMax !== undefined) {
        localVarQueryParameter["deliveryConditionPhValueMax"] =
          deliveryConditionPhValueMax;
      }

      if (deliveryConditionPcbSubstancePrefix !== undefined) {
        localVarQueryParameter["deliveryConditionPcbSubstancePrefix"] =
          deliveryConditionPcbSubstancePrefix;
      }

      if (deliveryConditionPcbSubstance !== undefined) {
        localVarQueryParameter["deliveryConditionPcbSubstance"] =
          deliveryConditionPcbSubstance;
      }

      if (deliveryConditionHeatingValuePrefix !== undefined) {
        localVarQueryParameter["deliveryConditionHeatingValuePrefix"] =
          deliveryConditionHeatingValuePrefix;
      }

      if (deliveryConditionHeatingValue !== undefined) {
        localVarQueryParameter["deliveryConditionHeatingValue"] =
          deliveryConditionHeatingValue;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary update one plant
     * @param {string} plantId
     * @param {UpdatePlantDTO} updatePlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerUpdatePlant: async (
      plantId: string,
      updatePlantDTO: UpdatePlantDTO,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'plantId' is not null or undefined
      assertParamExists("plantControllerUpdatePlant", "plantId", plantId);
      // verify required parameter 'updatePlantDTO' is not null or undefined
      assertParamExists(
        "plantControllerUpdatePlant",
        "updatePlantDTO",
        updatePlantDTO
      );
      const localVarPath = `/plant/{plantId}`.replace(
        `{${"plantId"}}`,
        encodeURIComponent(String(plantId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "oauth2",
        [],
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePlantDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlantApi - functional programming interface
 * @export
 */
export const PlantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary creates a new Plant
     * @param {CreatePlantDTO} createPlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plantControllerCreatePlant(
      createPlantDTO: CreatePlantDTO,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plantControllerCreatePlant(
          createPlantDTO,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary get one plant
     * @param {string} plantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plantControllerGetPlant(
      plantId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plantControllerGetPlant(
          plantId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary lists the plants
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [name]
     * @param {number} [category]
     * @param {string} [wasteKey]
     * @param {string} [postalCode]
     * @param {number} [postalCodeRange]
     * @param {boolean} [includeExternalPlants]
     * @param {boolean} [deliveryFormLiquid]
     * @param {boolean} [deliveryFormSolid]
     * @param {boolean} [deliveryFormPasty]
     * @param {boolean} [deliveryFormSiloCompatible]
     * @param {boolean} [deliveryFormContainer]
     * @param {boolean} [deliveryFormBarrels]
     * @param {boolean} [deliveryFormLooseFilling]
     * @param {boolean} [deliveryFormBoxPallets]
     * @param {boolean} [deliveryFormBigBags]
     * @param {boolean} [deliveryFormContainerTruck]
     * @param {boolean} [deliveryFormSiloTruck]
     * @param {boolean} [deliveryFormTiltSaddle]
     * @param {boolean} [deliveryFormTanker]
     * @param {boolean} [deliveryFormSuctionPressureTrolley]
     * @param {boolean} [deliveryFormFuelDepot]
     * @param {boolean} [deliveryFormIBCS]
     * @param {boolean} [deliveryFormASP]
     * @param {boolean} [deliveryFormASF]
     * @param {number} [deliveryConditionViscosity]
     * @param {number} [deliveryConditionSludgeSubstance]
     * @param {number} [deliveryConditionDissolvedSolid]
     * @param {number} [deliveryConditionSuspendedSolids]
     * @param {number} [deliveryConditionParticleSize]
     * @param {number} [deliveryConditionPhValueMin]
     * @param {number} [deliveryConditionPhValueMax]
     * @param {number} [deliveryConditionPcbSubstancePrefix]
     * @param {number} [deliveryConditionPcbSubstance]
     * @param {number} [deliveryConditionHeatingValuePrefix]
     * @param {number} [deliveryConditionHeatingValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plantControllerGetPlants(
      page?: number,
      pageSize?: number,
      name?: string,
      category?: number,
      wasteKey?: string,
      postalCode?: string,
      postalCodeRange?: number,
      includeExternalPlants?: boolean,
      deliveryFormLiquid?: boolean,
      deliveryFormSolid?: boolean,
      deliveryFormPasty?: boolean,
      deliveryFormSiloCompatible?: boolean,
      deliveryFormContainer?: boolean,
      deliveryFormBarrels?: boolean,
      deliveryFormLooseFilling?: boolean,
      deliveryFormBoxPallets?: boolean,
      deliveryFormBigBags?: boolean,
      deliveryFormContainerTruck?: boolean,
      deliveryFormSiloTruck?: boolean,
      deliveryFormTiltSaddle?: boolean,
      deliveryFormTanker?: boolean,
      deliveryFormSuctionPressureTrolley?: boolean,
      deliveryFormFuelDepot?: boolean,
      deliveryFormIBCS?: boolean,
      deliveryFormASP?: boolean,
      deliveryFormASF?: boolean,
      deliveryConditionViscosity?: number,
      deliveryConditionSludgeSubstance?: number,
      deliveryConditionDissolvedSolid?: number,
      deliveryConditionSuspendedSolids?: number,
      deliveryConditionParticleSize?: number,
      deliveryConditionPhValueMin?: number,
      deliveryConditionPhValueMax?: number,
      deliveryConditionPcbSubstancePrefix?: number,
      deliveryConditionPcbSubstance?: number,
      deliveryConditionHeatingValuePrefix?: number,
      deliveryConditionHeatingValue?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SimplePlantsDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plantControllerGetPlants(
          page,
          pageSize,
          name,
          category,
          wasteKey,
          postalCode,
          postalCodeRange,
          includeExternalPlants,
          deliveryFormLiquid,
          deliveryFormSolid,
          deliveryFormPasty,
          deliveryFormSiloCompatible,
          deliveryFormContainer,
          deliveryFormBarrels,
          deliveryFormLooseFilling,
          deliveryFormBoxPallets,
          deliveryFormBigBags,
          deliveryFormContainerTruck,
          deliveryFormSiloTruck,
          deliveryFormTiltSaddle,
          deliveryFormTanker,
          deliveryFormSuctionPressureTrolley,
          deliveryFormFuelDepot,
          deliveryFormIBCS,
          deliveryFormASP,
          deliveryFormASF,
          deliveryConditionViscosity,
          deliveryConditionSludgeSubstance,
          deliveryConditionDissolvedSolid,
          deliveryConditionSuspendedSolids,
          deliveryConditionParticleSize,
          deliveryConditionPhValueMin,
          deliveryConditionPhValueMax,
          deliveryConditionPcbSubstancePrefix,
          deliveryConditionPcbSubstance,
          deliveryConditionHeatingValuePrefix,
          deliveryConditionHeatingValue,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary update one plant
     * @param {string} plantId
     * @param {UpdatePlantDTO} updatePlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plantControllerUpdatePlant(
      plantId: string,
      updatePlantDTO: UpdatePlantDTO,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plantControllerUpdatePlant(
          plantId,
          updatePlantDTO,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PlantApi - factory interface
 * @export
 */
export const PlantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PlantApiFp(configuration);
  return {
    /**
     *
     * @summary creates a new Plant
     * @param {CreatePlantDTO} createPlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerCreatePlant(
      createPlantDTO: CreatePlantDTO,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .plantControllerCreatePlant(createPlantDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary get one plant
     * @param {string} plantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerGetPlant(
      plantId: string,
      options?: any
    ): AxiosPromise<PlantDTO> {
      return localVarFp
        .plantControllerGetPlant(plantId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary lists the plants
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [name]
     * @param {number} [category]
     * @param {string} [wasteKey]
     * @param {string} [postalCode]
     * @param {number} [postalCodeRange]
     * @param {boolean} [includeExternalPlants]
     * @param {boolean} [deliveryFormLiquid]
     * @param {boolean} [deliveryFormSolid]
     * @param {boolean} [deliveryFormPasty]
     * @param {boolean} [deliveryFormSiloCompatible]
     * @param {boolean} [deliveryFormContainer]
     * @param {boolean} [deliveryFormBarrels]
     * @param {boolean} [deliveryFormLooseFilling]
     * @param {boolean} [deliveryFormBoxPallets]
     * @param {boolean} [deliveryFormBigBags]
     * @param {boolean} [deliveryFormContainerTruck]
     * @param {boolean} [deliveryFormSiloTruck]
     * @param {boolean} [deliveryFormTiltSaddle]
     * @param {boolean} [deliveryFormTanker]
     * @param {boolean} [deliveryFormSuctionPressureTrolley]
     * @param {boolean} [deliveryFormFuelDepot]
     * @param {boolean} [deliveryFormIBCS]
     * @param {boolean} [deliveryFormASP]
     * @param {boolean} [deliveryFormASF]
     * @param {number} [deliveryConditionViscosity]
     * @param {number} [deliveryConditionSludgeSubstance]
     * @param {number} [deliveryConditionDissolvedSolid]
     * @param {number} [deliveryConditionSuspendedSolids]
     * @param {number} [deliveryConditionParticleSize]
     * @param {number} [deliveryConditionPhValueMin]
     * @param {number} [deliveryConditionPhValueMax]
     * @param {number} [deliveryConditionPcbSubstancePrefix]
     * @param {number} [deliveryConditionPcbSubstance]
     * @param {number} [deliveryConditionHeatingValuePrefix]
     * @param {number} [deliveryConditionHeatingValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerGetPlants(
      page?: number,
      pageSize?: number,
      name?: string,
      category?: number,
      wasteKey?: string,
      postalCode?: string,
      postalCodeRange?: number,
      includeExternalPlants?: boolean,
      deliveryFormLiquid?: boolean,
      deliveryFormSolid?: boolean,
      deliveryFormPasty?: boolean,
      deliveryFormSiloCompatible?: boolean,
      deliveryFormContainer?: boolean,
      deliveryFormBarrels?: boolean,
      deliveryFormLooseFilling?: boolean,
      deliveryFormBoxPallets?: boolean,
      deliveryFormBigBags?: boolean,
      deliveryFormContainerTruck?: boolean,
      deliveryFormSiloTruck?: boolean,
      deliveryFormTiltSaddle?: boolean,
      deliveryFormTanker?: boolean,
      deliveryFormSuctionPressureTrolley?: boolean,
      deliveryFormFuelDepot?: boolean,
      deliveryFormIBCS?: boolean,
      deliveryFormASP?: boolean,
      deliveryFormASF?: boolean,
      deliveryConditionViscosity?: number,
      deliveryConditionSludgeSubstance?: number,
      deliveryConditionDissolvedSolid?: number,
      deliveryConditionSuspendedSolids?: number,
      deliveryConditionParticleSize?: number,
      deliveryConditionPhValueMin?: number,
      deliveryConditionPhValueMax?: number,
      deliveryConditionPcbSubstancePrefix?: number,
      deliveryConditionPcbSubstance?: number,
      deliveryConditionHeatingValuePrefix?: number,
      deliveryConditionHeatingValue?: number,
      options?: any
    ): AxiosPromise<SimplePlantsDTO> {
      return localVarFp
        .plantControllerGetPlants(
          page,
          pageSize,
          name,
          category,
          wasteKey,
          postalCode,
          postalCodeRange,
          includeExternalPlants,
          deliveryFormLiquid,
          deliveryFormSolid,
          deliveryFormPasty,
          deliveryFormSiloCompatible,
          deliveryFormContainer,
          deliveryFormBarrels,
          deliveryFormLooseFilling,
          deliveryFormBoxPallets,
          deliveryFormBigBags,
          deliveryFormContainerTruck,
          deliveryFormSiloTruck,
          deliveryFormTiltSaddle,
          deliveryFormTanker,
          deliveryFormSuctionPressureTrolley,
          deliveryFormFuelDepot,
          deliveryFormIBCS,
          deliveryFormASP,
          deliveryFormASF,
          deliveryConditionViscosity,
          deliveryConditionSludgeSubstance,
          deliveryConditionDissolvedSolid,
          deliveryConditionSuspendedSolids,
          deliveryConditionParticleSize,
          deliveryConditionPhValueMin,
          deliveryConditionPhValueMax,
          deliveryConditionPcbSubstancePrefix,
          deliveryConditionPcbSubstance,
          deliveryConditionHeatingValuePrefix,
          deliveryConditionHeatingValue,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary update one plant
     * @param {string} plantId
     * @param {UpdatePlantDTO} updatePlantDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plantControllerUpdatePlant(
      plantId: string,
      updatePlantDTO: UpdatePlantDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .plantControllerUpdatePlant(plantId, updatePlantDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for plantControllerCreatePlant operation in PlantApi.
 * @export
 * @interface PlantApiPlantControllerCreatePlantRequest
 */
export interface PlantApiPlantControllerCreatePlantRequest {
  /**
   *
   * @type {CreatePlantDTO}
   * @memberof PlantApiPlantControllerCreatePlant
   */
  readonly createPlantDTO: CreatePlantDTO;
}

/**
 * Request parameters for plantControllerGetPlant operation in PlantApi.
 * @export
 * @interface PlantApiPlantControllerGetPlantRequest
 */
export interface PlantApiPlantControllerGetPlantRequest {
  /**
   *
   * @type {string}
   * @memberof PlantApiPlantControllerGetPlant
   */
  readonly plantId: string;
}

/**
 * Request parameters for plantControllerGetPlants operation in PlantApi.
 * @export
 * @interface PlantApiPlantControllerGetPlantsRequest
 */
export interface PlantApiPlantControllerGetPlantsRequest {
  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly category?: number;

  /**
   *
   * @type {string}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly wasteKey?: string;

  /**
   *
   * @type {string}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly postalCode?: string;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly postalCodeRange?: number;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly includeExternalPlants?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormLiquid?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormSolid?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormPasty?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormSiloCompatible?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormContainer?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormBarrels?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormLooseFilling?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormBoxPallets?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormBigBags?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormContainerTruck?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormSiloTruck?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormTiltSaddle?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormTanker?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormSuctionPressureTrolley?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormFuelDepot?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormIBCS?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormASP?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryFormASF?: boolean;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionViscosity?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionSludgeSubstance?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionDissolvedSolid?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionSuspendedSolids?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionParticleSize?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionPhValueMin?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionPhValueMax?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionPcbSubstancePrefix?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionPcbSubstance?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionHeatingValuePrefix?: number;

  /**
   *
   * @type {number}
   * @memberof PlantApiPlantControllerGetPlants
   */
  readonly deliveryConditionHeatingValue?: number;
}

/**
 * Request parameters for plantControllerUpdatePlant operation in PlantApi.
 * @export
 * @interface PlantApiPlantControllerUpdatePlantRequest
 */
export interface PlantApiPlantControllerUpdatePlantRequest {
  /**
   *
   * @type {string}
   * @memberof PlantApiPlantControllerUpdatePlant
   */
  readonly plantId: string;

  /**
   *
   * @type {UpdatePlantDTO}
   * @memberof PlantApiPlantControllerUpdatePlant
   */
  readonly updatePlantDTO: UpdatePlantDTO;
}

/**
 * PlantApi - object-oriented interface
 * @export
 * @class PlantApi
 * @extends {BaseAPI}
 */
export class PlantApi extends BaseAPI {
  /**
   *
   * @summary creates a new Plant
   * @param {PlantApiPlantControllerCreatePlantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlantApi
   */
  public plantControllerCreatePlant(
    requestParameters: PlantApiPlantControllerCreatePlantRequest,
    options?: any
  ) {
    return PlantApiFp(this.configuration)
      .plantControllerCreatePlant(requestParameters.createPlantDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get one plant
   * @param {PlantApiPlantControllerGetPlantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlantApi
   */
  public plantControllerGetPlant(
    requestParameters: PlantApiPlantControllerGetPlantRequest,
    options?: any
  ) {
    return PlantApiFp(this.configuration)
      .plantControllerGetPlant(requestParameters.plantId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary lists the plants
   * @param {PlantApiPlantControllerGetPlantsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlantApi
   */
  public plantControllerGetPlants(
    requestParameters: PlantApiPlantControllerGetPlantsRequest = {},
    options?: any
  ) {
    return PlantApiFp(this.configuration)
      .plantControllerGetPlants(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.name,
        requestParameters.category,
        requestParameters.wasteKey,
        requestParameters.postalCode,
        requestParameters.postalCodeRange,
        requestParameters.includeExternalPlants,
        requestParameters.deliveryFormLiquid,
        requestParameters.deliveryFormSolid,
        requestParameters.deliveryFormPasty,
        requestParameters.deliveryFormSiloCompatible,
        requestParameters.deliveryFormContainer,
        requestParameters.deliveryFormBarrels,
        requestParameters.deliveryFormLooseFilling,
        requestParameters.deliveryFormBoxPallets,
        requestParameters.deliveryFormBigBags,
        requestParameters.deliveryFormContainerTruck,
        requestParameters.deliveryFormSiloTruck,
        requestParameters.deliveryFormTiltSaddle,
        requestParameters.deliveryFormTanker,
        requestParameters.deliveryFormSuctionPressureTrolley,
        requestParameters.deliveryFormFuelDepot,
        requestParameters.deliveryFormIBCS,
        requestParameters.deliveryFormASP,
        requestParameters.deliveryFormASF,
        requestParameters.deliveryConditionViscosity,
        requestParameters.deliveryConditionSludgeSubstance,
        requestParameters.deliveryConditionDissolvedSolid,
        requestParameters.deliveryConditionSuspendedSolids,
        requestParameters.deliveryConditionParticleSize,
        requestParameters.deliveryConditionPhValueMin,
        requestParameters.deliveryConditionPhValueMax,
        requestParameters.deliveryConditionPcbSubstancePrefix,
        requestParameters.deliveryConditionPcbSubstance,
        requestParameters.deliveryConditionHeatingValuePrefix,
        requestParameters.deliveryConditionHeatingValue,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary update one plant
   * @param {PlantApiPlantControllerUpdatePlantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlantApi
   */
  public plantControllerUpdatePlant(
    requestParameters: PlantApiPlantControllerUpdatePlantRequest,
    options?: any
  ) {
    return PlantApiFp(this.configuration)
      .plantControllerUpdatePlant(
        requestParameters.plantId,
        requestParameters.updatePlantDTO,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
