import React, { useEffect, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";
import { Form, FormikHelpers, FormikProvider, useFormik } from "formik";
import { shallowEqual, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AddressDTO,
  PrivatePlantDTO,
  UpdateAddressDTO,
  UpdatePrivatePlantDTO,
} from "../../api";
import { useAPI } from "../../apiProvider";
import { Input, FormButtons } from "../../components/Form";
import CardTitle from "../../components/CardTitle";
import Modal from "../../components/TransitionsModal";
import { useSelector } from "../../redux/utils";
import {
  closePrivatePlantDataEditorModal,
  initialValues,
  IPrivatePlantDataEditorValues,
  mapEditorValues,
  mapPrivateDataFromValues,
  mapAddressFromValues,
} from "./utils";
import {
  getChangedValues,
  getExistingValues,
} from "../../components/Form/utils";
import AddressEditor, { useAddressEditorForm } from "../AddressEditor";
import { showNotification } from "../Notification";

const FORMIK_INITIAL_VALUES_LOADED = "initialValuesLoaded";

interface Props {
  onAction?: () => void;
  plantId: string;
}

const PlantPrivateDataEditor = ({ onAction, plantId }: Props) => {
  const { t } = useTranslation("privatePlantDataEditor");
  const api = useAPI();
  const store = useStore();

  const privatePlantData: PrivatePlantDTO | null = useSelector(
    (state) => state.modules.privatePlantData.selectedPrivateData,
    shallowEqual
  );

  const privatePlantDataAddress: AddressDTO | null | undefined = useSelector(
    (state) => state.modules.privatePlantData.selectedPrivateDataAddress,
    shallowEqual
  );

  const isModalOpen = useSelector(
    (state) => state.modules.privatePlantData.modalOpen,
    shallowEqual
  );

  const handleUpdatePrivatePlantData = async (
    values: IPrivatePlantDataEditorValues,
    initialPrivateDataValues: PrivatePlantDTO,
    initialAddressValues: AddressDTO
  ) => {
    const privatePlantDataChanged = getChangedValues<
      IPrivatePlantDataEditorValues,
      PrivatePlantDTO,
      UpdatePrivatePlantDTO
    >(values, initialPrivateDataValues);

    // Always mark plant when editing
    await api.privatePlantData.privatePlantControllerUpdatePrivatePlantData({
      plantId,
      updatePrivatePlantDTO: { ...privatePlantDataChanged, marked: true },
    });

    const addressData = getChangedValues<
      IPrivatePlantDataEditorValues,
      AddressDTO,
      UpdateAddressDTO
    >(values, initialAddressValues);
    if (addressData) {
      await api.privatePlantAddressData.privatePlantAddressControllerUpdatePrivatePlantBillingAddress(
        {
          plantId,
          updateAddressDTO: addressData,
        }
      );
    }
    if (!privatePlantData?.marked) {
      showNotification(store, t("privateDataCreatedNotification"));
    }
  };

  const handleCreatePrivatePlantData = async (
    values: IPrivatePlantDataEditorValues
  ) => {
    await api.privatePlantData.privatePlantControllerCreatePrivatePlantData({
      plantId,
      createPrivatePlantDTO: mapPrivateDataFromValues(values),
    });
    const addressData = mapAddressFromValues(values);
    const changedAddressData = getExistingValues<UpdateAddressDTO>(addressData);
    if (changedAddressData) {
      await api.privatePlantAddressData.privatePlantAddressControllerUpdatePrivatePlantBillingAddress(
        {
          plantId,
          updateAddressDTO: changedAddressData,
        }
      );
    }
    showNotification(store, t("privateDataCreatedNotification"));
  };

  const handleSubmit = async (
    values: IPrivatePlantDataEditorValues,
    formikHelpers: FormikHelpers<IPrivatePlantDataEditorValues>
  ) => {
    formikHelpers.setSubmitting(true);
    if (privatePlantData && privatePlantDataAddress) {
      await handleUpdatePrivatePlantData(
        values,
        privatePlantData,
        privatePlantDataAddress
      );
    } else {
      await handleCreatePrivatePlantData(values);
    }
    formikHelpers.setSubmitting(false);
    handleCloseModal();
    if (onAction) onAction();
  };

  const addressEditorForm = useAddressEditorForm();

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: addressEditorForm.addressValidationSchema,
  });

  const handleCloseModal = () => closePrivatePlantDataEditorModal(store);

  const handleSetEditorValues = useCallback(() => {
    if (
      !privatePlantData ||
      !privatePlantDataAddress ||
      formik.dirty ||
      formik.status === FORMIK_INITIAL_VALUES_LOADED
    )
      return;
    formik.setValues({
      ...mapEditorValues(privatePlantData, privatePlantDataAddress),
    });
    formik.setStatus(FORMIK_INITIAL_VALUES_LOADED);
  }, [privatePlantData, privatePlantDataAddress, formik]);

  useEffect(() => {
    if (isModalOpen === false && formik.dirty) {
      formik.resetForm();
    }
  }, [isModalOpen, formik]);

  useEffect(() => {
    handleSetEditorValues();
  }, [handleSetEditorValues]);

  return (
    <Modal title={t("title")} open={isModalOpen} onClose={handleCloseModal}>
      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pb={2} pt={2}>
                <CardTitle>{t("generalPriceConditions")}</CardTitle>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Input
                name="conditions"
                label={t("conditions")}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} pt={2}>
                <CardTitle>{t("billingAddress")}</CardTitle>
              </Box>
            </Grid>
            <AddressEditor />
          </Grid>
          <FormButtons onCancel={handleCloseModal} />
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default PlantPrivateDataEditor;
