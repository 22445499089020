import React from "react";
import { Typography } from "@material-ui/core";
import PersonAndContactViewer from "../../module/PersonAndContactViewer";
import { Skeleton } from "@material-ui/lab";
import { IPersonAndContactViewerState } from "../../module/PersonAndContactViewer/hooks";

interface Props {
  state: IPersonAndContactViewerState;
  title: string;
}

const PersonAndContactContainer = ({ state, title }: Props) => {
  if (state.person === null || state.contact === null) {
    return (
      <>
        <Typography variant="h4">{title}</Typography>
        <Skeleton animation="wave" height={22} />
      </>
    );
  }
  return (
    <>
      <Typography variant="h4">{title}</Typography>
      {state.person !== undefined && state.contact !== undefined && (
        <PersonAndContactViewer person={state.person} contact={state.contact} />
      )}
    </>
  );
};

export default PersonAndContactContainer;
