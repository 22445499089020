import PlantEditorActions from "./store/plantEditorActions";
import { AppStore } from "../../redux/configureStore";
import {
  AddressDTO,
  ContactDTO,
  CreatePersonDTO,
  CreatePlantDTO,
  PersonDTO,
  PlantDTO,
  UpdateAddressDTO,
  UpdateContactDTO,
  UpdatePersonDTO,
} from "../../api";
import {
  getChangedValues,
  getExistingValues,
} from "../../components/Form/utils";
import { APIs } from "../../apiProvider";
import { addressFormInitialValues } from "../AddressEditor";
import { getMainCategory } from "../../enum/plant";

export type PlantEditorValues = {
  name: string;
  excluded: string;
  notes: string;
  street?: string;
  postalCode?: string;
  city?: string;
  phone?: string;
  email?: string;
  internalFirstName: string;
  internalLastName: string;
  internalPosition: string;
  internalCellPhone: string;
  internalPhone: string;
  internalEmail: string;
  externalFirstName: string;
  externalLastName: string;
  externalPosition: string;
  externalCellPhone: string;
  externalPhone: string;
  externalEmail: string;
  category: number;
  categoryDetail: number;
  isExternalPlant: boolean;
  formsOfDelivery: string[];
  formsOfDeliveryTransport: string[];
  deliveryConditionViscosity: string;
  deliveryConditionSludgeSubstance: string;
  deliveryConditionDissolvedSolid: string;
  deliveryConditionSuspendedSolids: string;
  deliveryConditionParticleSize: string;
  deliveryConditionPhValueMin: string;
  deliveryConditionPhValueMax: string;
  deliveryConditionPcbSubstancePrefix: string;
  deliveryConditionPcbSubstance: string;
  deliveryConditionHeatingValuePrefix: string;
  deliveryConditionHeatingValue: string;
};

const mapFormsOfDeliveryType = (plant: PlantDTO | null) => {
  const formsOfDeliveryTypeStringA: string[] = [];
  if (plant) {
    plant.deliveryFormLiquid &&
      formsOfDeliveryTypeStringA.push("deliveryFormLiquid");
    plant.deliveryFormSolid &&
      formsOfDeliveryTypeStringA.push("deliveryFormSolid");
    plant.deliveryFormPasty &&
      formsOfDeliveryTypeStringA.push("deliveryFormPasty");
    plant.deliveryFormSiloCompatible &&
      formsOfDeliveryTypeStringA.push("deliveryFormSiloCompatible");
    plant.deliveryFormContainer &&
      formsOfDeliveryTypeStringA.push("deliveryFormContainer");
    plant.deliveryFormBarrels &&
      formsOfDeliveryTypeStringA.push("deliveryFormBarrels");
    plant.deliveryFormLooseFilling &&
      formsOfDeliveryTypeStringA.push("deliveryFormLooseFilling");
    plant.deliveryFormBoxPallets &&
      formsOfDeliveryTypeStringA.push("deliveryFormBoxPallets");
    plant.deliveryFormBigBags &&
      formsOfDeliveryTypeStringA.push("deliveryFormBigBags");
  }
  return formsOfDeliveryTypeStringA;
};

const mapFormsOfDeliveryTransport = (plant: PlantDTO | null) => {
  const formsOfDeliveryTransportStringA: string[] = [];
  if (plant) {
    plant.deliveryFormContainerTruck &&
      formsOfDeliveryTransportStringA.push("deliveryFormContainerTruck");
    plant.deliveryFormSiloTruck &&
      formsOfDeliveryTransportStringA.push("deliveryFormSiloTruck");
    plant.deliveryFormTiltSaddle &&
      formsOfDeliveryTransportStringA.push("deliveryFormTiltSaddle");
    plant.deliveryFormTanker &&
      formsOfDeliveryTransportStringA.push("deliveryFormTanker");
    plant.deliveryFormSuctionPressureTrolley &&
      formsOfDeliveryTransportStringA.push(
        "deliveryFormSuctionPressureTrolley"
      );
    plant.deliveryFormFuelDepot &&
      formsOfDeliveryTransportStringA.push("deliveryFormFuelDepot");
    plant.deliveryFormIBCS &&
      formsOfDeliveryTransportStringA.push("deliveryFormIBCS");
    plant.deliveryFormASP &&
      formsOfDeliveryTransportStringA.push("deliveryFormASP");
    plant.deliveryFormASF &&
      formsOfDeliveryTransportStringA.push("deliveryFormASF");
  }
  return formsOfDeliveryTransportStringA;
};

export const mapEditorValues = (
  plant: PlantDTO | null,
  address: AddressDTO | null,
  contact: ContactDTO | null,
  internalPerson: PersonDTO | null,
  externalPerson: PersonDTO | null,
  internalPersonContact: ContactDTO | null,
  externalPersonContact: ContactDTO | null
): PlantEditorValues => {
  return {
    name: plant?.name || "",
    excluded: plant?.excluded || "",
    notes: plant?.notes || "",
    street: address?.street || "",
    postalCode: address?.postalCode || "",
    city: address?.city || "",
    phone: contact?.phone || "",
    email: contact?.email || "",
    internalFirstName: internalPerson?.firstName || "",
    internalLastName: internalPerson?.lastName || "",
    internalPosition: internalPerson?.position || "",
    internalCellPhone: internalPersonContact?.cellPhone || "",
    internalPhone: internalPersonContact?.phone || "",
    internalEmail: internalPersonContact?.email || "",
    externalFirstName: externalPerson?.firstName || "",
    externalLastName: externalPerson?.lastName || "",
    externalPosition: externalPerson?.position || "",
    externalCellPhone: externalPersonContact?.cellPhone || "",
    externalPhone: externalPersonContact?.phone || "",
    externalEmail: externalPersonContact?.email || "",
    category: plant?.category ? getMainCategory(plant.category) : 0,
    categoryDetail:
      plant?.category && plant?.category % 100 !== 0 ? plant?.category : 0,
    isExternalPlant: plant?.isExternalPlant || false,
    formsOfDelivery: mapFormsOfDeliveryType(plant),
    formsOfDeliveryTransport: mapFormsOfDeliveryTransport(plant),
    deliveryConditionViscosity:
      plant?.deliveryConditionViscosity?.toString() || "",
    deliveryConditionSludgeSubstance:
      plant?.deliveryConditionSludgeSubstance?.toString() || "",
    deliveryConditionDissolvedSolid:
      plant?.deliveryConditionDissolvedSolid?.toString() || "",
    deliveryConditionSuspendedSolids:
      plant?.deliveryConditionSuspendedSolids?.toString() || "",
    deliveryConditionParticleSize:
      plant?.deliveryConditionParticleSize?.toString() || "",
    deliveryConditionPhValueMin:
      plant?.deliveryConditionPhValueMin?.toString() || "",
    deliveryConditionPhValueMax:
      plant?.deliveryConditionPhValueMax?.toString() || "",
    deliveryConditionPcbSubstancePrefix:
      plant?.deliveryConditionPcbSubstancePrefix?.toString() || "0",
    deliveryConditionPcbSubstance:
      plant?.deliveryConditionPcbSubstance?.toString() || "",
    deliveryConditionHeatingValuePrefix:
      plant?.deliveryConditionHeatingValuePrefix?.toString() || "0",
    deliveryConditionHeatingValue:
      plant?.deliveryConditionHeatingValue?.toString() || "",
  };
};

export const mapPlantFromValues = (
  plantEditorValues: PlantEditorValues,
  internalPersonId: string | null,
  externalPersonId: string | null
): CreatePlantDTO => {
  return {
    name: plantEditorValues.name,
    excluded: plantEditorValues.excluded,
    notes: plantEditorValues.notes,
    internalPersonId,
    externalPersonId,
    isExternalPlant: plantEditorValues.isExternalPlant,

    // test
    ...mapDeliveryFormsValuesToBoolean(plantEditorValues.formsOfDelivery),
    ...mapDeliveryFormsValuesToBoolean(
      plantEditorValues.formsOfDeliveryTransport
    ),
    category: plantEditorValues.category
      ? mapCategory(
          Number(plantEditorValues.category),
          Number(plantEditorValues.categoryDetail)
        )
      : 0,
    deliveryConditionViscosity: plantEditorValues.deliveryConditionViscosity
      ? Number(plantEditorValues.deliveryConditionViscosity)
      : null,
    deliveryConditionSludgeSubstance:
      plantEditorValues.deliveryConditionSludgeSubstance
        ? Number(plantEditorValues.deliveryConditionSludgeSubstance)
        : null,
    deliveryConditionDissolvedSolid:
      plantEditorValues.deliveryConditionDissolvedSolid
        ? Number(plantEditorValues.deliveryConditionDissolvedSolid)
        : null,
    deliveryConditionSuspendedSolids:
      plantEditorValues.deliveryConditionSuspendedSolids
        ? Number(plantEditorValues.deliveryConditionSuspendedSolids)
        : null,
    deliveryConditionParticleSize:
      plantEditorValues.deliveryConditionParticleSize
        ? Number(plantEditorValues.deliveryConditionParticleSize)
        : null,
    deliveryConditionPhValueMin: plantEditorValues.deliveryConditionPhValueMin
      ? Number(plantEditorValues.deliveryConditionPhValueMin)
      : null,
    deliveryConditionPhValueMax: plantEditorValues.deliveryConditionPhValueMax
      ? Number(plantEditorValues.deliveryConditionPhValueMax)
      : null,
    deliveryConditionPcbSubstancePrefix:
      plantEditorValues.deliveryConditionPcbSubstancePrefix
        ? Number(plantEditorValues.deliveryConditionPcbSubstancePrefix)
        : null,
    deliveryConditionPcbSubstance:
      plantEditorValues.deliveryConditionPcbSubstance
        ? Number(plantEditorValues.deliveryConditionPcbSubstance)
        : null,
    deliveryConditionHeatingValuePrefix:
      plantEditorValues.deliveryConditionHeatingValuePrefix
        ? Number(plantEditorValues.deliveryConditionHeatingValuePrefix)
        : null,
    deliveryConditionHeatingValue:
      plantEditorValues.deliveryConditionHeatingValue
        ? Number(plantEditorValues.deliveryConditionHeatingValue)
        : null,
  };
};

export const mapCategory = (category: number, categoryDetail: number) => {
  if (categoryDetail) {
    return categoryDetail;
  }
  return category;
};

export const mapDeliveryFormsValuesToBoolean = (formStringArray: string[]) => {
  const formsOfDelivery: { [k: string]: boolean } = {};
  formStringArray.forEach((formString) => {
    formsOfDelivery[formString] = true;
  });
  return formsOfDelivery;
};

export const editorInitialValues: PlantEditorValues = {
  name: "",
  notes: "",
  excluded: "",
  phone: "",
  email: "",
  internalFirstName: "",
  internalLastName: "",
  internalPosition: "",
  internalCellPhone: "",
  internalPhone: "",
  internalEmail: "",
  externalFirstName: "",
  externalLastName: "",
  externalPosition: "",
  externalCellPhone: "",
  externalPhone: "",
  externalEmail: "",
  category: 0,
  categoryDetail: 0,
  isExternalPlant: false,
  formsOfDelivery: [],
  formsOfDeliveryTransport: [],
  deliveryConditionViscosity: "",
  deliveryConditionSludgeSubstance: "",
  deliveryConditionDissolvedSolid: "",
  deliveryConditionSuspendedSolids: "",
  deliveryConditionParticleSize: "",
  deliveryConditionPhValueMin: "",
  deliveryConditionPhValueMax: "",
  deliveryConditionPcbSubstancePrefix: "0",
  deliveryConditionPcbSubstance: "",
  deliveryConditionHeatingValuePrefix: "0",
  deliveryConditionHeatingValue: "",
  ...addressFormInitialValues,
};

export const mapAddressFromValues = (
  plantEditorValues: PlantEditorValues
): UpdateAddressDTO => {
  return {
    street: plantEditorValues.street,
    postalCode: plantEditorValues.postalCode,
    city: plantEditorValues.city,
  };
};

export const mapContactFromValues = (
  plantEditorValues: PlantEditorValues
): UpdateContactDTO => {
  return {
    phone: plantEditorValues.phone,
    email: plantEditorValues.email,
  };
};

const mapInternalPersonFromValues = (
  plantEditorValues: PlantEditorValues
): CreatePersonDTO => {
  return {
    firstName: plantEditorValues.internalFirstName,
    lastName: plantEditorValues.internalLastName,
    position: plantEditorValues.internalPosition,
  };
};

const mapExternalPersonFromValues = (
  plantEditorValues: PlantEditorValues
): CreatePersonDTO => {
  return {
    firstName: plantEditorValues.externalFirstName,
    lastName: plantEditorValues.externalLastName,
    position: plantEditorValues.externalPosition,
  };
};

const mapInternalPersonContactFromValues = (
  plantEditorValues: PlantEditorValues
): UpdateContactDTO => {
  return {
    cellPhone: plantEditorValues.internalCellPhone,
    phone: plantEditorValues.internalPhone,
    email: plantEditorValues.internalEmail,
  };
};

const mapExternalPersonContactFromValues = (
  plantEditorValues: PlantEditorValues
): UpdateContactDTO => {
  return {
    cellPhone: plantEditorValues.externalCellPhone,
    phone: plantEditorValues.externalPhone,
    email: plantEditorValues.externalEmail,
  };
};

export const showPlantEditorModal = async (
  store: AppStore,
  api?: APIs,
  plantId?: string
) => {
  // Open editor only for creating plant
  if (!plantId || !api) {
    store.dispatch(PlantEditorActions.setModalOpen(true));
    return;
  }

  // Open editor for editing plant
  const plant = await api.plant.plantControllerGetPlant({ plantId });
  const [address, contact, externalPerson, internalPerson] = await Promise.all([
    api.address.addressControllerGetAddress({
      addressId: plant.data.addressId,
    }),
    api.contact.contactControllerGetContact({
      contactId: plant.data.contactId,
    }),
    plant.data.externalPersonId
      ? api.person.personControllerGetPerson({
          personId: plant.data.externalPersonId,
        })
      : Promise.resolve(undefined),
    plant.data.internalPersonId
      ? api.person.personControllerGetPerson({
          personId: plant.data.internalPersonId,
        })
      : Promise.resolve(undefined),
  ]);

  const [externalPersonContact, internalPersonContact] = await Promise.all([
    externalPerson
      ? api.contact.contactControllerGetContact({
          contactId: externalPerson.data.contactId,
        })
      : Promise.resolve(undefined),
    internalPerson
      ? api.contact.contactControllerGetContact({
          contactId: internalPerson.data.contactId,
        })
      : Promise.resolve(undefined),
  ]);

  store.dispatch(
    PlantEditorActions.setModalOpen(
      true,
      plant.data,
      address.data,
      contact.data,
      internalPerson?.data,
      externalPerson?.data,
      internalPersonContact?.data,
      externalPersonContact?.data
    )
  );
};

export const closePlantEditorModal = (store: AppStore) => {
  store.dispatch(PlantEditorActions.setModalOpen(false));
};

export const createInternalPersonAndItsContact = async (
  values: PlantEditorValues,
  api: APIs
): Promise<string | null> => {
  const internalPersonData = mapInternalPersonFromValues(values);
  const internalPersonContactData = mapInternalPersonContactFromValues(values);
  const changedInternalPersonContactData = getExistingValues<UpdateContactDTO>(
    internalPersonContactData
  );

  // Create person in case if person or person contact data filled
  if (
    !internalPersonData.firstName &&
    !internalPersonData.lastName &&
    !changedInternalPersonContactData
  ) {
    return null;
  }
  const internalPerson = await api.person.personControllerCreatePerson({
    createPersonDTO: internalPersonData,
  });

  // Update person contact in case if contact data filled
  if (!changedInternalPersonContactData) {
    return internalPerson.data;
  }
  await api.personContact.personContactControllerUpdatePersonContact({
    personId: internalPerson.data,
    updateContactDTO: changedInternalPersonContactData,
  });
  return internalPerson.data;
};

export const createExternalPersonAndItsContact = async (
  values: PlantEditorValues,
  api: APIs
): Promise<string | null> => {
  const externalPersonData = mapExternalPersonFromValues(values);
  const externalPersonContactData = mapExternalPersonContactFromValues(values);
  const changedExternalPersonContactData = getExistingValues<UpdateContactDTO>(
    externalPersonContactData
  );

  // Create person in case if person or person contact data filled
  if (
    !externalPersonData.firstName &&
    !externalPersonData.lastName &&
    !changedExternalPersonContactData
  )
    return null;
  const externalPerson = await api.person.personControllerCreatePerson({
    createPersonDTO: externalPersonData,
  });

  // Update person contact in case if contact data filled
  if (!changedExternalPersonContactData) {
    return externalPerson.data;
  }
  await api.personContact.personContactControllerUpdatePersonContact({
    personId: externalPerson.data,
    updateContactDTO: changedExternalPersonContactData,
  });
  return externalPerson.data;
};

export const updateInternalPersonAnItsContact = async (
  values: PlantEditorValues,
  initialPersonData: PersonDTO,
  initialPersonContactData: ContactDTO,
  api: APIs
) => {
  const changedPersonData = getChangedValues<
    CreatePersonDTO,
    PersonDTO,
    UpdatePersonDTO
  >(mapInternalPersonFromValues(values), initialPersonData);
  if (changedPersonData) {
    await api.person.personControllerUpdatePerson({
      personId: initialPersonData.id,
      updatePersonDTO: changedPersonData,
    });
  }
  const changedPersonContactData = getChangedValues<
    UpdateContactDTO,
    ContactDTO,
    UpdateContactDTO
  >(mapInternalPersonContactFromValues(values), initialPersonContactData);
  if (changedPersonContactData) {
    await api.personContact.personContactControllerUpdatePersonContact({
      personId: initialPersonData.id,
      updateContactDTO: changedPersonContactData,
    });
  }
};

export const updateExternalPersonAnItsContact = async (
  values: PlantEditorValues,
  initialPersonData: PersonDTO,
  initialPersonContactData: ContactDTO,
  api: APIs
) => {
  const changedPersonData = getChangedValues<
    CreatePersonDTO,
    PersonDTO,
    UpdatePersonDTO
  >(mapExternalPersonFromValues(values), initialPersonData);
  if (changedPersonData) {
    await api.person.personControllerUpdatePerson({
      personId: initialPersonData.id,
      updatePersonDTO: changedPersonData,
    });
  }
  const changedPersonContactData = getChangedValues<
    UpdateContactDTO,
    ContactDTO,
    UpdateContactDTO
  >(mapExternalPersonContactFromValues(values), initialPersonContactData);
  if (changedPersonContactData) {
    await api.personContact.personContactControllerUpdatePersonContact({
      personId: initialPersonData.id,
      updateContactDTO: changedPersonContactData,
    });
  }
};
