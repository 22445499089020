import WasteImporterActions from "./store/wasteImporterActions";
import { AppStore } from "../../redux/configureStore";
import { TFunction } from "i18next";

export const showWasteImporterModal = async (store: AppStore) => {
  store.dispatch(WasteImporterActions.setModalOpen(true));
};

export const closeWasteImporterModal = (store: AppStore) => {
  store.dispatch(WasteImporterActions.setModalOpen(false));
};

export const getStepsLabels = (t: TFunction) => [
  t("stepLabel1"),
  t("stepLabel2"),
  t("stepLabel3"),
];
