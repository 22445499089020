import React from "react";
import { Grid } from "@material-ui/core";
import { Input } from "../../components/Form";
import { useTranslation } from "react-i18next";

const ContactFormFields = () => {
  const { t } = useTranslation("plantEditor");
  return (
    <>
      <Grid item xs={6}>
        <Input name="phone" label={t("phone")} />
      </Grid>
      <Grid item xs={6}>
        <Input name="email" label={t("email")} />
      </Grid>
    </>
  );
};

export default ContactFormFields;
