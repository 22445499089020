import React from "react";
import { Route } from "react-router-dom";
import Plant from "../../page/Plant";
import Home from "../../page/Home";
import MarkedPlants from "../../page/MarkedPlants";

const Routes = () => (
  <>
    <Route exact path="/" component={Home} />
    <Route exact path="/plant/:plantId" component={Plant} />
    <Route exact path="/plant/my/marked" component={MarkedPlants} />
  </>
);

export default Routes;
