import Keycloak from "keycloak-js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ConfigApi, Configuration } from "../../api";
import { useConfig } from "../../config";

export function useAuthentication() {
  const config = useConfig();
  const [keycloak, setKeycloak] = useState<Keycloak.KeycloakInstance>();
  const [isLoadingKeycloak, setIsLoadingKeycloak] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState<string>();

  // Get only the config-api without authentication configured, as it doesn't need that.
  const api = useMemo(() => {
    if (!config) {
      return;
    }
    const configApi = new ConfigApi(
      new Configuration({
        basePath: config?.backend,
      })
    );
    return configApi;
  }, [config]);

  useEffect(() => {
    if (isLoggedIn || !api) {
      return;
    }

    // First load the config from the backend.
    const configPromise = api
      .configControllerGetConfig()
      .then((config) => config.data)
      .catch((err) => {
        console.error(err);
      });

    // Based on the config instantiate keycloak if needed.
    configPromise.then((config) => {
      if (!config) {
        return;
      }

      if (config.keycloak) {
        setKeycloak(
          Keycloak({
            url: config.keycloak.host,
            realm: config.keycloak.realm,
            clientId: config.keycloak.clientId,
          })
        );
      } else {
        setIsLoggedIn(true);
      }
    });
  }, [api, isLoggedIn]);

  useEffect(() => {
    // Init keycloak. To be able to use await this is wrapped into a function.
    const initKeycloak = async () => {
      // Else wait for both to be set.
      if (keycloak === undefined) {
        return;
      }

      keycloak
        .init({ onLoad: "login-required" })
        .then((auth) => {
          if (!auth) {
            window.location.reload();
            return;
          }

          setIsLoadingKeycloak(false);
          // Enable Token refresh.
          setInterval(() => {
            keycloak
              .updateToken(70)
              .then((refreshed) => {
                if (refreshed) {
                  console.debug("Token refreshed");
                  setToken(keycloak.token);
                } else {
                  console.debug("Token not refreshed");
                  if (keycloak.tokenParsed?.exp && keycloak.timeSkew) {
                    console.warn(
                      "Token not refreshed, valid for " +
                        Math.round(
                          keycloak.tokenParsed.exp +
                            keycloak.timeSkew -
                            new Date().getTime() / 1000
                        ) +
                        " seconds"
                    );
                  }
                }
              })
              .catch((err) => {
                console.error("Failed to refresh token", err);
                setIsLoggedIn(false);
              });
          }, 60000);

          keycloak.loadUserProfile().then(() => {
            setIsLoggedIn(true);
            setToken(keycloak.token);
          });
        })
        .catch((err) => {
          console.error("Authentication failed", err);
          setIsLoggedIn(false);
        });
    };

    initKeycloak().then();
  }, [keycloak]);

  return {
    isLoggedIn: isLoggedIn && keycloak && token,
    isLoading: isLoadingKeycloak,
    token,
    profile: keycloak?.profile,
    logout: useCallback(() => {
      keycloak?.logout().then(() => {
        setIsLoadingKeycloak(true);
        setIsLoggedIn(false);
        setToken(undefined);
      });
    }, [keycloak]),
  };
}

export interface AuthData {
  logout: () => void;
  profile: Keycloak.KeycloakProfile;
}

export const AuthContext = React.createContext<AuthData>({
  logout: () => undefined,
  profile: {},
});

export const useLogout = () => {
  return useContext(AuthContext).logout;
};

export const useProfile = () => {
  return useContext(AuthContext).profile;
};
