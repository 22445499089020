import React from "react";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Input, SelectMultiple } from "../../components/Form";
import { Button, Grid } from "@material-ui/core";
import { WasteCategoryGroupEntity } from "../../jsonEntity/wasteCategoryGroup";
import { useTranslation } from "react-i18next";
import { WasteCategoryEnum } from "../../api";

interface Props {
  onAddWasteCategoryGroup: (
    wasteCategoryGroup: WasteCategoryGroupEntity
  ) => void;
  minPage?: number;
}

const CategoryGroupForm = ({ onAddWasteCategoryGroup, minPage = 0 }: Props) => {
  const { t } = useTranslation("wasteImporter");
  const { t: wasteListTranslation } = useTranslation("wasteListViewer");

  const validationSchema = yup.object({
    fromPage: yup
      .number()
      .required(t("fromPageValidationError"))
      .min(minPage + 1, t("fromPageValidationError")),
  });

  const handleSubmit = async (values: WasteCategoryGroupEntity) => {
    onAddWasteCategoryGroup(values);
    formik.resetForm();
  };

  const formik = useFormik<WasteCategoryGroupEntity>({
    initialValues: { fromPage: minPage + 1, categories: [] },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleTranslateCategory = (value: string) => {
    return wasteListTranslation(`category.${value.toLocaleLowerCase()}`);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input type="number" name="fromPage" />
          </Grid>
          <Grid item xs={6}>
            <SelectMultiple
              name="categories"
              formik={formik}
              options={WasteCategoryEnum}
              translateValue={handleTranslateCategory}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default CategoryGroupForm;
