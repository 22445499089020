/* tslint:disable */
/* eslint-disable */
/**
 * RE:Base
 * API for the RE:Base frontend
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './api/address-api';
export * from './api/config-api';
export * from './api/contact-api';
export * from './api/marked-plant-api';
export * from './api/person-api';
export * from './api/person-contact-api';
export * from './api/plant-api';
export * from './api/plant-address-api';
export * from './api/plant-contact-api';
export * from './api/plant-waste-api';
export * from './api/private-plant-address-data-api';
export * from './api/private-plant-data-api';
export * from './api/waste-api';

