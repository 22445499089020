import React, { memo } from "react";
import { Box, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";

const useStyles = makeStyles(() => ({
  checkboxList: {
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    padding: "2px",
  },
  formControlLabel: {
    margin: "0",
  },
}));

export interface ICheckbox {
  key: string;
  label: string;
}

interface Props {
  checkboxes: ICheckbox[];
  groupKey: string;
}

const CheckboxGroup: React.FC<Props> = memo(({ checkboxes, groupKey }) => {
  const formik = useFormikContext<Record<string, string[]>>();

  const classes = useStyles();

  const handleChange = (checkbox: ICheckbox) => {
    if (formik.values[groupKey].includes(checkbox.key)) {
      formik.values[groupKey].splice(
        formik.values[groupKey].indexOf(checkbox.key),
        1
      );
      formik.setFieldValue(groupKey, formik.values[groupKey]);
    } else {
      formik.setFieldValue(groupKey, [
        ...formik.values[groupKey],
        checkbox.key,
      ]);
    }
  };

  const renderCheckbox = (checkbox: ICheckbox) => {
    return (
      <FormControlLabel
        key={checkbox.key}
        className={classes.formControlLabel}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={formik.values[groupKey].includes(checkbox.key)}
            onChange={() => handleChange(checkbox)}
            name={checkbox.key}
          />
        }
        label={checkbox.label}
      />
    );
  };

  return (
    <Box className={classes.checkboxList}>
      {checkboxes.map((checkbox) => renderCheckbox(checkbox))}
    </Box>
  );
});
CheckboxGroup.displayName = "CheckboxGroup";

export default CheckboxGroup;
