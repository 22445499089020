import React from "react";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { ContactDTO } from "../../api";

interface Props {
  contact?: ContactDTO;
  titleVariant?: Variant;
  valueVariant?: Variant;
}

const ContactViewer = ({
  contact,
  titleVariant = "h4",
  valueVariant = "body1",
}: Props) => {
  const { t } = useTranslation("contactViewer");

  if (!contact) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Skeleton animation="wave" height={21} />
          <Skeleton animation="wave" height={22} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton animation="wave" height={21} />
          <Skeleton animation="wave" height={22} />
        </Grid>
      </Grid>
    );
  }

  if (!contact.email && !contact.phone) {
    // don't show if contact is empty
    return null;
  }
  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant={titleVariant}>{t("phone")}</Typography>
        <Typography variant={valueVariant}>{contact.phone}</Typography>
      </Grid>
      <Grid item>
        <Typography variant={titleVariant}>{t("email")}</Typography>
        <Typography variant={valueVariant}>{contact.email}</Typography>
      </Grid>
    </Grid>
  );
};

export default ContactViewer;
