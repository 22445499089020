import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  SimplePlantDTO,
  SimplePlantsDTO,
  PlantApiPlantControllerGetPlantsRequest,
} from "../../api";
import { useAPI } from "../../apiProvider";

export interface IPlantListState {
  count: number;
  loadedPlants?: SimplePlantDTO[];

  /**
   * Resets everything and starts loading page 0 again.
   */
  refreshPlants: () => void;

  /**
   * Loads the next page and adds it to the loadedPlants.
   * Note: does not have an effect when pageSize is undefined.
   */
  nextPage: () => void;
}

export interface PlantListFilter {
  category?: number;
  wasteKey?: string;
  postalCode?: string;
  postalCodeRange?: number;
  name?: string;
  includeExternalPlants?: boolean;
  formsOfDelivery?: string[];
  formsOfDeliveryTransport?: string[];
  deliveryConditionViscosity?: string;
  deliveryConditionSludgeSubstance?: string;
  deliveryConditionDissolvedSolid?: string;
  deliveryConditionSuspendedSolids?: string;
  deliveryConditionParticleSize?: string;
  deliveryConditionPhValueMin?: string;
  deliveryConditionPhValueMax?: string;
  deliveryConditionPcbSubstancePrefix?: number;
  deliveryConditionPcbSubstance?: string;
  deliveryConditionHeatingValuePrefix?: number;
  deliveryConditionHeatingValue?: string;
}

export interface PlantListOptions {
  /**
   * filter can be used to select only a subset of plants.
   */
  filter?: PlantListFilter;

  /**
   * pageSize can be used to page the plant list by the given size.
   */
  pageSize?: number;
}

/**
 * Generic function which loads plants based on the passed filter
 * using the given apiCall.
 * @param apiCall to use to fetch the plants.
 * @param filter can be used to select only a subset of plants.
 * @param pageSize can be used to page the plant list by the given size.
 * @returns
 */
function useCommonPlantListState(
  apiCall: (
    options: PlantApiPlantControllerGetPlantsRequest
  ) => Promise<AxiosResponse<SimplePlantsDTO>>,
  options?: PlantListOptions
): IPlantListState {
  const [loadedPlants, setLoadedPlants] = useState<SimplePlantDTO[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const loadPlants = useCallback(
    async (page?: number) => {
      const response = await apiCall({
        category: options?.filter?.category || undefined, // we don't need to filter by 0
        wasteKey: options?.filter?.wasteKey || undefined,
        postalCode: options?.filter?.postalCode || undefined,
        postalCodeRange: options?.filter?.postalCodeRange || undefined,
        includeExternalPlants:
          options?.filter?.includeExternalPlants || undefined,
        name: options?.filter?.name || undefined,
        page: options?.pageSize === undefined ? undefined : page,
        pageSize: options?.pageSize,
        deliveryFormLiquid: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormLiquid"
        )
          ? true
          : undefined,
        deliveryFormSolid: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormSolid"
        )
          ? true
          : undefined,
        deliveryFormPasty: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormPasty"
        )
          ? true
          : undefined,
        deliveryFormSiloCompatible: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormSiloCompatible"
        )
          ? true
          : undefined,
        deliveryFormContainer: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormContainer"
        )
          ? true
          : undefined,
        deliveryFormBarrels: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormBarrels"
        )
          ? true
          : undefined,
        deliveryFormLooseFilling: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormLooseFilling"
        )
          ? true
          : undefined,
        deliveryFormBoxPallets: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormBoxPallets"
        )
          ? true
          : undefined,
        deliveryFormBigBags: options?.filter?.formsOfDelivery?.includes(
          "deliveryFormBigBags"
        )
          ? true
          : undefined,
        deliveryFormContainerTruck:
          options?.filter?.formsOfDeliveryTransport?.includes(
            "deliveryFormContainerTruck"
          )
            ? true
            : undefined,
        deliveryFormSiloTruck:
          options?.filter?.formsOfDeliveryTransport?.includes(
            "deliveryFormSiloTruck"
          )
            ? true
            : undefined,
        deliveryFormTiltSaddle:
          options?.filter?.formsOfDeliveryTransport?.includes(
            "deliveryFormTiltSaddle"
          )
            ? true
            : undefined,
        deliveryFormTanker: options?.filter?.formsOfDeliveryTransport?.includes(
          "deliveryFormTanker"
        )
          ? true
          : undefined,
        deliveryFormSuctionPressureTrolley:
          options?.filter?.formsOfDeliveryTransport?.includes(
            "deliveryFormSuctionPressureTrolley"
          )
            ? true
            : undefined,
        deliveryFormFuelDepot:
          options?.filter?.formsOfDeliveryTransport?.includes(
            "deliveryFormFuelDepot"
          )
            ? true
            : undefined,
        deliveryFormIBCS: options?.filter?.formsOfDeliveryTransport?.includes(
          "deliveryFormIBCS"
        )
          ? true
          : undefined,
        deliveryFormASP: options?.filter?.formsOfDeliveryTransport?.includes(
          "deliveryFormASP"
        )
          ? true
          : undefined,
        deliveryFormASF: options?.filter?.formsOfDeliveryTransport?.includes(
          "deliveryFormASF"
        )
          ? true
          : undefined,
        deliveryConditionViscosity:
          Number(options?.filter?.deliveryConditionViscosity) || undefined,
        deliveryConditionSludgeSubstance:
          Number(options?.filter?.deliveryConditionSludgeSubstance) ||
          undefined,
        deliveryConditionDissolvedSolid:
          Number(options?.filter?.deliveryConditionDissolvedSolid) || undefined,
        deliveryConditionSuspendedSolids:
          Number(options?.filter?.deliveryConditionSuspendedSolids) ||
          undefined,
        deliveryConditionParticleSize:
          Number(options?.filter?.deliveryConditionParticleSize) || undefined,
        deliveryConditionPhValueMin:
          Number(options?.filter?.deliveryConditionPhValueMin) || undefined,
        deliveryConditionPhValueMax:
          Number(options?.filter?.deliveryConditionPhValueMax) || undefined,
        deliveryConditionPcbSubstancePrefix:
          options?.filter?.deliveryConditionPcbSubstancePrefix || undefined,
        deliveryConditionPcbSubstance:
          Number(options?.filter?.deliveryConditionPcbSubstance) || undefined,
        deliveryConditionHeatingValuePrefix:
          options?.filter?.deliveryConditionHeatingValuePrefix || undefined,
        deliveryConditionHeatingValue:
          Number(options?.filter?.deliveryConditionHeatingValue) || undefined,
      });

      // Reset when loading page 0 again
      if (page !== 0 && page !== undefined && options?.pageSize !== undefined) {
        setLoadedPlants((current) => [...current, ...response.data.plants]);
        setCurrentPage(page);
      } else {
        setCurrentPage(0);
        setLoadedPlants(response.data.plants);
      }

      setCount(response.data.count);
    },
    // length is not accepted by es-lint but its necessary to check if array size of formsOfDelivery or formsOfDeliveryTransport is changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      apiCall,
      options?.filter?.category,
      options?.filter?.postalCode,
      options?.filter?.postalCodeRange,
      options?.filter?.name,
      options?.filter?.wasteKey,
      options?.filter?.includeExternalPlants,
      options?.filter?.formsOfDelivery,
      options?.filter?.formsOfDelivery?.length,
      options?.filter?.formsOfDeliveryTransport,
      options?.filter?.formsOfDeliveryTransport?.length,
      options?.filter?.deliveryConditionViscosity,
      options?.filter?.deliveryConditionSludgeSubstance,
      options?.filter?.deliveryConditionDissolvedSolid,
      options?.filter?.deliveryConditionSuspendedSolids,
      options?.filter?.deliveryConditionParticleSize,
      options?.filter?.deliveryConditionPhValueMin,
      options?.filter?.deliveryConditionPhValueMax,
      options?.filter?.deliveryConditionPcbSubstancePrefix,
      options?.filter?.deliveryConditionPcbSubstance,
      options?.filter?.deliveryConditionHeatingValuePrefix,
      options?.filter?.deliveryConditionHeatingValue,
      options?.pageSize,
    ]
  );

  const refreshPlants = useCallback(() => {
    setLoadedPlants([]);
    setCount(0);
    setCurrentPage(0);
    loadPlants(0);
  }, [loadPlants]);

  useEffect(() => {
    refreshPlants();
  }, [loadPlants, refreshPlants]);

  const nextPage = useCallback(() => {
    if (options?.pageSize === undefined) {
      // no paging enabled
      return;
    }
    loadPlants(currentPage + 1);
  }, [currentPage, loadPlants, options?.pageSize]);

  return {
    count,
    loadedPlants,
    refreshPlants,
    nextPage,
  };
}

/**
 * Loads plants based on the passed filter.
 * @param options
 * @returns
 */
export function usePlantListState(options?: PlantListOptions): IPlantListState {
  const api = useAPI();
  return useCommonPlantListState(
    useCallback(
      (options: PlantApiPlantControllerGetPlantsRequest) =>
        api.plant.plantControllerGetPlants(options),
      [api.plant]
    ),
    options
  );
}

/**
 * Loads "marked" plants based on the passed filter.
 * @param options
 * @returns
 */
export function useMarkedPlantListState(
  options?: PlantListOptions
): IPlantListState {
  const api = useAPI();
  return useCommonPlantListState(
    useCallback(
      (options: PlantApiPlantControllerGetPlantsRequest) =>
        api.markedPlant.markedPlantControllerGetMarkedPlants(options),
      [api.markedPlant]
    ),
    options
  );
}
