import { useState } from "react";
import { PostalCodeRange } from "./constants";

export interface IFormsOfDelivery {
  liquid: boolean;
  solid: boolean;
}
export interface ISearchValues {
  name: string;
  category: number;
  wasteKey?: string;
  postalCode?: string;
  postalCodeRange: number;
  categoryDetail?: number;
  formsOfDelivery?: string[];
  formsOfDeliveryTransport?: string[];
  deliveryConditionViscosity: string;
  deliveryConditionSludgeSubstance: string;
  deliveryConditionDissolvedSolid: string;
  deliveryConditionSuspendedSolids: string;
  deliveryConditionParticleSize: string;
  deliveryConditionPhValueMin: string;
  deliveryConditionPhValueMax: string;
  deliveryConditionPcbSubstance: string;
  deliveryConditionHeatingValue: string;
  includeExternalPlants: boolean;
}

interface IPlantSearchState {
  search: ISearchValues;
  setSearch: (searchData: ISearchValues) => void;
}

export function usePlantSearchState(): IPlantSearchState {
  const initialValues: ISearchValues = {
    name: "",
    category: 0,
    postalCodeRange: PostalCodeRange.Range100,
    wasteKey: "",
    postalCode: "",
    categoryDetail: 0,
    formsOfDelivery: [],
    formsOfDeliveryTransport: [],
    deliveryConditionViscosity: "",
    deliveryConditionSludgeSubstance: "",
    deliveryConditionDissolvedSolid: "",
    deliveryConditionSuspendedSolids: "",
    deliveryConditionParticleSize: "",
    deliveryConditionPhValueMin: "",
    deliveryConditionPhValueMax: "",
    deliveryConditionPcbSubstance: "",
    deliveryConditionHeatingValue: "",
    includeExternalPlants: false,
  };

  const [search, setSearch] = useState<ISearchValues>(initialValues);

  return {
    search,
    setSearch,
  };
}
