import { createAction } from "@reduxjs/toolkit";
import { WasteDTO } from "../../../api";

const WasteEditorActions = {
  setModalOpen: createAction(
    "MODULE_WASTE_EDITOR_SET_MODAL_OPEN",
    (open: boolean, usedWasteKeys: string[] = [], selectedWaste?: WasteDTO) => {
      return {
        payload: {
          modalOpen: open,
          usedWasteKeys,
          selectedWaste,
        },
      };
    }
  ),
};

export default WasteEditorActions;
