import { Reducer, combineReducers } from "redux";
import { PlantEditorReducer } from "../module/PlantEditor/store/plantEditorReducer";
import { WasteEditorReducer } from "../module/WasteEditor/store/wasteEditorReducer";
import { PrivatePlantDataReducer } from "../module/PrivatePlantDataEditor/store/privatePlantDataEditorReducer";
import { WasteImporterReducer } from "../module/WasteImporter/store/wasteImporterReducer";
import { IWasteImporterState } from "../module/WasteImporter/store/wasteImporterTypes";
import { NotificationReducer } from "../module/Notification/store/notificationReducer";
import { IPlantEditorState } from "../module/PlantEditor/store/plantEditorTypes";
import { IWasteEditorState } from "../module/WasteEditor/store/wasteEditorTypes";
import { IPrivatePlantDataState } from "../module/PrivatePlantDataEditor/store/privatePlantDataEditorTypes";
import { INotificationState } from "../module/Notification/store/notificationTypes";

export interface IModuleState {
  plantEditor: IPlantEditorState;
  wasteEditor: IWasteEditorState;
  privatePlantData: IPrivatePlantDataState;
  wasteImporter: IWasteImporterState;
  notification: INotificationState;
}

export const ModuleReducer = combineReducers({
  plantEditor: PlantEditorReducer,
  wasteEditor: WasteEditorReducer,
  privatePlantData: PrivatePlantDataReducer,
  wasteImporter: WasteImporterReducer,
  notification: NotificationReducer,
}) as Reducer<IModuleState>;
