import { useTranslation } from "react-i18next";
import * as yup from "yup";

export function useAddressEditorForm() {
  const { t } = useTranslation("addressEditor");

  const addressValidationSchema = yup.object({
    postalCode: yup
      .string()
      .matches(/^[0-9]+$/, t("errorPostalCodeOnlyDigits"))
      .min(5, t("errorPostalCodeMaxLength"))
      .max(5, t("errorPostalCodeMaxLength")),
  });

  return {
    addressValidationSchema,
  };
}
