import React from "react";
import {
  StyledComponentProps,
  Typography,
  withStyles,
} from "@material-ui/core";

interface Props extends StyledComponentProps<"root"> {
  children: React.ReactNode;
}

const Title = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    textTransform: "uppercase",
    padding: "4px 20px",
    color: theme.palette.secondary.contrastText,
    display: "initial",
  },
}))(Typography);

const CardTitle = ({ children, ...rest }: Props) => (
  <Title variant="h3" {...rest}>
    {children}
  </Title>
);

export default CardTitle;
