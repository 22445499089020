export default {
  other: "Sonstiges",
  excluded: "Ausgeschlossen sind",
  remarks: "Sonstiges/ Bemerkungen",
  companyData: "Firmendaten",
  internalPersonTitle: "Anlagen Kontaktperson",
  externalPersonTitle: "REMONDIS Kontaktperson",
  type: "Anlagenart",
  createdBy: "Eingetragen von",
  createdAt: "Eingetragen am",
  createdAtPattern: "dd.MM.yyyy",
  formsOfDelivery: "Anlieferungsformen & Bedingungen",
};
