import React from "react";
import { useSelector } from "../../redux/utils";
import { Skeleton } from "@material-ui/lab";
import { shallowEqual, useStore } from "react-redux";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { changePlantMarking, createEmptyPrivatePlantData } from "./utils";
import { useAPI } from "../../apiProvider";

interface Props {
  plantId: string;
}

const PlantMarkingEditor = ({ plantId }: Props) => {
  const store = useStore();
  const api = useAPI();
  const { t } = useTranslation("privatePlantDataEditor");

  const isPlantMarked: boolean | undefined = useSelector(
    (state) => state.modules.privatePlantData.plantMarked,
    shallowEqual
  );

  const handleMarkPlant = () => {
    if (isPlantMarked === null) {
      createEmptyPrivatePlantData(store, api, plantId);
      return;
    }
    changePlantMarking(store, api, plantId, true);
  };

  const handleUnMarkPlant = () => {
    changePlantMarking(store, api, plantId, false);
  };

  if (isPlantMarked === undefined) {
    return <Skeleton animation="wave" height={40} width={100} />;
  }

  if (isPlantMarked) {
    return (
      <Button
        onClick={handleUnMarkPlant}
        color="primary"
        startIcon={<FontAwesomeIcon icon={faBookmark} />}
      >
        {t("marked")}
      </Button>
    );
  }

  return (
    <Button
      onClick={handleMarkPlant}
      color="primary"
      startIcon={<FontAwesomeIcon icon={faBookmarkRegular} />}
    >
      {t("unmarked")}
    </Button>
  );
};

export default PlantMarkingEditor;
