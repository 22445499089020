import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { AddressDTO, ContactDTO, PlantDTO } from "../../api";
import InfoCard from "../../components/InfoCard";
import { PageBackButton, PageEditButton } from "../../components/PageButtons";
import AddressViewer from "../AddressViewer";
import ContactViewer from "../ContactViewer";
import { PlantMarkingEditor } from "../PrivatePlantDataEditor";
import PersonAndContactContainer from "../../containers/PersonAndContactContainer";
import {
  categories,
  categoryDetails,
  getMainCategory,
  getCategoryDetailsIndex,
} from "../../enum/plant";
import { IPersonAndContactViewerState } from "../PersonAndContactViewer/hooks";
import WasteListViewerContainer from "../../containers/WasteListViewerContainer";
import PlantPrivateDataViewerContainer from "../../containers/PrivatePlantDataViewerContainer";
import FormsOfDeliveryViewer from "../FormsOfDeliveryViewer";
import ConditionsOfDeliveryViewer from "../ConditionsOfDeliveryViewer";

interface Props {
  plant: PlantDTO;
  address?: AddressDTO;
  contact?: ContactDTO;
  internalPersonAndContactState: IPersonAndContactViewerState;
  externalPersonAndContactState: IPersonAndContactViewerState;
  onEdit: () => void;
  refreshPlant: () => void;
}

const useStyles = makeStyles((theme) => ({
  topContainer: {
    backgroundColor: theme.palette.lightBg,
    padding: "5px 0px 30px",
  },
  cardsContainer: {
    height: "100%",
    padding: "35px 0px",
  },
  notes: {
    whiteSpace: "break-spaces",
  },
  plantDetails: {
    marginTop: 0,
  },
  header: {
    padding: "20px 0px",
  },
  headerRightSide: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  headerLastButton: {
    marginLeft: 30,
  },
}));

const PlantViewer = ({
  plant,
  address,
  contact,
  internalPersonAndContactState,
  externalPersonAndContactState,
  onEdit,
  refreshPlant,
}: Props) => {
  const { t } = useTranslation("plantViewer");
  const classes = useStyles();

  const createdAt = useMemo(() => {
    return DateTime.fromISO(plant.createdAt, { zone: "UTC" })
      .toLocal()
      .toFormat(t("createdAtPattern"));
  }, [plant.createdAt, t]);

  return (
    <>
      <div className={classes.topContainer}>
        <Container>
          <Grid container spacing={3} className={classes.header}>
            <Grid item xs={4}>
              <PageBackButton />
            </Grid>
            <Grid item xs={8} className={classes.headerRightSide}>
              <PlantMarkingEditor plantId={plant.id} />
              <PageEditButton
                onEdit={onEdit}
                className={classes.headerLastButton}
              />
            </Grid>
          </Grid>
          <Typography variant="h1">{plant.name}</Typography>
          <Grid container spacing={3} className={classes.plantDetails}>
            <Grid item xs={6}>
              {plant.category !== 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  whiteSpace="break-spaces"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h4">{t("type")}: </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {categories[getMainCategory(plant.category)]}
                        {plant.category % 100 !== 0 &&
                          categoryDetails[
                            getCategoryDetailsIndex(plant.category)
                          ] && (
                            <>
                              <br />
                              {
                                categoryDetails[
                                  getCategoryDetailsIndex(plant.category) - 1
                                ][plant.category]
                              }
                            </>
                          )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box
                justifyContent="flex-end"
                display="flex"
                alignItems="center"
                whiteSpace="break-spaces"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t("createdAt")}: </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{createdAt}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                justifyContent="flex-end"
                display="flex"
                alignItems="center"
                whiteSpace="break-spaces"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t("createdBy")}: </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{plant.createdBy}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.cardsContainer}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InfoCard title={t("companyData")} fullWidth>
                <Grid container spacing={2}>
                  <Grid item container xs={6} direction="column" spacing={1}>
                    <Grid item>
                      <AddressViewer address={address} />
                    </Grid>
                    <Grid item>
                      <ContactViewer contact={contact} />
                    </Grid>
                  </Grid>
                  {internalPersonAndContactState.person &&
                    (internalPersonAndContactState.person.firstName !== "" ||
                      internalPersonAndContactState.person.lastName !== "") && (
                      <Grid item xs={3}>
                        <PersonAndContactContainer
                          title={t("internalPersonTitle")}
                          state={internalPersonAndContactState}
                        />
                      </Grid>
                    )}
                  {externalPersonAndContactState.person &&
                    (externalPersonAndContactState.person.firstName !== "" ||
                      externalPersonAndContactState.person.lastName !== "") && (
                      <Grid item xs={3}>
                        <PersonAndContactContainer
                          title={t("externalPersonTitle")}
                          state={externalPersonAndContactState}
                        />
                      </Grid>
                    )}
                </Grid>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <InfoCard title={t("formsOfDelivery")} fullWidth>
                <Grid container>
                  <Grid item xs={6}>
                    <FormsOfDeliveryViewer plant={plant} />
                  </Grid>
                  <Grid item xs={6}>
                    <ConditionsOfDeliveryViewer plant={plant} />
                  </Grid>
                </Grid>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <WasteListViewerContainer
                plantId={plant.id}
                wasteKeyExpireDate={plant.wasteExpireDate}
                refreshPlant={refreshPlant}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard title={t("other")} fullWidth>
                <Box>
                  <Typography variant="h4">{t("excluded")}</Typography>
                  <Typography variant="body1">{plant.excluded}</Typography>
                </Box>
                <Box marginTop={1}>
                  <Typography variant="h4">{t("remarks")}</Typography>
                  <Typography className={classes.notes} variant="body1">
                    {plant.notes}
                  </Typography>
                </Box>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <PlantPrivateDataViewerContainer
                plantId={plant.id}
                load={plant.hasPrivate}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PlantViewer;
