import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PlantViewerContainer from "../../containers/PlantViewerContainer";

interface MatchParams {
  plantId: string;
}

const Plant = ({ match }: RouteComponentProps<MatchParams>) => {
  return <PlantViewerContainer plantId={match.params.plantId} />;
};

export default Plant;
