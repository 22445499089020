import React, { useMemo } from "react";
import { APIProvider } from "../../apiProvider";
import { Configuration } from "../../api";
import { AuthContext, useAuthentication } from "./hooks";
import { useConfig } from "../../config";

interface Props {
  children: React.ReactNode;
}

export default function Login({ children }: Props) {
  const config = useConfig();
  const { isLoggedIn, isLoading, token, profile, logout } = useAuthentication();

  const apiConfig = useMemo(() => {
    if (!config) {
      return;
    }
    return new Configuration({
      basePath: config?.backend,
      baseOptions: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    });
  }, [token, config]);

  if (!apiConfig || isLoading) {
    return <></>;
  }

  if (isLoggedIn) {
    return (
      <APIProvider config={apiConfig}>
        <AuthContext.Provider
          value={{
            logout,
            profile: profile || {},
          }}
        >
          {children}
        </AuthContext.Provider>
      </APIProvider>
    );
  }

  return <>Unauthorized. There may be no connection to Keycloak!</>;
}
