import { useCallback, useEffect, useState } from "react";
import { ContactDTO } from "../../api";
import { useAPI } from "../../apiProvider";

interface IContactViewerState {
  contact?: ContactDTO;
  refreshContact: () => void;
}

export function useContactViewerState(contactId?: string): IContactViewerState {
  const [contact, setContact] = useState<ContactDTO>();

  const api = useAPI();
  const loadContact = useCallback(async () => {
    if (!contactId) return;
    const response = await api.contact.contactControllerGetContact({
      contactId,
    });
    setContact(response.data);
  }, [api.contact, contactId]);

  useEffect(() => {
    loadContact();
  }, [loadContact]);

  return {
    contact,
    refreshContact: loadContact,
  };
}
