import React from "react";
import {
  IDeliveryCondition,
  DeliveryConditions,
  DeliveryConditionsPhValue,
  DeliveryConditionsWithPrefix,
  MinMax,
} from "../../enum/plant";
import { InputAdornment, Grid } from "@material-ui/core";
import { Input, Select } from "../../components/Form";

interface DeliveryConditionInputProps {
  deliveryCondition: IDeliveryCondition;
}

interface Props {
  isSearch?: boolean;
}

const DeliveryConditionWithPrefix = ({
  deliveryCondition,
}: DeliveryConditionInputProps) => {
  const prefixName = `${deliveryCondition.name}Prefix`;
  return (
    <Grid item container spacing={1}>
      <Grid item xs={4}>
        <Select name={prefixName} label={""} options={MinMax} />
      </Grid>
      <Grid item xs={8}>
        <Input
          name={deliveryCondition.name}
          label={deliveryCondition.label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {deliveryCondition.unit}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const DeliveryConditionInput = ({
  deliveryCondition,
}: DeliveryConditionInputProps) => {
  return (
    <Input
      name={deliveryCondition.name}
      label={deliveryCondition.label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {deliveryCondition.unit}
          </InputAdornment>
        ),
      }}
    />
  );
};

const DeliveryConditionInputFields = ({ isSearch = false }: Props) => {
  return (
    <Grid container spacing={1}>
      <Grid item container direction="column" spacing={1}>
        {DeliveryConditions.map((deliveryCondition) => (
          <Grid item key={deliveryCondition.name}>
            <DeliveryConditionInput deliveryCondition={deliveryCondition} />
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={1}>
        {DeliveryConditionsPhValue.map((deliveryCondition) => (
          <Grid item xs={6} key={deliveryCondition.name}>
            <DeliveryConditionInput deliveryCondition={deliveryCondition} />
          </Grid>
        ))}
      </Grid>

      <Grid item container spacing={1}>
        {DeliveryConditionsWithPrefix.map((deliveryCondition) => (
          <Grid item xs={12} key={deliveryCondition.name}>
            {isSearch ? (
              <DeliveryConditionInput deliveryCondition={deliveryCondition} />
            ) : (
              <DeliveryConditionWithPrefix
                deliveryCondition={deliveryCondition}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryConditionInputFields;
