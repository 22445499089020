import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Typography,
  Container,
  makeStyles,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { ISearchValues } from "./hooks";
import {
  Input,
  Select,
  SelectWasteKey,
  CheckboxGroup,
  CategoryDetailSelect,
} from "../../components/Form";
import {
  categories,
  FormsOfDelivery,
  FormsOfDeliveryTransport,
} from "../../enum/plant";
import { PostalCodeRangeOptions } from "./constants";
import DeliveryConditionInputFields from "../DeliveryConditionInputFields";
import { useDeliveryConditionsInputFields } from "../DeliveryConditionInputFields/hooks";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "400px",
    backgroundImage: "url(/images/map.png)",
    backgroundPositionY: "100px",
    backgroundSize: "100% 300px",
    backgroundRepeat: "no-repeat",
  },
  container: {
    paddingTop: 20,
  },
  searchContainer: {
    position: "relative",
    paddingBottom: 10,
  },
  searchPaper: {
    padding: 10,
  },
  searchFieldsBox: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "5px",
  },
  searchField: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  category: {
    maxWidth: "25%",
    minWidth: "230px",
  },
  wasteKey: {
    maxWidth: "20%",
    minWidth: "150px",
  },
  postalCode: {
    maxWidth: "10%",
    minWidth: "80px",
  },
  postalCodeRange: {
    maxWidth: "15%",
    minWidth: "133px",
  },
  name: {
    maxWidth: "25%",
    minWidth: "200px",
  },
  buttonContainer: {
    display: "flex",
    flexGrow: 1,
    minWidth: "200px",
    justifyContent: "end",
  },
  resetButtonContainer: {},
  resetButton: {
    textTransform: "lowercase",
  },
  showDetailButtonContainer: {
    height: "100%",
    display: "flex",
  },
  detailSearchContainer: {
    marginTop: "40px",
    marginBottom: "20px",
  },
  detailSearchFieldHeader: {
    marginBottom: "20px",
  },
  detailSearchButtonContainer: {
    textAlign: "right",
  },
}));

interface Props {
  search: ISearchValues;
  onSetSearch: (data: ISearchValues) => void;
  hideTitle?: boolean;
}

const PlantSearch = ({ search, onSetSearch, hideTitle = false }: Props) => {
  const { t } = useTranslation("plantSearch");
  const deliveryConditionsSearchValidationSchema =
    useDeliveryConditionsInputFields();

  const classes = useStyles();

  const [showDetailSearch, setShowDetailSearch] = useState(false);

  const handleSubmit = (values: ISearchValues) => {
    // Category should be always number
    // If categoryDetail is not empty or 0 its a category detail search
    let category = Number(values.category);
    if (values.categoryDetail && values.categoryDetail !== 0) {
      category = Number(values.categoryDetail);
    }
    onSetSearch({ ...values, category: category });
  };

  const handleResetSearch = () => {
    formik.values["formsOfDelivery"] = [];
    formik.values["formsOfDeliveryTransport"] = [];
    formik.resetForm();
    formik.submitForm();
  };

  const formik = useFormik<ISearchValues>({
    initialValues: search,
    validationSchema: deliveryConditionsSearchValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className={classes.root}>
      <Container className={classes.searchContainer}>
        {hideTitle ? null : (
          <Typography className={classes.container}>{t("title")}</Typography>
        )}
        <Paper className={classes.searchPaper}>
          <FormikProvider value={formik}>
            <Form>
              <Box className={classes.searchFieldsBox}>
                <Select
                  className={`${classes.searchField} ${classes.category}`}
                  name="category"
                  label={t("category")}
                  options={categories}
                />
                <SelectWasteKey
                  className={`${classes.searchField} ${classes.wasteKey}`}
                  name="wasteKey"
                  label={t("wasteKey")}
                />
                <Input
                  className={`${classes.searchField} ${classes.postalCode}`}
                  name="postalCode"
                  label={t("postCode")}
                />
                <Select
                  className={`${classes.searchField} ${classes.postalCodeRange}`}
                  name="postalCodeRange"
                  label={t("postalCodeRange")}
                  sort={(a, b) =>
                    Number.parseInt(a.value, 10) - Number.parseInt(b.value, 10)
                  }
                  options={PostalCodeRangeOptions}
                />
                <Input
                  className={`${classes.searchField} ${classes.name}`}
                  name="name"
                  label={t("name")}
                />
              </Box>
              {showDetailSearch && (
                <Box m={1} className={classes.detailSearchContainer}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box m={1} className={classes.detailSearchFieldHeader}>
                        <strong>{t("specifyPlant")}</strong>
                      </Box>
                      <CategoryDetailSelect
                        categoryNumber={formik.values["category"]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box m={1} className={classes.detailSearchFieldHeader}>
                        <strong>{t("formsOfDelivery")}</strong>
                      </Box>
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          <CheckboxGroup
                            checkboxes={FormsOfDelivery}
                            groupKey="formsOfDelivery"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CheckboxGroup
                            checkboxes={FormsOfDeliveryTransport}
                            groupKey="formsOfDeliveryTransport"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={4} spacing={1}>
                      <Box m={1} className={classes.detailSearchFieldHeader}>
                        <strong>{t("deliveryConditions")}</strong>
                      </Box>
                      <DeliveryConditionInputFields isSearch />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box m={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box className={classes.showDetailButtonContainer}>
                      <Button
                        onClick={() => setShowDetailSearch(!showDetailSearch)}
                      >
                        <Box fontSize={12}>
                          <Grid container direction="row" spacing={1}>
                            {!showDetailSearch ? (
                              <>
                                <Grid item>
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </Grid>
                                <Grid item>{t("openDetailSearch")}</Grid>
                              </>
                            ) : (
                              <>
                                <Grid item>
                                  <FontAwesomeIcon icon={faChevronUp} />
                                </Grid>
                                <Grid item>{t("closeDetailSearch")}</Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={`${classes.searchField} ${classes.buttonContainer}`}
                    >
                      <Button
                        color="primary"
                        className={classes.resetButton}
                        type="button"
                        onClick={handleResetSearch}
                      >
                        {t("reset")}
                      </Button>
                      <Button
                        type="submit"
                        startIcon={<FontAwesomeIcon icon={faSearch} />}
                        color="primary"
                        variant="contained"
                      >
                        {t("search")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </Paper>
      </Container>
    </div>
  );
};

export default PlantSearch;
