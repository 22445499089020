import "./App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import theme from "./styles/theme";
import { MuiThemeProvider } from "@material-ui/core";
import Layout from "./containers/Layout";

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale={"de-DE"}>
        <Layout />
      </MuiPickersUtilsProvider>
    </Router>
  </MuiThemeProvider>
);

export default App;
