import React, { memo } from "react";
import { Grid, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
interface Props {
  onCancel: () => void;
  saveButtonText?: string;
  cancelButtonText?: string;
}

const useStyles = makeStyles({
  buttonWrapper: {
    position: "relative",
    display: "initial",
  },
  saveButtonContainer: {
    textAlign: "right",
  },
  root: {
    paddingTop: 20,
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const FormButtons = memo(
  ({ onCancel, saveButtonText, cancelButtonText }: Props) => {
    const { t } = useTranslation("form");

    const formik = useFormikContext();
    const classes = useStyles();

    const saveText = saveButtonText ? saveButtonText : t("save");
    const cancelText = cancelButtonText ? cancelButtonText : t("abort");

    return (
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={6}>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" onClick={onCancel}>
              {cancelText}
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.saveButtonContainer}>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={<FontAwesomeIcon icon={faSave} />}
            >
              {saveText}
            </Button>
            {formik.isSubmitting && (
              <CircularProgress size={24} className={classes.loader} />
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
);

FormButtons.displayName = "FormButtons";

export default FormButtons;
