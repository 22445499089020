export default {
  communicationTool: "Kommunikationstool",
  north: "Nord",
  east: "Ost",
  south: "Süd",
  southwest: "Südwest",
  rhineland: "Rheinland",
  west: "West",
  profile: "Profil",
  logout: "Abmelden",
};
