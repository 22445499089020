import { createAction } from "@reduxjs/toolkit";
import { AddressDTO, PrivatePlantDTO } from "../../../api";

const PrivatePlantDataEditorActions = {
  setModalOpen: createAction(
    "MODULE_PRIVATE_PLANT_DATA_EDITOR_SET_MODAL_OPEN",
    (
      open: boolean,
      selectedPrivatePlantData?: PrivatePlantDTO,
      selectedPrivatePlantDataAddress?: AddressDTO
    ) => {
      return {
        payload: {
          modalOpen: open,
          selectedPrivatePlantData,
          selectedPrivatePlantDataAddress,
        },
      };
    }
  ),
  changePlantMarking: createAction(
    "MODULE_PRIVATE_PLANT_DATA_EDITOR_SET_PLANT_MARKING",
    (plantMarked) => {
      return {
        payload: {
          plantMarked,
        },
      };
    }
  ),
};

export default PrivatePlantDataEditorActions;
