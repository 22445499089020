import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useAPI } from "../../apiProvider";
import InfoCard from "../../components/InfoCard";
import WasteEditor, { showWasteEditorModal } from "../../module/WasteEditor";
import WasteImporter, {
  showWasteImporterModal,
} from "../../module/WasteImporter";
import WasteListViewer, {
  useWasteListViewerState,
} from "../../module/WasteListViewer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    paddingBottom: 0,
  },
  content: {
    padding: "20px 0 0 0",
  },
});

interface Props {
  plantId: string;
  wasteKeyExpireDate: string | null;
  refreshPlant: () => void;
}

const WasteListViewerContainer = ({
  plantId,
  wasteKeyExpireDate,
  refreshPlant,
}: Props) => {
  const { t } = useTranslation("wasteListViewer");
  const wasteListViewerState = useWasteListViewerState(plantId);
  const store = useStore();
  const api = useAPI();
  const classes = useStyles();

  const handleOpenWasteEditorToCreate = useCallback(() => {
    showWasteEditorModal(store, api, plantId);
  }, [store, plantId, api]);

  const handleOpenWasteEditorToEdit = useCallback(
    (wasteId: string) => {
      showWasteEditorModal(store, api, plantId, wasteId);
    },
    [store, api, plantId]
  );

  const handleOpenWasteImport = useCallback(() => {
    showWasteImporterModal(store);
  }, [store]);

  const handleImportWasteAction = useCallback(() => {
    wasteListViewerState.refreshWasteList();
    refreshPlant();
  }, [wasteListViewerState, refreshPlant]);

  return (
    <InfoCard
      title={t("wasteListTitle")}
      fullWidth
      onAction={handleOpenWasteEditorToCreate}
      onImport={handleOpenWasteImport}
      className={classes.root}
      contentClassName={classes.content}
    >
      <WasteListViewer
        wasteListViewerState={wasteListViewerState}
        onEditWaste={handleOpenWasteEditorToEdit}
        wasteKeyExpireDate={wasteKeyExpireDate}
      />
      <WasteEditor
        plantId={plantId}
        onAction={wasteListViewerState.refreshWasteList}
      />
      <WasteImporter plantId={plantId} onAction={handleImportWasteAction} />
    </InfoCard>
  );
};

export default WasteListViewerContainer;
