import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const PageBackButton = () => {
  const history = useHistory();
  const { t } = useTranslation("common");
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Button
      color="primary"
      onClick={handleGoBack}
      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
    >
      {t("back")}
    </Button>
  );
};

export default PageBackButton;
